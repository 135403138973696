const WEIGHT = {
  chart: {
    //type: 'spline',
    marginTop: 20,
    alignTicks: false,
    plotBackgroundColor: '#F1F1F1'
  },
  xAxis: [
    {
      type: 'category',
      crosshair: true,
      alternateGridColor: '#F7F7F7'
    }
  ],
  yAxis: [
    {
      min: 0,
      tickInterval: 10,
      title: {
        text: null,
        style: 'display: none;'
      },
      //title: {
      //  text: 'kg',
      //  align: 'low',
      //  rotation: 0,
      //  offset: 22,
      //  y: 22,
      //  style: {
      //    fontSize: 10,
      //  }
      //},
    }
  ],
  plotOptions: {
    columnrange: {
      dataLabels: {
        enabled: true,
        padding: 3,
        style: {
          color: '#444',
          fontSize: '10px'
        }
      }
    },
    spline: {
      lineWidth: 4,
      states: {
        hover: {
          lineWidth: 5
        }
      },
      marker: {
        symbol: 'circle'
      },
    },
    series: {
      cursor: 'pointer',
      minPointLength: 3,
      events: {
        legendItemClick: function() {
          return false;
        }
      },
      point: {
        events: {}
      }
    },
    states: {
      hover: {
        enabled: false
      }
    }
  }
};

export { WEIGHT }
