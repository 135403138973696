import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    $('#root-context').replaceWith(data.content);
    $('#edit-modal .modal').modal('hide');
    $('.flash-alerts').html(data.flash);
  }

  onError(event) {
    let [data, status, xhr] = event.detail;

    $('#edit-modal .errors-context').
      findOrAppend('.message', '<div class="message" />').
      html(data.errors);
  }
}
