import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    if (data.key) {
      this.updateNotificationFields(data);
    }

    const modal = $('body').find('#notification-template-modal .modal');

    if (modal) {
      modal.modal('hide');
    }
  }

  onError(event) {
    const [data, _status, _xhr] = event.detail;

    if (data.form) {
      $('#notification-template-modal .modal-content').
      replaceWith(data.form);
    }
  }

  updateNotificationFields(data) {
    document.getElementById(data.key).querySelector('.notification-subject').value = data.subject;
    document.getElementById(data.key).querySelector('.notification-body').value = data.body;
  }
}
