import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  QUESTION_TYPES,
  DEFAULT_QUESTION_FORM,
  DEFAULT_ANSWER_LINE
} from 'src/react/constants/TrainingWizard/index'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { saveQuestion } from 'src/react/store/TrainingWizard/actions'

import { Accordion, Button, Modal, FormGroup, FormLabel, Alert } from 'react-bootstrap'
import { SelectField, TextAreaField, FieldError, ImageAttachmentField, SubmitError, SubmitButton } from 'src/react/components/FormFields/index'
import { Form, Field, FormSpy } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'

import AnswerRow from './Partials/AnswerRow'

const switchCorrectAnswer = ([rowIndex], state, { changeValue }) => {
  changeValue(state, 'answers', values => {
    return values.map((answer, index) => ({ ...answer, correct: rowIndex == index }))
  });
};

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const QuestionModal = (props) => {
  const { modal, formEntryId: entityId } = props;

  const dispatch = useDispatch();
  const snippetId = useSelector(state => selectors.activeSnippetId(state));
  const question = entityId ? useSelector(state => selectors.getFormQuestionById(state, entityId)) : null;
  const onSubmit = async (values) => await dispatch(saveQuestion(values, question, { snippetId }));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={question || DEFAULT_QUESTION_FORM}
      mutators={{
        ...arrayMutators,
        switchCorrectAnswer
      }}
      render={({
        submitError,
        handleSubmit,
        form,
        submitting,
        pristine,
        values
      }) => (
        <Modal
          {...modal}
          size="xl"
          className="question-modal"
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header
              className="align-items-center"
              closeButton
            >
              <div className="h4 m-0">
                {question ? 'Edit question' : 'New question'}
              </div>
            </Modal.Header>
            <Modal.Body>
              {
                submitError && (
                  <SubmitError
                    submitError={submitError}
                  />
                )
              }
              <FormGroup>
                <FormLabel>
                  Question Type
                </FormLabel>
                <Field
                  name="question_type"
                  component={SelectField}
                  placeholder="Question type ..."
                  type="select"
                  options={ QUESTION_TYPES }
                />
                <FieldError name="question_type" />
              </FormGroup>
              <FormGroup>
                <div className="d-flex justify-content-between align-items-center">
                  <FormLabel>
                    Question
                  </FormLabel>
                  <ImageAttachmentField name="image" />
                </div>
                <Field
                  name="question"
                  component={TextAreaField}
                  type="textarea"
                  rows="2"
                />
                <FieldError name="question" />
              </FormGroup>
              <Condition
                when="question_type"
                is="optionable"

              >
                <div className="answers-block">
                  <FieldArray name="answers">
                    {
                      ({ fields }) =>
                        fields.map((name, index) => (
                          <AnswerRow
                            key={ index }
                            name={ name }
                            index={ index }
                            form={ form }
                            fields={ fields }
                          />
                        )
                      )
                    }
                  </FieldArray>
                </div>
                <Button
                  className="btn-sm"
                  onClick={
                    () => form.mutators.push('answers', DEFAULT_ANSWER_LINE)
                  }
                >
                  + Add answer
                </Button>
              </Condition>
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton
                type="submit"
                disabled={submitting}
                className="btn-primary"
              >
                Submit
              </SubmitButton>
              <Button
                onClick={modal.onHide}
                className="btn-secondary"
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    />
  );
}

export default QuestionModal
