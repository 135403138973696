import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.handleAmbientActivityChange();
    this.attachListeners();
  }

  handleAmbientActivityChange() {
    this.ambientSettingsValuesContext.style.display = this.element.checked ? 'block' : 'none';
  }

  attachListeners() {
    this.element.addEventListener('change', (e) => this.handleAmbientActivityChange());
  }

  get ambientSettingsValuesContext() {
    return document.querySelector('.ambient-settings-values-context');
  }
}
