import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { deleteCategory, deleteSnippet, deleteQuestion } from 'src/react/store/TrainingWizard/actions'

import { Button, Modal } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { SubmitError, SubmitButton } from 'src/react/components/FormFields/index'

import DeleteMessage from './Partials/DeleteMessage'

const currentAction = (entry) => {
  const factory = {
    category: deleteCategory,
    snippet: deleteSnippet,
    question: deleteQuestion
  };

  return factory[entry];
};

const currentEntity = (entry, entryId) => {
  const factory = {
    category: selectors.getСategoryById,
    snippet: selectors.getSnippetById,
    question: selectors.getQuestionById
  };

  return useSelector(state => factory[entry](state, entryId));
};

const DeleteModal = (props) => {
  const { modal, formEntry: entry, formEntryId: entryId } = props;

  const deleteAction = currentAction(entry);
  const entity = currentEntity(entry, entryId);

  const dispatch = useDispatch();
  const onSubmit = async (values) => await dispatch(deleteAction(entity));

  return (
    <Form
      onSubmit={onSubmit}
      render={({ submitError, handleSubmit, form, submitting, pristine, values }) => (
        <Modal
          {...modal}
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                Delete { entry }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0">
              {
                submitError && (
                  <SubmitError
                    submitError={ submitError }
                  />
                )
              }
              <div className="bd-callout bd-callout-danger mt-0">
                <strong> Note: </strong>
                <span>
                  <DeleteMessage
                    entry={entry}
                    entity={entity}
                  />
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton
                type="submit"
                disabled={submitting}
                className="btn-primary"
              >
                Confirm
              </SubmitButton>
              <Button
                onClick={modal.onHide}
                className="btn-secondary"
              >
                Cancel
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    />
  );
}

export default DeleteModal
