import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const element = this.element;

    const checkboxes = element.querySelector('.protocol_protocol_appointments_in_use input[type=checkbox]')
    checkboxes.addEventListener('change', (e) => {
      let destroy_input = element.querySelector('.protocol_protocol_appointments__destroy input');
      destroy_input.value = !e.currentTarget.checked;
    })
  }
}
