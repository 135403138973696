import BaseController from "./base_controller";
import LeftEye from 'images/eyes/left.svg'
import RightEye from 'images/eyes/right.svg'
import Amsler from 'src/charts/cycle/amsler/widget'

const WIDGETS = {
  'ophthalmology:amsler': 'renderAmsler',
  'ophthalmology:color': 'renderColor',
  'ophthalmology:vision': 'renderVision',
  'ophthalmology:contrast': 'renderContrast',
  'ophthalmology:survey': 'renderSurvey'
};

export default class extends BaseController {

  resolve(response) {
    const content = this[WIDGETS[response.keyring]](response);

    this.instance.setContent(content.innerHTML);
  }

  renderAmsler(response) {
    const { 'ophthalmology:amsler:l': left, 'ophthalmology:amsler:r': right } = response.tests;

    const template = `
      <div class="test-preview-widget amsler">
        <div class="title">Amsler 5 Grid / Visual Field 10-5</div>
        ${this.inlineExamDateTemplate(left || right)}
        ${this.testHeader(left, right)}

        <div class="row">
          <div class="col-6 left-eye">
            <div class="d-flex justify-content-between results">
              <span>
                <div class="score text-center">
                  <div class="chapter">Central 5-degrees</div>
                  <div class="grid central" data-left-central>
                    ${this.withResults(left) ? '' : 'N/A'}
                </div>
                <div class="chapter">QUAD Central 10-Degrees</div>
                <div class="grid full" data-left-full>
                    ${this.withResults(left) ? '' : 'N/A'}
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div class="col-6 ">
            <div class="d-flex justify-content-between results">
              <span>
                <div class="score text-center">
                <div class="chapter">Central 5-degrees</div>
                  <div class="grid central" data-right-central>
                    ${this.withResults(right) ? '' : 'N/A'}
                  </div>
                <div class="chapter">QUAD Central 10-Degrees</div>
                <div class="grid full" data-right-full>
                    ${this.withResults(right) ? '' : 'N/A'}
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>`;

    let container = this.buildContainer(template);

    if (this.withResults(left)) {
      new Amsler(container.querySelector('[data-left-central]'), { type: 'central', payload: left.central });
      new Amsler(container.querySelector('[data-left-full]'), { type: 'full', payload: left.full });
    }

    if (this.withResults(right)) {
      new Amsler(container.querySelector('[data-right-central]'), { type: 'central', payload: right.central });
      new Amsler(container.querySelector('[data-right-full]'), { type: 'full', payload: right.full });
    }

    return container;
  }

  renderColor(response) {
    const { 'ophthalmology:color:l': left, 'ophthalmology:color:r': right } = response.tests;

    const template = `
      <div class="test-preview-widget color">
        <div class="title">Color Vision</div>
        ${this.inlineExamDateTemplate(left || right)}
        ${this.testHeader(left, right)}

        <div class="row">
          <div class="col-6 left-eye">
            <div class="score">
              ${
                this.withResults(left) ?
                `${left.recognized_plates} of ${left.shown_plates} (Missed ${left.missed_plates})` :
                'N/A'
              }
            </div>
          </div>
          <div class="col-6">
            <div class="score">
              ${
                this.withResults(right) ?
                `${right.recognized_plates} of ${right.shown_plates} (Missed ${right.missed_plates})` :
                'N/A'
              }
            </div>
          </div>
        </div>`;

    return this.buildContainer(template);
  }

  renderVision(response) {
    const { 'ophthalmology:vision:l': left, 'ophthalmology:vision:r': right } = response.tests;

    const template = `
      <div class="test-preview-widget vision">
        <div class="title">Visual Acuity</div>
        ${this.inlineExamDateTemplate(left || right)}
        ${this.testHeader(left, right)}

        <div class="row">
          <div class="col-6 left-eye">
            <div class="score">
              ${this.withResults(left) ? `${left.snellen_pretty} (${left.logmar_pretty})` : 'N/A'}
            </div>
          </div>
          <div class="col-6">
            <div class="score">
              ${this.withResults(right) ? `${right.snellen_pretty} (${right.logmar_pretty})` : 'N/A'}
            </div>
          </div>
        </div>`;

    return this.buildContainer(template);
  }

  renderContrast(response) {
    const { 'ophthalmology:contrast:l': left, 'ophthalmology:contrast:r': right } = response.tests;

    const template = `
      <div class="test-preview-widget contrast">
        <div class="title">Contrast</div>
        ${this.inlineExamDateTemplate(left || right)}
        ${this.testHeader(left, right)}

        <div class="row">
          <div class="col-6 left-eye">
            <div class="score">
              ${this.withResults(left) ? `${left.contrast_pelli_robson}` : 'N/A'}
            </div>
          </div>
          <div class="col-6">
            <div class="score">
              ${this.withResults(right) ? `${right.contrast_pelli_robson}` : 'N/A'}
            </div>
          </div>
        </div>`;

    return this.buildContainer(template);
  }

  renderSurvey(response) {
    const { 'ophthalmology:survey': survey } = response.tests;

    const reducer = (accumulator, currentValue) => accumulator + (
      survey[currentValue] != null ? (
        `<div class="list-group-item d-flex">
          <span class="text">${BaseController.surveyLibrary[currentValue]}</span>
          ${this.renderAnswer(survey, currentValue)}
        </div>`
      ) : ''
    );

    const template = `
      <div class="test-preview-widget survey">
        <div class="title">Survey</div>
        ${this.inlineExamDateTemplate(survey)}
        <div class="list-group list-group-flush d-flex results">
          ${Object.keys(BaseController.surveyLibrary).reduce(reducer, '')}
        </div>
      </div>`;

    return this.buildContainer(template);
  }

  inlineExamDateTemplate(test) {
    return `
      <div class="mb-2">
        <span class="test-widget-date">${test.end_time}</span>
        <span class="test-widget-timezone">${test.timezone}</span>
      </div>
    `;
  }

  testHeader(left, right) {
    return `
      <div class="row">
        <div class="col-6 left-eye">
          <div class="section-title">
            <img src="${LeftEye}">
            Left (OS)
            <div class="ml-auto ${this.withResults(left) && left.base ? 'mdi mdi-12px mdi-star text-success' : ''}"></div>
          </div>
        </div>
        <div class="col-6 right-eye">
          <div class="section-title">
            <img src="${RightEye}">
            Right (OD)
            <div class="ml-auto ${this.withResults(right) && right.base ? 'mdi mdi-12px mdi-star text-success' : ''}"></div>
          </div>
        </div>
      </div>`;
  }
}