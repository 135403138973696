import ConfirmController from 'src/stimulus/controllers/helpers/confirm_controller'
import $ from 'jquery'

export default class extends ConfirmController {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    $(this.element).replaceWith(data.content);
  }
}
