import React, { useRef, memo } from 'react'
import copyToClipboard from 'src/utils/copyToClipboard'
import $ from 'jquery'

import combineOptions from 'src/react/utils/Compares/combineOptions'

const ShareLink = ({ blacklist }) => {
  const copyText = () => window.compares.compare_url + '?' + $.param(combineOptions(blacklist));

  const node = React.createRef(null);

  const copy = () => {
    const { current } = node;

    copyToClipboard(copyText(), () => {
      $(current).
        tooltip({ title: 'Copied!', trigger: 'manual' }).
        tooltip('show');

      setTimeout((() => $(current).tooltip('dispose')), 1300);
    });
  }

  return (
    <a
      className="btn btn-white text-nowrap"
      ref={node}
      onClick={
        () => copy()
      }
    >
      Share
    </a>
  );
}

export default memo(ShareLink)
