import { Controller } from 'stimulus'
import DiseaseFormatter from 'src/utils/diseaseFormatter'
import $ from 'jquery'
import 'selectize'

export default class extends Controller {
  connect() {
    const self = this;
    const url = this.element.dataset.url;
    const isGroupingDisabled = this.element.dataset.disableGroupByNode == true;
    const formatter = new DiseaseFormatter(this.element);

    $(this.element).selectize({
      valueField: 'code',
      labelField: 'code',
      searchField: ['code', 'description'],
      options: [],
      create: false,
      plugins: ['remove_button'],
      dropdownParent: 'body',
      disabledField: isGroupingDisabled ? false : 'node',
      render: {
        option: formatter.optionViewCallback(),
        item: formatter.itemViewCallback()
      },
      onInitialize: this.tagTooltipCallback,
      onChange: function() {
        self.tagTooltipCallback();
        formatter.cleanHiddenSelectOptions(this.$input[0].options);
      },
      load: function(query, callback) {
        query = formatter.dotInsensitiveQuery(query);
        this.clearOptions();

        if (!query.length) return callback();

        $.ajax({
          url: url,
          dataType: 'json',
          data: { search: query },
          error: () => callback(),
          success: (response) => callback(formatter.formatResponseData(response.data))
        })
      },
      score: () => () => 1
    });
  }

  tagTooltipCallback() {
    $('[data-toggle="tooltip"], .tooltip').tooltip('hide');
    $('[data-toggle="tooltip"]').tooltip();
  }
}
