import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { DragDropContext } from 'react-beautiful-dnd'

import Controls from './Controls'
import Categories from './Categories'
import WizardBoard from './WizardBoard'
import ModalFactory from './ModalFactory'

import Preloader from 'src/react/components/TrainingWizard/Preloader'

import { getTrainingTree } from 'src/react/store/TrainingWizard/actions'
import { moveEntity } from 'src/react/store/TrainingWizard/movers'
import { resetFlashMessage } from 'src/react/utils/TrainingWizard/flash'

const Page = (props) => {
  const dispatch = useDispatch();
  const onDragEnd = (result) => dispatch(moveEntity(result));
  const onBeforeCapture = () => resetFlashMessage();

  useEffect(() => {
    dispatch(getTrainingTree());
  }, [dispatch]);

  const isLoading = useSelector(state => selectors.isLoading(state));
  const data = useSelector(state => selectors.getState(state));

  if (isLoading) return <Preloader />;

  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
      <div className="trainings-wizard">
        <div className="container-fluid">
          <Controls />

          <div className="main-content">
            <div className="card trainings-create-card">
              <div className="card-body p-0">
                <div className="d-flex">
                  <div className="col-3 border-right px-0">
                    <Categories />
                  </div>

                  <div className="col-9 px-0">
                    <WizardBoard />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ModalFactory />
        </div>
      </div>
    </DragDropContext>
  );
}

export default Page
