import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener("trix-file-accept", (event) => {
      const acceptedTypes = ['image/jpeg', 'image/png'];

      if (!acceptedTypes.includes(event.file.type)) {
        event.preventDefault()

        swal('Only images can be attached.');
      }
    });
  }
}
