import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { showFormModalAction, showCopyModalAction, showDeleteModalAction } from 'src/react/store/TrainingWizard/actions'

import Ability from './Ability'
import Questions from './Questions'

const Wizard = ({ snippetId }) => {
  const dispatch = useDispatch();
  const training = useSelector(state => selectors.training(state));
  const snippet = useSelector(state => selectors.getSnippetById(state, snippetId));
  const category = useSelector(state => selectors.getСategoryById(state, snippet.category_id));

  const questionsCount = snippet.questions.length;
  const isDragDisabled = ['active', 'inactive'].includes(training.status);

  return (
    <>
      <div className="snippet-container">
        <div className="snippet">
          <div className="panel align-items-start">
            <div className="title"> {snippet.name} </div>
            <div className="actions">
              <Ability
                context="editable_training"
              >
                <Button
                  className="btn-sm"
                  variant="link"
                  onClick={
                    () => dispatch(showFormModalAction({ entry: 'snippet', id: snippetId }))
                  }
                >
                  <div className="mdi mdi-pencil"></div>
                  Edit
                </Button>
                <Button
                  className="btn-sm"
                  variant="link"
                  onClick={
                    () => dispatch(showCopyModalAction({ entry: 'snippet', id: snippetId }))
                  }
                >
                  <div className="mdi mdi-content-copy"></div>
                  Copy
                </Button>
                <Button
                  className="btn-sm text-danger"
                  variant="link"
                  onClick={
                    () => dispatch(showDeleteModalAction({ entry: 'snippet', id: snippetId }))
                  }
                >
                  <div className="mdi mdi-delete"></div>
                  Delete
                </Button>
              </Ability>
            </div>

          </div>
          <div className="content">
            <div className="snippet-row">
              <div className="snippet-col-title">
                Snippet ID
              </div>
              <div className="snippet-col-value">
                {snippet.id}
              </div>
            </div>
            <div className="snippet-row">
              <div className="snippet-col-title">
                Snippet Category
              </div>
              <div className="snippet-col-value">
                {category.name}
              </div>
            </div>
            {
              snippet.video && (
                <div className="snippet-row">
                  <div className="snippet-col-title">
                    Link to video
                  </div>
                  <div className="snippet-col-value">
                    <a href={snippet.video} target="_blank">{snippet.video}</a>
                  </div>
                </div>
              )
            }
            {
              snippet.content && (
                <div className="snippet-row">
                  <div className="snippet-col-title">
                    Content
                  </div>
                  <div className="snippet-col-value">
                    <span dangerouslySetInnerHTML={{__html: snippet.content}} />
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className="questionnaire-container">
        <div className="questionnaire">
          <div className="panel">
            <div className="title"> Questionnaire </div>
            <Ability
              context="editable_training"
            >
              <button
                className="btn btn-sm btn-primary"
                onClick={
                  () => dispatch(showFormModalAction({ entry: 'question' }))
                }
              >
                Add question
              </button>
            </Ability>
          </div>
          <div className="content">
            {
              questionsCount > 0 ? (
                <Questions
                  questions={snippet.questions}
                  snippetId={snippetId}
                  isDragDisabled={isDragDisabled}
                />
              ) : (
                <div className="questionnaire-empty">
                  No questions inside of snippet
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Wizard
