import moment from 'moment'

class ChartTooltipFormatter {
  constructor(element, params = {}) {
    this.element = element;
    this.params = params;
  }

  initDates(object, dateFormat) {
    return [
      moment(object.x, dateFormat),
      moment(object.series.xAxis.categories[object.point.x + 1], dateFormat),
      moment(object.series.xAxis.categories[object.point.x - 1], dateFormat)
    ]
  }

  nextDate(currDate, dateFormat, object) {
    let nextDate,
        grouping = object.series.xAxis.userOptions.grouping;

    switch (grouping) {
      case '7 days':
        nextDate = currDate.add(6, 'day').format(dateFormat);
        break;
      case '1 month':
        nextDate = currDate.endOf('month').format(dateFormat);
        break;
      default:
        nextDate = currDate.format(dateFormat)
    }

    return nextDate;
  }

  dateRange(object, options = {}) {
    const dateFormat = options.date_format ? options.date_format : 'DD MMM YYYY';
    const rangeEnabled = options.range_enabled == false ? false : true;
    let [currDate, nextDate, prevDate] = this.initDates(object, dateFormat),
        dateRange = `${currDate.format(dateFormat)}`;

    nextDate = this.nextDate(currDate, dateFormat, object);

    if (nextDate != dateRange && rangeEnabled == true) {
      dateRange = dateRange + ` - ${nextDate}`;
    }

    return dateRange;
  }

  seriesMarker(object, options = {}) {
    const color = options['color'] ? options['color'] : object.series.color;

    return '<br/><span style="font-size: 14px; color: ' + color + '">&#9679</span> ';
  }

  seriesTotal(object, options = {}) {
    const seriesName = options.series_name ? (' ' + options.series_name) : '';
    const seriesUnits = options.series_units ? options.series_units : '';
    const amountValue = options.money_format ? object.total.toFixed(2) : object.total
    const amount =
      options.units_position == 'before' ? `: ${seriesUnits}<b>${amountValue}</b>` :
      `: <b>${amountValue}</b> ${seriesUnits}`

    return 'Total' + seriesName + amount;
  }

  seriesInfo(object, options = {}) {
    const seriesName = options.series_name ? options.series_name : object.series.name;
    const seriesUnits = options.series_units ? options.series_units : '';
    const amountValue = options.money_format ? object.y.toFixed(2) : object.y
    const amount =
      options.units_position == 'before' ? `: ${seriesUnits}<b>${amountValue}</b>` :
      `: <b>${amountValue}</b> ${seriesUnits}`

    return seriesName + amount;
  }

  // Charts tooltips
  patientsActivityTooltip(object) {
    if (object.y == 0)
      return false;

    return this.dateRange(object) +
      this.seriesMarker(object) + this.seriesInfo(object);

  }

  eventsActivityTooltip(object) {
    if (this.total == 0)
      return false;

    return this.dateRange(object) +
      this.seriesMarker(object) + this.seriesInfo(object) +
      this.seriesMarker(object, { color: 'red' }) + this.seriesTotal(object, { series_name: 'Events' });
  }

  timingTooltip(object) {
    if (object.y == 0)
        return false;

    return this.dateRange(object) +
      this.seriesMarker(object) + this.seriesInfo(object, { series_name: 'Time', series_units: 'hours' });
  }

  chargeItemsTooltip(object) {
    if (this.total == 0)
      return false;

    return this.dateRange(object, { date_format: 'MMMM YYYY', range_enabled: false }) +
      this.seriesMarker(object) +
      this.seriesInfo(object, { series_units: '$', units_position: 'before', money_format: true }) +
      this.seriesMarker(object, { color: 'red' }) +
      this.seriesTotal(object, { series_units: '$', units_position: 'before', money_format: true });
  }

  billablePatientsTooltip(object) {
    if (this.total == 0)
      return false;

    return this.dateRange(object, { date_format: 'MMMM YYYY', range_enabled: false }) +
      this.seriesMarker(object) + this.seriesInfo(object) +
      this.seriesMarker(object, { color: 'red' }) + this.seriesTotal(object);
  }

  patientsProtocolsActivityTooltip(object) {
    if (object.y == 0)
      return false;

    return this.dateRange(object) +
      this.seriesMarker(object) + this.seriesInfo(object);

  }
}

export default ChartTooltipFormatter
