import { Controller } from 'stimulus'
import Chart from 'src/charts/dashboard/onboarding/chart'

export default class extends Controller {
  connect() {
    new Chart(this.element, this.chartOptions(this.element));
  }

  chartOptions(selector) {
    const { payload } = $(selector).data();

    return {
      payload: { series: payload }
    }
  }
}
