import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/Timesheets/selectors'
import { switchActivitiesAction } from 'src/react/store/Timesheets/actions'

const Headline = (props) => {
  const dispatch = useDispatch();

  const currentDay = useSelector(state => selectors.currentDay(state));

  return (
    <div className="timesheet-list-head">
      <div className="timesheet-line">
        <div className="timesheet-line-collapser">
          <div className="btn btn-icon">
             <span
               className="mdi mdi-chevron-double-down"
               onClick={
                 () => dispatch(switchActivitiesAction(currentDay))
               }
              >
              </span>
          </div>
        </div>

        <div className="timesheet-line-patient">
          Patient
        </div>

        <div className="timesheet-line-activity">
          Activity
        </div>

        <div className="timesheet-line-reconds">
          Records
        </div>

        <div className="timesheet-line-time">
          Time
        </div>

        <div className="timesheet-line-actions">
          Actions
        </div>
      </div>
    </div>
  );
}

export default Headline
