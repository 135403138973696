import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Accordion } from 'react-bootstrap'

import * as selectors from 'src/react/store/Timesheets/selectors'

import SummaryLine from './SummaryLine'
import TimeRecord from './TimeRecord'

const Activity = ({ activityId, isLockedDay }) => {
  const activity = useSelector(state => selectors.getActivityById(state, activityId));

  const { active, time_records } = activity;

  return (
    <Accordion
      className="timesheet-list-item"
      activeKey={active}
    >
      <SummaryLine
        activity={activity}
        isLockedDay={isLockedDay}
      />

      <Accordion.Collapse
        eventKey={true}
      >
        <div className="timesheet-fold">
          {
            time_records.map((timeRecordId) => (
              <TimeRecord
                activityId={activityId}
                timeRecordId={timeRecordId}
                isLockedDay={isLockedDay}
                key={timeRecordId}
              />
            ))
          }
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
}

export default memo(Activity)
