import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    const self = $(this.element);
    const options = this.data;

    self.on('click', function (event) {
      if (options.get('disabled') === 'true') {
        event.preventDefault();
      } else {
        self.prop('href', options.get('url'))
      }
    });
  }
}
