import React, { useCallback } from 'react'
import Select from 'react-select'
import classnames from 'classnames'

import InvalidFeedback from 'src/react/components/Timesheets/InvalidFeedback'

const SelectCell = ({ cell: { value, errors }, changeCell, options }) => {
  const inputStyles = classnames({ 'is-invalid': errors });

  const handleInputChange = useCallback(
    (event) => {
      changeCell({ value: event.value });
    },
    [changeCell]
  );

  return (
    <>
      <Select
        autoFocus
        options={options}
        defaultMenuIsOpen={true}
        defaultValue={value}
        className={inputStyles}
        onChange={handleInputChange}
      />
      <InvalidFeedback errors={errors} />
    </>
  );
}

export default SelectCell
