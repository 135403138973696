import Base from 'src/components/summary/grids/ophthalmology/base'
import { DEFAULT_OPTIONS } from 'src/components/summary/constants/defaults'
import { VISION, LOGMAR_SNELLEN_PRETTY } from 'src/components/summary/constants/ophthalmology/vision'
import moment from 'moment'

class Vision extends Base {
  defaultParams() {
    return $.extend(true, {}, DEFAULT_OPTIONS, VISION);
  }

  onLoadRequestEnd(chart, response) {
    super.onLoadRequestEnd(chart, response);

    this.redrawTrendline();
  }

  onDrilldownRequestEnd(chart, e, response) {
    super.onDrilldownRequestEnd(chart, e, response);

    this.drawTrendline();
  }

  onDrillupStart() {
    this.removeTrendline();

    super.onDrillupStart();
  }

  onDrillupEnd(chart) {
    this.redrawTrendline();
  }

  onActivateDaily(chart, e, response) {
    chart.xAxis[0].update({ plotBands: this.dailyPlotBands(response) }, false);
  }

  onDeactivateDaily(chart) {
    chart.xAxis[0].update({ plotBands: [] }, false);
  }

  tooltipFormatter(context, tooltip) {
    return this.tooltipHeader(this.tooltipLabel(context.point)) +
           this.tooltipBody(context.point, this.tooltipResult(context.point));
  }

  tooltipLabel(point) {
    if (this.currentDataspace.mode == 'daily') return point.time;
    if (point.by_date) return moment(point.by_date).format('D MMM Y');

    return `${moment(point.start_date).format('D MMM Y')} - ${moment(point.end_date).format('D MMM Y')}`;
  }

  tooltipBody(point, result) {
    return `
      <br/>
      <span style="color:${point.series.color}"> ● </span>${point.series.name}: <b>${result}</b>
    `;
  }

  tooltipResult(point) {
    return (LOGMAR_SNELLEN_PRETTY[point.y] || {})[this.options.unit] || point.y;
  }

  get options() {
    const { eye } = this.params;
    const { vision_unit: unit } = this.storage.params;

    return {
      eye,
      unit,
      category: 'ophthalmology',
      widget: 'vision'
    };
  }
}

export default Vision
