import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {}

  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    $(`#medical_group_responsibility_member_${data.id}`).replaceWith(data.content)

    if (data.previous_responsible) {
      $(`#medical_group_responsibility_member_${data.previous_responsible.id}`).replaceWith(data.previous_responsible.content)
    }
  }
}
