import React from 'react'

import Avatar from 'src/react/components/Timesheets/Avatar'

const User = ({ user }) => {
  const { name, email } = user;

  return (
    <div
      className="user-list-widget"
      style={{padding: '7px 0px'}}
    >
      <Avatar user={user} />

      <div className="content">
        <div className="name">{name}</div>
        <div className="email">{email}</div>
      </div>

      <div className="d-flex align-items-center ml-1">
        <div className="mr-0"></div>
      </div>
    </div>
  );
}

export default User
