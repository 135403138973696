import { Controller } from 'stimulus'
import Amsler from 'src/charts/cycle/amsler/cycle'

export default class extends Controller {
  connect() {
    new Amsler(this.element, this.chartOptions(this.element));
  }

  chartOptions(selector) {
    const { type, payload } = $(selector).data();

    return {
      type: type,
      payload: payload
    }
  }
}
