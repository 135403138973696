import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', (e) => this.setPeriod(e.target));
    this.fromSelector.addEventListener('click', () => this.resetQuickDates());
    this.toSelector.addEventListener('click', () => this.resetQuickDates());
  }

  setPeriod(target) {
    const quickDate = this.element.options[this.element.selectedIndex].value;

    if (quickDate === '') {
      this.fromController.datepicker.clear();
      this.toController.datepicker.clear();
    } else {
      this.fromController.datepicker.selectDate(this.dateFrom(quickDate));
      this.toController.datepicker.selectDate(this.dateTo(quickDate));
    }
  }

  resetQuickDates() {
    this.element.selectedIndex = 0;
  }

  get fromController() {
    return this._controllerBySelector(this.fromSelector);
  }

  get toController() {
    return this._controllerBySelector(this.toSelector);
  }

  get fromSelector() {
    return document.getElementById('q_from');
  }

  get toSelector() {
    return document.getElementById('q_to');
  }

  _controllerBySelector(selector) {
    return this.application.getControllerForElementAndIdentifier(selector, 'helpers--picker');
  }

  dateFrom(quick_date) {
    const dateNow = new Date();
    const firstDayOfTheWeek = (dateNow.getDate() - dateNow.getDay()) + 1;

    switch(quick_date) {
      case 'this_week':
        return new Date(dateNow.setDate(firstDayOfTheWeek));
      case 'last_week':
        return new Date(dateNow.setDate(firstDayOfTheWeek - 7));
      case 'this_month':
        return new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
      case 'last_month':
        return new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1);
    }
  }

  dateTo(quick_date) {
    const dateNow = new Date();
    const firstDayOfTheWeek = (dateNow.getDate() - dateNow.getDay()) + 1;

    switch(quick_date) {
      case 'this_week':
        return new Date(dateNow.setDate(firstDayOfTheWeek + 6));
      case 'last_week':
        return new Date(dateNow.setDate(firstDayOfTheWeek - 1));
      case 'this_month':
        return new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
      case 'last_month':
        return new Date(dateNow.getFullYear(), dateNow.getMonth(), 0);
    }
  }
}
