import React, { useState, useEffect } from 'react'
import { Form } from 'react-final-form'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

const boxStyles = {
  lineHeight: '24px'
};

const labelStyleDefault = {
  cursor: 'pointer',
  margin: 0
};

const labelStyleExists = {
  ... labelStyleDefault,
  color: 'green'
};

const ImageFileField = ({
  input: { name, value, onChange, ...input },
  meta,
  image,
  error,
  invalid = meta => meta.touched && meta.invalid,
  valid = () => false,
  ...rest
}) => {
  const [available, setAvailable] = useState(!!image);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={boxStyles}
    >
      <label
        htmlFor={`${name}-binding`}
        style={
          (available || value) ? labelStyleExists : labelStyleDefault
        }
      >
        <span className="mdi mdi-24px mdi-image-area"></span>
      </label>

      <input
        {...input}
        id={`${name}-binding`}
        type="file"
        hidden
        onChange={
          ({ target }) => onChange(target.files[0])
        }
      />

      {
        error &&
          <OverlayTrigger
            placement='top'
            trigger={['focus', 'hover']}
            defaultShow={true}
            overlay={
              <Tooltip>
                {error}
              </Tooltip>
            }
          >
            <span className="mdi mdi-24px mdi-alert-circle-outline text-danger"></span>
          </OverlayTrigger>
      }
    </div>
  );
}

export default ImageFileField
