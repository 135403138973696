import React, { useState, useEffect } from 'react'

const Image = ({ image }) => {
  if (!image) return null;

  const [ source, setSource ] = useState('thumb');

  useEffect(() => setSource('thumb'), [image]);

  const preview = {
    thumb: image.thumb.url,
    preview: image.preview.url
  }[source];

  return (
    <img
      style={{ cursor: 'pointer', maxWidth: '100%' }}
      src={preview}
      alt=""
      onClick={
        () => setSource(source == 'thumb' ? 'preview' : 'thumb')
      }
    />
  );
};

export default Image
