import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  connect() {
    const preloadTemplateFields = (event) => {
      if (event.target.parentNode.id == 'notification-template-modal') {
        this.preloadModalField(this.modalNotificationSubject, this.originalNotificationSubject(event.target.id).value);
        this.preloadModalField(this.modalNotificationBody, this.originalNotificationBody(event.target.id).value);
      }
    }

    $(document).on('show.bs.modal', preloadTemplateFields);
  }

  onSuccess(event) {
    const [data, _status, _xhr] = event.detail;

    if (data.location) {
      window.location = window.location;
    }

    if (data.modal) {
      let modal =
        $('body').
        findOrAppend('#notification-template-modal', '<div id="notification-template-modal" />').
        html(data.modal).
        find('.modal').
        modal('show')
    }
  }

  onError(event) {
    const [data, _status, _xhr] = event.detail;

    if (data.modal) {
      $('#notification-template-modal .modal-content').
      replaceWith('Something went wrong');
    }
  }

  preloadModalField(field, fieldValue) {
    field[0].value = fieldValue;
  }

  get modalRuleForm() {
    return document.querySelector('.notification-template-form');
  }

  get modalNotificationSubject() {
    return $(this.modalRuleForm).find('.notification-subject');
  }

  get modalNotificationBody() {
    return $(this.modalRuleForm).find('.notification-body');
  }

  originalNotificationSubject(fieldsBoxId) {
    return document.getElementById(fieldsBoxId).querySelector('.notification-subject');
  }

  originalNotificationBody(fieldsBoxId) {
    return document.getElementById(fieldsBoxId).querySelector('.notification-body');
  }
}
