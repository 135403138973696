const formatPath = (template, variables) => {
  Object.entries(variables).forEach(([k, v]) => {
    template = template.replace(new RegExp(':'+ k,'g'), v);
  });

  return template;
};

const presence = (value) => (value === null || value === undefined ? '' : value);

export {
  formatPath,
  presence
}
