import Base from 'src/components/summary/widgets/base'

class Simple extends Base {
  init() {
    this.showLoading();
  }

  fetchRootResults() {
    let promise = new Promise((resolve, reject) => {
      const request = this.requestData(this.rootRequestOptions());

      request.done((response) => {
        this.setResults(response);

        resolve(response);
      });

      request.fail(jqXHR => reject(jqXHR));
    });

    return promise;
  }

  requestData(request_options = {}) {
    return $.ajax({
      url: window.metrics.load_results_path,
      data: request_options,
      type: 'GET',
      dataType: 'json'
    });
  }

  rootRequestOptions() {
    let { start_date, end_date } = this.storage.params;

    start_date = start_date.format('YYYY-MM-DD');
    end_date = end_date.format('YYYY-MM-DD');

    return {
      ... this.options,
      ... { start_date, end_date }
    }
  }

  get loadingPlaceholder() {
    return `
      <div class="ph-item">
        <div class="ph-col-12">
          <div class="ph-picture"></div>
          <div class="ph-row">
            <div class="ph-col-6 big"></div>
            <div class="ph-col-4 empty big"></div>
            <div class="ph-col-2 big"></div>
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-6"></div>
            <div class="ph-col-6 empty"></div>
            <div class="ph-col-12"></div>
          </div>
        </div>
      </div>
    `;
  }

  showLoading() {
    this.selector.innerHTML = this.loadingPlaceholder;
  }

  hideLoading() {}
}

export default Simple
