import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const self = this.element;
    const countryEl = document.getElementById(self.dataset.countryElementId);

    this.toggleStateField(self, countryEl);
    countryEl.addEventListener('change', () => this.toggleStateField(self, countryEl));
  }

  toggleStateField(stateEl, countryEl) {
    const isUSA = () => countryEl.disabled === false && countryEl.value === 'US';

    if (isUSA()) {
      stateEl.removeAttribute('disabled');
    } else {
      stateEl.value = '';
      stateEl.setAttribute('disabled', true);
    }
  }
}
