import { Controller } from 'stimulus'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import configureStore from 'src/react/store/configureStore'
import trainings from 'src/react/store/TrainingWizard'

import Page from 'src/react/containers/TrainingWizard/Page'

export default class extends Controller {
  connect() {
    ReactDOM.render(
      <Provider store={this.store}>
        <Page />
      </Provider>,
      this.element
    )
  }

  get store() {
    return configureStore({ trainings });
  }
}
