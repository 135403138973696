import { Controller } from 'stimulus'
import moment from 'moment'

export default class extends Controller {
  static targets = ['stepBackward', 'stepForward']

  connect() {
    this.buildNavigationTooltips();
    this.attachTooltips();
  }

  setRange(new_from, new_to) {
    this.setFrom(new_from);
    this.setTo(new_to);
    this.applyFilters();
  }

  getGroupBy() {
    const range_selector = this.groupBySelector;

    if (range_selector.type == 'hidden') {
      return range_selector.value
    }

    return range_selector.options[range_selector.selectedIndex].value;
  }

  setFrom(date_from) {
    this.fromSelector.value = date_from;
  }

  setTo(date_to) {
    this.toSelector.value = date_to;
  }

  applyFilters() {
    const form = this.filterFormSelector;
    $(form).submit();
  }

  stepBackward() {
    const new_from = moment(this.fromSelector.value, 'YYYY-MM-DD').subtract(1, this.getGroupBy()).format('YYYY-MM-DD');
    const new_to = moment(this.toSelector.value, 'YYYY-MM-DD').subtract(1, this.getGroupBy()).format('YYYY-MM-DD');
    this.setRange(new_from, new_to);
  }

  stepForward() {
    const new_from = moment(this.fromSelector.value, 'YYYY-MM-DD').add(1, this.getGroupBy()).format('YYYY-MM-DD');
    const new_to = moment(this.toSelector.value, 'YYYY-MM-DD').add(1, this.getGroupBy()).format('YYYY-MM-DD');
    this.setRange(new_from, new_to);
  }

  buildNavigationTooltips() {
    const [ forwardTitle, backwardTitle ] = this.tooltipsTitles(this.getGroupBy());

    this.stepForwardTarget.dataset.originalTitle = forwardTitle;
    this.stepBackwardTarget.dataset.originalTitle = backwardTitle;
  }

  tooltipsTitles(group_by) {
    return [
      `Next period (+1 ${group_by})`,
      `Previous period (-1 ${group_by})`
    ];
  }

  attachTooltips() {
    $(this.stepForwardTarget).tooltip({ trigger: 'hover' });
    $(this.stepBackwardTarget).tooltip({ trigger: 'hover' });
  }

  get filterFormSelector() {
    return document.getElementsByClassName('form-filters');
  }

  get groupBySelector() {
    return document.getElementById('q_grouped_by');
  }

  get fromSelector() {
    return document.getElementById('q_from');
  }

  get toSelector() {
    return document.getElementById('q_to');
  }
}
