import React, { memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import useDidUpdateEffect from 'src/react/hooks/useDidUpdateEffect'

import * as selectors from 'src/react/store/Timesheets/selectors'
import { createTreeTimeRecord, updateTreeTimeRecord, showDeleteModalAction } from 'src/react/store/Timesheets/actions'

import EditableCell from './EditableCell'

const TimeRecord = ({ activityId, timeRecordId, isLockedDay }) => {
  const dispatch = useDispatch();
  const timeRecord = useSelector(state => selectors.getTimeRecordById(state, timeRecordId));
  const actionableId = useSelector(state => selectors.actionableId(state));

  const {
    category,
    details,
    amount,
    draft = false,
    frozen = false,
    form = {
      cells: {
        category: null,
        details: null,
        amount: null
      }
    }
  } = timeRecord;

  const {
    cells: {
      category: categoryCell,
      details: detailsCell,
      amount: amountCell
    }
  } = form;


  useDidUpdateEffect(() => {
    dispatch(draft ?
      createTreeTimeRecord({ activityId, timeRecord }, { actionableId }) :
      updateTreeTimeRecord({ activityId, timeRecord }, { actionableId })
    );
  }, [category, details, amount]);

  return (
    <div className={classnames('timesheet-fold-line', { 'draft': draft, 'frozen': frozen })}>
      <EditableCell
        context="category"
        timeRecord={timeRecord}
        cell={categoryCell}
        isLockedDay={isLockedDay}
      />

      <EditableCell
        context="details"
        timeRecord={timeRecord}
        cell={detailsCell}
        isLockedDay={isLockedDay}
      />

      <EditableCell
        context="amount"
        timeRecord={timeRecord}
        cell={amountCell}
        isLockedDay={isLockedDay}
      />

      <div className="timesheet-line-actions">
        {
          frozen ? (
            <div>
              <span className="mdi mdi-loading mdi-spin"></span>
              <span style={{fontSize: '12px', color: '#878787'}}> Saving ... </span>
            </div>
          ) : (
            !isLockedDay && (
              <a
                className="btn btn-icon"
                onClick={
                  () => dispatch(showDeleteModalAction({ entry: 'time_record', id: timeRecordId, options: { activityId } }))
                }
              >
                <span className="mdi mdi-delete"></span>
              </a>
            )
          )
        }
      </div>
    </div>
  )
}

export default memo(TimeRecord)
