import { escapeHTML } from 'src/utils/sanitize'

class Arguments {
  constructor(form) {
    this.form = form;

    this.init();
  }

  init() {
    this.initRuleArguments();

    this.setRulePreview();
    this.bindListeners();
  }

  compileArgument(el) {
    const { argument, aliases } = el.dataset;

    const variable = () => {
      if (el.options && aliases) {
        return JSON.parse(aliases)[el.options[el.selectedIndex].value];
      }

      if (el.options) {
        return el.options[el.selectedIndex].text;
      }

      return el.value;
    }

    return {
      key: argument,
      name: variable(),
      value: el.value
    }
  }

  initRuleArguments() {
    let args = {};

    this.ruleArgumentsBox.find(':input').each((index, el) => {
      const { key, name, value } = this.compileArgument(el);

      args[key] = { name, value };
    });


    this.ruleArguments = args;
  }

  setRulePreview() {
    let conditionKey = this.ruleArguments.condition ? this.ruleArguments.condition.value : Object.keys(this.conditionsTemplates)[0];
    let currentTemplate = this.conditionsTemplates[conditionKey];

    Object.entries(this.ruleArguments).forEach(([k, v]) => {
      currentTemplate = currentTemplate.replace(new RegExp('{'+ k +'}','g'), escapeHTML(v.name) || `{${k}}`);
    });

    this.rulePreviewBox.html(currentTemplate);
  }

  updateRulePreview(e) {
    const { key, name, value } = this.compileArgument(e.target);

    this.ruleArguments[key] = { name, value };

    this.setRulePreview();
  }

  bindListeners() {
    this.ruleArgumentsBox.on('keyup change', ':input', (e, v) => this.updateRulePreview(e));
  }

  get conditionsTemplates() {
    return this.form.find('#conditions').data('templates');
  }

  get ruleArgumentsBox() {
    return this.form.find('.rule-arguments');
  }

  get rulePreviewBox() {
    return this.form.find('#rule-preview p');
  }
}

export default Arguments
