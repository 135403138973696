import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.handleContrastActivityChange();
    this.attachListeners();
  }

  handleContrastActivityChange() {
    this.contrastSettingsValuesContext.style.display = this.element.checked ? 'block' : 'none';
  }

  attachListeners() {
    this.element.addEventListener('change', (e) => this.handleContrastActivityChange());
  }

  get contrastSettingsValuesContext() {
    return document.querySelector('.contrast-settings-values-context');
  }
}
