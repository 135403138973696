import Base from 'src/components/summary/grids/ophthalmology/base'
import { DEFAULT_OPTIONS } from 'src/components/summary/constants/defaults'
import { AMSLER } from 'src/components/summary/constants/ophthalmology/amsler'
import moment from 'moment'

class Amsler extends Base {
  defaultParams() {
    return $.extend(true, {}, DEFAULT_OPTIONS, AMSLER);
  }

  pointClick(point, event) {
    // skip if not in drilldown mode
    if (point.drilldown) return;

    this.gotoCycleURL(point);
  }

  onActivateDaily(chart, e, response) {
    chart.xAxis[0].update({ plotBands: this.dailyPlotBands(response) }, false);
  }

  onDeactivateDaily(chart) {
    chart.xAxis[0].update({ plotBands: [] }, false);
  }

  addDrilldownSeries(response, e) {
    response.series.forEach((object, index) => {
      this.instance.addSingleSeriesAsDrilldown(e.point, object, false);
    });
  }

  tooltipFormatter(context, tooltip) {
    let s = this.tooltipHeader(this.tooltipLabel(context.points[0].point));

    context.points.forEach((point) => {
      s += this.tooltipBody(point, this.tooltipResult(point.point));
    });

    return s;
  }

  tooltipLabel(point) {
    if (this.currentDataspace.mode == 'daily') return point.time;
    if (point.by_date) return moment(point.by_date).format('D MMM Y');

    return `${moment(point.start_date).format('D MMM Y')} - ${moment(point.end_date).format('D MMM Y')}`;
  }

  tooltipResult(point) {
    return point.y;
  }

  unitAlias(point) {
    return point.series.userOptions.unit || '';
  }

  get options() {
    let { eye, group } = this.params;

    return {
      eye,
      group,
      category: 'ophthalmology',
      widget: 'amsler'
    };
  }
}

export default Amsler
