import Base from 'src/components/summary/grids/ophthalmology/base'
import { DEFAULT_OPTIONS } from 'src/components/summary/constants/defaults'
import { COLOR } from 'src/components/summary/constants/ophthalmology/color'
import moment from 'moment'

class Color extends Base {
  defaultParams() {
    return $.extend(true, {}, DEFAULT_OPTIONS, COLOR);
  }

  onLoadRequestEnd(chart, response) {
    super.onLoadRequestEnd(chart, response);

    this.redrawTrendline();
  }

  onDrilldownRequestEnd(chart, e, response) {
    super.onDrilldownRequestEnd(chart, e, response);

    this.drawTrendline();
  }

  onDrillupStart() {
    this.removeTrendline();

    super.onDrillupStart();
  }

  onDrillupEnd(chart) {
    this.redrawTrendline();
  }

  onActivateDaily(chart, e, response) {
    chart.xAxis[0].update({ plotBands: this.dailyPlotBands(response) }, false);
  }

  onDeactivateDaily(chart) {
    chart.xAxis[0].update({ plotBands: [] }, false);
  }

  tooltipFormatter(context, tooltip) {
    return this.tooltipHeader(this.tooltipLabel(context.point)) +
           this.tooltipBody(context.point, this.tooltipResult(context.point));
  }

  tooltipLabel(point) {
    if (this.currentDataspace.mode == 'daily') return point.time;
    if (point.by_date) return moment(point.by_date).format('D MMM Y');

    return `${moment(point.start_date).format('D MMM Y')} - ${moment(point.end_date).format('D MMM Y')}`;
  }

  tooltipResult(point) {
    return point.y;
  }

  unitAlias(point) {
    return this.unitsAliases[point.series.userOptions.unit] || point.series.userOptions.unit || '';
  }

  get unitsAliases() {
    return {};
  }

  get options() {
    let { eye } = this.params;

    return {
      eye,
      category: 'ophthalmology',
      widget: 'color'
    };
  }
}

export default Color
