import { handleActions } from 'redux-actions'

import {
  getListReceive,
  getListRequest,
  setRequestErrors,
  switchVisibilityAction,
  switchShowAllAction,
  deleteFromListAction,
  changeScrollPositionsAction,
  busyBookmarkAction,
  changeBookmarkAction,
  failureBookmarkAction,
  busyTransmitAction,
  completeTransmitAction,
  setPageAction
} from './actions'

const initialState = {};

export default handleActions({
  [ getListReceive ]: (state) => ({
    ...state,
    isLoading: true
  }),
  [ setRequestErrors ]: (state, { payload: errors }) => ({
    ...state,
    errors,
    isLoading: false
  }),
  [ busyTransmitAction ]: (state, { payload }) => ({
    ...state,
    transmitBusy: true
  }),
  [ completeTransmitAction ]: (state, { payload: flash }) => ({
    ...state,
    flash,
    transmitBusy: false
  }),
  [ switchVisibilityAction ]: (state, { payload }) => ({
    ...state,
    ...payload
  }),
  [ setPageAction ]: (state, { payload: page }) => ({
    ...state,
    page
  }),
  [ changeScrollPositionsAction ]: (state, { payload }) => ({
    ...state,
    ...payload
  }),
  [ switchShowAllAction ]: (state, { payload: showAll }) => ({
    ...state,
    showAll
  }),
  [ busyBookmarkAction ]: (state, { payload: id }) => {
    return {
      ...state,
      cycles: state.cycles.map((cycle) => {
        return cycle.id === id ?
          {
            ...cycle,
            bookmarkBusy: true
          } :
          cycle;
      })
    };
  },
  [ changeBookmarkAction ]: (state, { payload: { id, bookmarked } }) => {
    return {
      ...state,
      cycles: state.cycles.map((cycle) => {
        return cycle.id === id ?
          {
            ...cycle,
            bookmarked,
            bookmarkBusy: false
          } :
          cycle;
      })
    };
  },
  [ failureBookmarkAction ]: (state, { payload: id }) => {
    return {
      ...state,
      cycles: state.cycles.map((cycle) => {
        return cycle.id === id ?
          {
            ...cycle,
            bookmarkBusy: false
          } :
          cycle;
      })
    };
  },
  [ deleteFromListAction ]: (state, { payload: id }) => {
    return {
      ...state,
      blacklist: [...state.blacklist, id],
      cycles: state.cycles.filter((cycle) => cycle.id !== id)
    };
  },
  [ getListRequest ]: (state, { payload: { cycles, total: totalCount, complete: isComplete } }) => {
    const currentCycles = [...cycles.reverse(), ...state.cycles];
    const loadedCount = cycles.length;

    return ({
      ...state,
      isLoading: false,
      cycles: currentCycles,
      isComplete,
      totalCount,
      loadedCount
    });
  }
}, initialState);
