import React, { useRef, useEffect, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import { useInView } from 'react-intersection-observer'
import useDidUpdateEffect from 'src/react/hooks/useDidUpdateEffect'

import * as selectors from 'src/react/store/Compares/selectors'
import { getList, setPage, changeScrollPositionsAction } from 'src/react/store/Compares/actions'

import Card from './Card'
import Preloader from 'src/react/components/Compares/Preloader'
import Empty from 'src/react/components/Compares/Empty'
import Errors from 'src/react/components/Compares/Errors'

import { SIDE_SLIPPAGE_INDENT } from 'src/react/constants/Compares/index'

const ScrollableCards = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => selectors.isLoading(state));
  const isComplete = useSelector(state => selectors.isComplete(state));
  const page = useSelector(state => selectors.page(state));
  const cycles = useSelector(state => selectors.cycles(state));
  const leftVisible = useSelector(state => selectors.leftVisible(state));
  const rightVisible = useSelector(state => selectors.rightVisible(state));
  const canScrollLeft = useSelector(state => selectors.canScrollLeft(state));
  const canScrollRight = useSelector(state => selectors.canScrollRight(state));
  const errors = useSelector(state => selectors.errors(state));

  const availableCount = useSelector(state => selectors.availableCount(state));
  const loadedCount = useSelector(state => selectors.loadedCount(state));

  const leftArrowActivity = classnames('slider-control prev-link', { 'slider-control-active': canScrollLeft, 'slider-control-disabled': !canScrollLeft }, { 'slider-control-hidden': availableCount <= 3 });
  const rightArrowActivity = classnames('slider-control next-link', { 'slider-control-active': canScrollRight, 'slider-control-disabled': !canScrollRight }, { 'slider-control-hidden': availableCount <= 3 });

  const node = useRef(null);

  const { ref: loader, inView, entry } = useInView({
    threshold: 0.1,
    initialInView: true
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [cycles]);

  useDidUpdateEffect(() => {
    if (isComplete) return;

    dispatch(getList(page));
  }, [page]);

  useEffect(() => {
    if (!inView) return;

    dispatch(setPage(page == null ? 1 : page + 1));
  }, [inView]);

  useEffect(() => {
    setScrollPosition();

    node.current.addEventListener('scroll', setScrollPosition);

    return () => node.current.removeEventListener('scroll', setScrollPosition);
  }, [isLoading]);

  useLayoutEffect(() => {
    if (isLoading) return;

    scrollContainer();
  }, [isLoading]);

  const scrollContainer = () => {
    if (page == 1) {
      scrollContainerBy(node.current.scrollWidth, { behavior: 'smooth' });
    } else {
      // Determine position of latest loaded card element
      let scrollToChild = node.current.querySelectorAll('.card')[loadedCount - 1];

      if (scrollToChild) scrollToChild.scrollIntoView();
    }
  }

  const setScrollPosition = () => {
    const { scrollLeft, scrollWidth, clientWidth } = node.current;

    const canScrollLeft = scrollLeft > SIDE_SLIPPAGE_INDENT;
    const canScrollRight = (scrollLeft + SIDE_SLIPPAGE_INDENT) <= scrollWidth - clientWidth;

    dispatch(changeScrollPositionsAction({ canScrollLeft, canScrollRight }));
  }

  const scrollContainerBy = (distance, options = {}) => {
    node.current.scrollBy({ left: distance, ... options });
  }

  if (errors) {
    return (
      <div>
        <Errors errors={errors} />
      </div>
    );
  }

  return (
    <div>
      {
        !isLoading && availableCount == 0 && <Empty />
      }

      <div className="cards-slider">
        <div
          className={leftArrowActivity}
          onClick={
            () => scrollContainerBy(-500, { behavior: 'smooth' })
          }
        >
          <span className="arrow-left">
           <i className="mdi mdi-36px mdi-arrow-left-bold-circle"></i>
         </span>
        </div>

        <div
          className={rightArrowActivity}
          onClick={
            () => scrollContainerBy(500, { behavior: 'smooth' })
          }
        >
         <span className="arrow-right">
           <i className="mdi mdi-36px mdi-arrow-right-bold-circle"></i>
         </span>
        </div>

        {
          (isLoading && availableCount == 0) && <Preloader />
        }

        <div
          className="cards-stack"
          ref={node}
        >
          <div
            ref={loader}
            style={{ minWidth: '10px', background: 'transparent'} }
          >
          </div>

          {
            cycles.map((cycle, index) => (
              <Card
                key={cycle.id}
                cycle={cycle}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default ScrollableCards
