import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    if (data.location) {
      window.location = data.location;
    }

    if (data.change_status_modal) {
      $('body').
      findOrAppend('#change-status-modal', '<div id="change-status-modal" />').
      html(data.change_status_modal).
      find('.modal').
      modal('show');
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;

    if (data.change_status_modal) {
      $('#change-status-modal .modal-content').
      replaceWith(data.change_status_modal);
    }
  }
}
