import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    if (data.location) {
      window.location = data.location;
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;

    if (data.form) {
      $('#change-modal .modal-content').
        replaceWith(data.form);
    }
  }
}
