import { Controller } from 'stimulus'
import $ from 'jquery'
import 'selectize'

export default class extends Controller {
  connect() {
    const url = this.element.dataset.url;

    $(this.element).selectize({
      valueField: 'code',
      labelField: 'name',
      searchField: ['code', 'name'],
      maxItems: 1,
      closeAfterSelect: true,
      create: false,
      render: {
        option: function(item, escape) {
          let title = item.name ? ' ' + escape(item.name) : '';
          return '<div><span>' + title + '</span>&nbsp;<span class="type-abbr">('  + escape(item.code) + ')</span></div>';
        }
      },
      load: function(query, callback) {
        this.clearOptions();

        if (!query.length) return callback();

        $.ajax({
          type: 'GET',
          url: url,
          data: { search: query },
          error: () => callback(),
          success: (response) => callback(response.data)
        })
      },
      onInitialize: function() {
        this.$wrapper.removeClass('single');
      }
    });
  }
}
