import React from 'react'
import { useSelector } from 'react-redux'

import * as selectors from 'src/react/store/TrainingWizard/selectors'

import Wizard from './Wizard'

const WizardBoard = (props) => {
  const activeSnippetId = useSelector(state => selectors.activeSnippetId(state));

  return (
    <div className="wizard">
      {
        activeSnippetId && (
          <Wizard
            snippetId={activeSnippetId}
          />
        )
      }
    </div>
  );
}

export default WizardBoard
