import React from 'react'

const DeleteMessage = ({ entry, entity }) => {
  if (entry == 'category') {
    return (
      <>
        Do you confirm deletion of the category <strong>{entity.name}</strong> with all its content?
      </>
    );
  }

  if (entry == 'snippet') {
    return (
      <>
        Do you confirm deletion of the snippet <strong>{entity.name}</strong> with all its content?
      </>
    );
  }

  if (entry == 'question') {
    return (
      <>
        Do you confirm deletion of the question with all its content?
      </>
    );
  }

  return null;
};

export default DeleteMessage
