import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown } from 'react-bootstrap'

import Ability from './Ability'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { showFormModalAction, showActivateTrainingModalAction, showCopyModalAction } from 'src/react/store/TrainingWizard/actions'

import Status from 'src/react/components/TrainingWizard/Status'

const Controls = (props) => {
  const dispatch = useDispatch();
  const training = useSelector(state => selectors.training(state));

  const { name, status, threshold, created_at, status_changed_at } = training;

  return (
    <div className="page-head">
      <ol className="breadcrumb breadcrumb-top">
        <li className="breadcrumb-item">
          <a href={window.training.trainings_path}>Trainings</a>
        </li>
        <li className="breadcrumb-item active">
          {name}
        </li>
      </ol>

      <div className="page-head-row">
        <div className="page-head-col">
          <div className="training-card">
            <div>
              <div className="training-info">
                <div className="training-name">
                  {name}
                </div>
              </div>
              <div className="training-statuses">
                <Status status={status} />

                <div>Success Threshold: {threshold}%</div>
                <div>Created: {created_at}</div>
                <div>Status updated: {status_changed_at}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-head-col actions-col">
          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="actions-dropdown"
            >
              <div className="mobile-hidden">Actions</div>
              <div className="mdi mdi-18px mdi-custom-actions"></div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Ability
                context="editable_training"
              >
                <Dropdown.Item
                  as="button"
                  onClick={
                    () => dispatch(showFormModalAction({ entry: 'training' }))
                  }
                >
                  Edit
                </Dropdown.Item>
              </Ability>

              <Ability
                context="activateable_training"
              >
                <Dropdown.Item
                  as="button"
                  onClick={
                    () => dispatch(showActivateTrainingModalAction())
                  }
                >
                  Activate
                </Dropdown.Item>
              </Ability>

              <Dropdown.Item
                as="button"
                onClick={
                  () => dispatch(showCopyModalAction({ entry: 'training' }))
                }
              >
                Copy
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default Controls
