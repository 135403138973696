import Base from 'src/components/summary/grids/base'
import { DEFAULT_OPTIONS } from 'src/components/summary/constants/defaults'
import { BLOOD_PRESSURE } from 'src/components/summary/constants/cardiology/blood_pressure'
import moment from 'moment'

class BloodPressure extends Base {
  defaultParams() {
    return $.extend(true, {}, DEFAULT_OPTIONS, BLOOD_PRESSURE);
  }

  onActivateDaily(chart, e, response) {
    chart.yAxis[1].update({ visible: true }, false);
  }

  onDeactivateDaily(chart) {
    chart.yAxis[1].update({ visible: false }, false);
  }

  tooltipFormatter(context, tooltip) {
    let s = this.tooltipHeader(this.tooltipLabel(context.points[0].point));

    context.points.forEach((point) => {
      s += this.tooltipBody(point, this.tooltipResult(point.point));
    });

    return s;
  }

  tooltipLabel(point) {
    if (this.currentDataspace.mode == 'daily') return point.time;
    if (point.by_date) return moment(point.by_date).format('D MMM Y');

    return `${moment(point.start_date).format('D MMM Y')} - ${moment(point.end_date).format('D MMM Y')}`;
  }

  tooltipResult(point) {
    return (this.currentDataspace.mode == 'range') ? `${point.low} - ${point.high}` : point.y;
  }

  get options() {
    return {
      category: 'cardiology',
      widget: 'blood_pressure'
    }
  }
}

export default BloodPressure
