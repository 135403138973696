import { DEFAULT_ANSWER_LINE } from 'src/react/constants/TrainingWizard/index'

import { createSelector } from 'reselect'

const getState = state => state.trainings;
const isLoading = state => getState(state).isLoading;
const formAction = state => getState(state).formAction;
const formEntry = state => getState(state).formEntry;
const formEntryId = state => getState(state).formEntryId;
const formModalShow = state => getState(state).formModalShow;
const formOptions = state => getState(state).formOptions;
const activeSnippetId = state => getState(state).activeSnippetId;
const activeCategoryId = state => getState(state).activeCategoryId;
const training = state => getState(state).training;
const categories = state => training(state).categories;

const categoriesTree = state => getState(state).categories;
const snippetsTree = state => getState(state).snippets;
const questionsTree = state => getState(state).questions;
const answersTree = state => getState(state).answers;

const getСategoryById = (state, id) => categoriesTree(state)[id];
const getSnippetById = (state, id) => snippetsTree(state)[id];
const getQuestionById = (state, id) => questionsTree(state)[id];
const getAnswerById = (state, id) => answersTree(state)[id];

const categoriesCount = createSelector(categories, (items => items.length));

const categoriesForSelect = createSelector(
  [categories, categoriesTree],
  ((items, tree) => items.map(id => tree[id]))
);

const getFormQuestionById = createSelector(
  [getQuestionById, answersTree],
  (question, tree) => {
    const { answers, ... rest } = question;

    const prepareAnswers = (answers) => {
      return answers.length ? answers.map(answerId => tree[answerId]) : [DEFAULT_ANSWER_LINE];
    };

    return {
      ... rest,
      answers: prepareAnswers(answers)
    };
  }
);

export {
  getState,
  isLoading,
  formAction,
  formEntry,
  formEntryId,
  formModalShow,
  formOptions,
  training,
  categories,
  categoriesCount,
  categoriesForSelect,
  activeCategoryId,
  activeSnippetId,
  getСategoryById,
  getSnippetById,
  getQuestionById,
  getAnswerById,
  getFormQuestionById
}
