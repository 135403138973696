import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.stopPropagation();
    this.toggleField();
    this.setListeners();
  }

  setListeners() {
    if (this.switcher) {
      this.switcher.addEventListener('change', () => this.toggleField());
    }

    this.plusBtn.addEventListener('click', () => {
      if (this.input.value < this.maxValue) {
        this.input.value = this.inputValue + 1;
      }
    });

    this.minusBtn.addEventListener('click', () => {
      if (this.input.value > this.minValue) {
        this.input.value = this.inputValue - 1;
      }
    });
  }

  toggleField() {
    if (!this.switcher) return;

    if (this.switcher.checked) {
      this.element.classList.remove('disabled-input');
      this.input.removeAttribute('disabled');
    } else {
      this.element.classList.add('disabled-input');
      this.input.setAttribute('disabled', true);
    }
  }

  stopPropagation() {
    this.plusBtn.addEventListener('click', (e) => this.disableEventTriggering(e));
    this.minusBtn.addEventListener('click', (e) => this.disableEventTriggering(e));
  }

  disableEventTriggering(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  get switcher() {
    return document.getElementById(this.switcherId);
  }

  get input() {
    return this.element.querySelector('input');
  }

  get inputValue() {
    return parseInt(this.input.value);
  }

  get plusBtn() {
    return this.element.querySelector('.plus-btn');
  }

  get minusBtn() {
    return this.element.querySelector('.minus-btn');
  }

  get minValue() {
    const { minValue } = this.element.dataset;
    return +minValue || 0;
  }

  get maxValue() {
    const { maxValue } = this.element.dataset;
    return +maxValue || 100;
  }

  get switcherId() {
    const { switcherId } = this.element.dataset;
    return switcherId;
  }
}
