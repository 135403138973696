import $ from 'jquery'

const init = () => {
  // tooltips
  $('[data-toggle="tooltip"]').tooltip({
    container: 'body'
  })

  // popovers
  $('[data-toggle="popover"]').popover({
    container: 'body'
  })

  // Prevent Connections Dropdown closes on click
  $(document).on('click', '.be-connections', (e) => {
    e.stopPropagation();
  });

  // Bootstrap modal scroll top fix
  $(document).on('show.bs.modal', '.modal', () => {
    $('html').addClass('be-modal-open');
  });

  $(document).on('hidden.bs.modal', '.modal', () => {
    $('html').removeClass('be-modal-open');
  });

  // async listeners
  $(document).on('ajax:before', '.async', function(event, xhr, status, error) {
    const self = $(this);

    if (self.data('await')) {
      self.data('original', self.html());
      self.html(self.data('await'));
    }

    self.prop('disabled', true);
  });

  $(document).on('ajax:complete', '.async', function(event, xhr, status, error) {
    const self = $(this);

    if (self.data('await')) {
      self.html(self.data('original'));
    }

    self.prop('disabled', false);
  });

  // focus listeners
  $(document).on('shown.bs.modal', '.modal', function() {
    $(this).find('[autofocus]').focus();
  });

  //no scroll body if dropdown open
  $('.scroll-dropdown').on('show.bs.dropdown', function () {
    $("body").addClass("no-scroll");
  });

  $('.scroll-dropdown').on('hide.bs.dropdown', function () {
    $("body").removeClass("no-scroll");
  });


}

export { init }
