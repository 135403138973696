const QUESTION_TYPES = [
  { value: 'optionable', label: 'Multiple Choice' },
  { value: 'free', label: 'Free answer (input)' }
];

const DEFAULT_CATEGORY_FORM = { name: '' };
const DEFAULT_SNIPPET_FORM = { name: '' };

const DEFAULT_ANSWER_LINE = {
  correct: false,
  answer: '',
  score: 0
};

const DEFAULT_QUESTION_FORM = {
  question_type: 'optionable',
  question: '',
  answers: [
    { ... DEFAULT_ANSWER_LINE, correct: true },
    DEFAULT_ANSWER_LINE
  ]
};

export {
  QUESTION_TYPES,
  DEFAULT_ANSWER_LINE,
  DEFAULT_CATEGORY_FORM,
  DEFAULT_SNIPPET_FORM,
  DEFAULT_QUESTION_FORM
}
