const safeParams = (query) => query ? query.split(',').map(i => Number(i)) : [];

const combineOptions = (blacklist) => {
  const {
    ids,
    from,
    to,
    without_ids,
    only_amsler
  } = window.compares;

  const listable = () => {
    return {
      ids: safeParams(ids).filter((i) => !blacklist.includes(i)).join(',')
    };
  }

  const rangeable = () => {
    const filterIds = [...new Set([...safeParams(without_ids), ...blacklist])].join(',');

    let options = { from, to };

    if (filterIds) options.without_ids = filterIds;

    return options;
  };

  let options = ids ? listable() : rangeable();

  if (only_amsler) options.only_amsler = only_amsler;

  return options;
}

export default combineOptions
