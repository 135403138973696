import $ from 'jquery'
import escape from 'src/utils/sanitize'
import 'devbridge-autocomplete'
import { createStore } from 'redux'

$.fn.membershipsFormBindings = function(options) {
  const self = $(this);

  const { type } = options;

  const autocomplete_box = self.find('.autocomplete-select');
  const autocomplete_url = autocomplete_box.data('url');

  const memberships_box = self.find('.memberships-context');
  const memberships_list = memberships_box.find('.list');
  const memberships_count = memberships_box.find('.count');

  const applyButton = self.find('.apply-context')

  const store = createStore((state = [], action) => {
    switch (action.type) {
      case 'ADD_MEMBERSHIP':
        return [...state, action.id];
      case 'REMOVE_MEMBERSHIP':
        return state.filter((id) => id !== action.id);
      default:
        return state;
    }
  });

  store.subscribe(() => updateUI(store.getState()));

  const updateUI = (state) => {
    const count = state.length;

    memberships_count.html(count);
    memberships_box.toggleClass('d-none', (count <= 0));
    applyButton.prop('disabled', (count <= 0));
  };

  const autocomplete = () => {
    autocomplete_box.autocomplete({
      serviceUrl: autocomplete_url,
      dataType: 'json',
      minChars: 0,
      deferRequestBy: 200,
      appendTo: '#edit-modal .modal',
      forceFixPosition: true,
      showNoSuggestionNotice: true,
      noSuggestionNotice: `<div class="p-2"> No results <div>`,
      formatResult(suggestion, currentValue) {
        return formatResults(suggestion, currentValue);
      },
      onSelect(suggestion) {
        return onSelectEntry(suggestion);
      }
    });
  }

  const formatResults = (suggestion, currentValue) => {
    switch (type) {
      case 'patient_groups':
        return formarPatientGroups(suggestion, currentValue);
      case 'members':
        return formatUsers(suggestion, currentValue, 'member')
      case 'patients':
        return formatUsers(suggestion, currentValue, 'patient')
    }
  }

  const formarPatientGroups = (suggestion, currentValue) => {
    return `
      <div class="patient-group-list-widget">
        <div>
          <div class="name"> ${escape(currentValue, suggestion.data.name)} </div>
          ${alreadyExists(suggestion) ? '<div class="already-exists"> Already assigned </div>' : ''}
        </div>

        ${archived(suggestion) ? '<span class="custom-badge"> Deactivated </span>' : ''}
        ${alreadyAdded(suggestion) ? '<div class="custom-badge"> Added </div>' : ''}
      </div>
    `;
  }

  const formatUsers = (suggestion, currentValue, roleType) => {
    return `
      <div class="user-list-widget">
        <div class="avatar" 
          data-controller="helpers--avatar" 
          data-image-url="${suggestion.data.avatar.thumb.url}"
          data-name="${suggestion.data.name}" 
          data-role="${roleType}" 
          data-size="32">
        </div>
        
        <div class="user-between-with-bange">
          <div>
            <div class="name"> ${escape(currentValue, suggestion.data.name)} </div>
            <div class="email"> ${escape(currentValue, suggestion.data.email)} </div>
            ${alreadyExists(suggestion) ? '<div class="already-exists"> Already assigned </div>' : ''}
          </div>

          ${archived(suggestion) ? '<span class="custom-badge"> Deactivated </span>' : ''}
          ${alreadyAdded(suggestion) ? '<div class="custom-badge"> Added </div>' : ''}
       </div>
     </div>`;
  }

  const onSelectEntry = (suggestion) => {
    autocomplete_box.val('');

    if (alreadyExists(suggestion)) return;
    if (alreadyAdded(suggestion)) return;

    const membership =
      suggestion.membership.replace(/new_membership_index/gi, (new Date().getTime()));

    memberships_list.append(membership);

    store.dispatch({ type: 'ADD_MEMBERSHIP', id: suggestion.data.id });
  }

  const archived = (suggestion) => {
    return suggestion.data.membership_archived;
  }

  const alreadyExists = (suggestion) => {
    return suggestion.data.membership_exists;
  }

  const alreadyAdded = (suggestion) => {
    return store.getState().includes(suggestion.data.id);
  }

  self.on('click', '.membership-remove', function(e) {
    const element = $(this).
      parents('.membership-fields').
      remove();

    store.dispatch({ type: 'REMOVE_MEMBERSHIP', id: element.data('reference') });
  });

  // initializers
  autocomplete();
};
