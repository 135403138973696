import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    if (data.location) {
      window.location = data.location;
    }

    if (data.state_modal) {
      $('body').
        findOrAppend('#state-modal', '<div id="state-modal" />').
        html(data.state_modal).
        find('.modal').
        modal('show');
    }
  }

  onError(event) {
    let [data, status, xhr] = event.detail;

    if (data.state_form) {
      $('#state-modal .modal-content').replaceWith(data.state_form);
    }

    if (data.rule_form) {
      $('.rule-form').replaceWith(data.rule_form);
    }
  }
}
