import { createSelector } from 'reselect'

const getState = state => state.compares;
const isLoading = state => getState(state).isLoading;
const isComplete = state => getState(state).isComplete;
const leftVisible = state => getState(state).leftVisible;
const rightVisible = state => getState(state).rightVisible;
const canScrollLeft = state => getState(state).canScrollLeft;
const canScrollRight = state => getState(state).canScrollRight;
const showAll = state => getState(state).showAll;
const cycles = state => getState(state).cycles;
const page = state => getState(state).page;
const errors = state => getState(state).errors;
const flash = state => getState(state).flash;
const transmitBusy = state => getState(state).transmitBusy;
const blacklist = state => getState(state).blacklist;

const loadedCount = state => getState(state).loadedCount;
const totalCount = state => getState(state).totalCount;

const currentCount = state => cycles(state).length;
const blacklistCount = state => blacklist(state).length;

const availableCount = createSelector(
  [totalCount, blacklistCount],
  (total, blacklist) => total - blacklist
);

export {
  isLoading,
  isComplete,
  leftVisible,
  rightVisible,
  canScrollLeft,
  canScrollRight,
  showAll,
  cycles,
  page,
  errors,
  flash,
  transmitBusy,
  blacklist,
  loadedCount,
  currentCount,
  availableCount
}
