import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const avatar = this.element;
    const { name, role, imageUrl } = avatar.dataset;

    if (imageUrl != 'null') {
      avatar.innerHTML = `<img src="${imageUrl}" width="50" height="50" alt="avatar" />`;
    } else {
      avatar.innerHTML = `
        <div class="user-avatar">
          <div class="avatar-middle ${role}-background">
            <div class="initials">
              ${this.getAvatarName(name)}
            </div>
          </div>
        </div>
      `;
    }
  }

  getAvatarName(name) {
    const chunks = this.splitByChunks(name);
    const [ lastName, firstName ] = [chunks[0], chunks[chunks.length - 1]];

    return (lastName[0] + firstName[0]).toUpperCase();
  }

  splitByChunks(name) {
    return name.replace(/\s+/g, ' ').trim().split(' ');
  }
}
