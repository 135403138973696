import { normalize, schema } from 'normalizr'

const answer = new schema.Entity('answers');
const question = new schema.Entity('questions', { answers: [answer] });
const snippet = new schema.Entity('snippets', { questions: [question] });
const category = new schema.Entity('categories', { snippets: [snippet] });

const normalizeTraining = (data) => normalize(data, { categories: [category] });
const normalizeCategory = (data) => normalize(data, { snippets: [snippet] });
const normalizeSnippet = (data) => normalize(data, { questions: [question] });
const normalizeQuestion = (data) => normalize(data, { answers: [answer] });

export {
  normalizeTraining,
  normalizeCategory,
  normalizeSnippet,
  normalizeQuestion
}
