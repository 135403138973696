import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { activateTraining } from 'src/react/store/TrainingWizard/actions'

import { Button, Modal } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { SubmitError, SubmitButton } from 'src/react/components/FormFields/index'

const ActivateTrainingModal = (props) => {
  const { modal } = props;

  const dispatch = useDispatch();
  const training = useSelector(state => selectors.training(state));
  const onSubmit = async (values) => await dispatch(activateTraining(values));

  return (
    <Form
      onSubmit={onSubmit}
      render={({ submitError, handleSubmit, form, submitting, pristine, values }) => (
        <Modal
          {...modal}
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                Activate Training
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0">
              {
                submitError && (
                  <SubmitError
                    submitError={ submitError }
                  />
                )
              }
              <div className="bd-callout bd-callout-warning mt-0">
                <strong> Warning: </strong>
                <span>
                  Current active training will be deactivated on this action.
                  Are you sure you want to proceed with the activation of <strong>{training.name}</strong>?
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton
                type="submit"
                disabled={submitting}
                className="btn-primary"
              >
                Confirm
              </SubmitButton>
              <Button
                onClick={modal.onHide}
                className="btn-secondary"
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    />
  );
}

export default ActivateTrainingModal
