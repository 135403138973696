import { Controller } from 'stimulus'
import $ from 'jquery'
import 'selectize'

export default class extends Controller {
  connect() {
    const selected_value = this.data.get('selected')
    const selected = selected_value && JSON.parse(selected_value);

    let selectize = $(this.element).selectize({
      create: false,
      plugins: ['remove_button']
    });


    if (selected) {
      selectize[0].selectize.setValue(selected)
    }
  }
}
