import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    $('body').
      findOrAppend('#change-modal', '<div id="change-modal" />').
      html(data.content).
      find('.modal').
      modal('show').
      membershipsFormBindings({ type: 'members' });
  }

  onError(event) {
    let [data, status, xhr] = event.detail;

    $('#change-modal .errors-context').
    findOrAppend('.message', '<div class="message" />').
    html(data.errors);
  }
}
