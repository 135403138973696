import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { DEFAULT_CATEGORY_FORM } from 'src/react/constants/TrainingWizard/index'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { saveCategory } from 'src/react/store/TrainingWizard/actions'

import { Button, Modal, FormGroup, FormLabel } from 'react-bootstrap'
import { Form, Field } from 'react-final-form'
import { TextField, FieldError, SubmitError, SubmitButton } from 'src/react/components/FormFields/index'

const CategoryModal = (props) => {
  const { modal, formEntryId: entityId } = props;

  const dispatch = useDispatch();
  const category = entityId ? useSelector(state => selectors.getСategoryById(state, entityId)) : null;
  const onSubmit = async (values) => await dispatch(saveCategory(values, category));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={category || DEFAULT_CATEGORY_FORM}
      render={({ submitError, handleSubmit, form, submitting, pristine, values }) => (
        <Modal
          {...modal}
          size="lg"
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                { category ? 'Edit category' : 'New category' }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                submitError && (
                  <SubmitError
                    submitError={ submitError }
                  />
                )
              }
              <FormGroup>
                <FormLabel>
                  Name
                </FormLabel>
                <Field
                  name="name"
                  component={TextField}
                  placeholder="Enter category name ..."
                  type="text"
                />
                <FieldError name="name" />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton
                type="submit"
                disabled={submitting}
                className="btn-primary"
              >
                Submit
              </SubmitButton>
              <Button
                onClick={modal.onHide}
                className="btn-secondary"
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    />
  );
}

export default CategoryModal
