import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import Question from './Question'

const Questions = ({ isDragDisabled, questions, snippetId }) => {
  return (
    <Droppable
      droppableId={`question-${snippetId}`}
      type={`question-${snippetId}`}
    >
      {
        (dropProvided, dropSnapshot) => (
          <div
            className="questions"
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps}
          >
            {
              questions.map((questionId, index) => (
                <Draggable
                  draggableId={`question-${snippetId}-${questionId}`}
                  index={index}
                  key={questionId}
                >
                  {
                    (dragProvided, dragSnapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                      >
                        <Question
                          dragHandleProps={dragProvided.dragHandleProps}
                          questionId={questionId}
                          index={index}
                          isDragDisabled={isDragDisabled}
                        />
                      </div>
                    )
                  }
                </Draggable>
              ))
            }
            {dropProvided.placeholder}
          </div>
        )
      }
    </Droppable>
  );
}

export default Questions
