import React from 'react'
import daysRange from 'src/react/utils/Timesheets/daysRange'

import Day from './Day'

const Days = ({ from, to }) => {
  const days = daysRange(from, to);

  return (
    <div className="timesheet-week">
      {
        days.map((day) => (
          <Day day={day} key={day} />
        ))
      }
    </div>
  );
}

export default Days
