import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { components } from 'react-select'

import useDidUpdateEffect from 'src/react/hooks/useDidUpdateEffect'

import User from 'src/react/components/Timesheets/User'

const customStyle = {
  option: (base, { isSelected, isFocused }) => {
    let backgroundColor = base.backgroundColor;
    let color = base.color;

    if (isSelected) {
      color = 'inherit';
      backgroundColor = '#deebff';
    }

    if (isFocused) {
      backgroundColor = '#eee';
    }

    return {
      ...base,
      color,
      backgroundColor,
      "&:active": {
        backgroundColor: '#deebff'
      }
    };
  }
}

const Option = (props) => {
  const { name, email, avatar } = props.data;

  return (
    <components.Option {...props}>
      <User user={{ email, name, avatar }} />
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => {
  const { name, email, avatar } = props.data;

  return (
    <components.SingleValue {...props} >
      <strong>{name}</strong>
    </components.SingleValue>
  );
}

const Actionable = ({
  user,
  changeActionable,
  loadOptions,
  optionKey = 'id',
  ...rest
}) => {
  const [ value, setValue ] = useState(user);
  const [ options, setOptions ] = useState([user]);

  useEffect(() => {
    loadOptions().
      then(results => setOptions(results));
  }, []);

  useDidUpdateEffect(() => {
    changeActionable(value);
  }, [value[optionKey]]);

  const onChange = (option) => setValue(option);

  const filterOptions = ({ data: { name, email }}, inputValue) => {
    return name.toLowerCase().includes(inputValue.toLowerCase()) ||
           email.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <Select
      isSearchable
      blurInputOnSelect
      defaultValue={user}
      getOptionValue={option => option[optionKey]}
      filterOption={filterOptions}
      options={options}
      onChange={onChange}
      styles={customStyle}
      components={{
        Option,
        SingleValue
      }}
    />
  );
}

export default Actionable
