import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['differentEyesCheckbox', 'bothEyesBox', 'differentEyesBox']

  connect() {
    this.toggleDifferentEyes();
  }

  toggleDifferentEyes() {
    const isChecked = this.differentEyesCheckboxTarget.checked;
    this.toggleEyesBoxes(isChecked);
  }

  toggleEyesBoxes(showDifferent) {
    if (showDifferent) {
      this.bothEyesBoxTarget.style.display = 'none';
      this.displayDifferentEyesBox('block')
    } else {
      this.bothEyesBoxTarget.style.display = 'block';
      this.displayDifferentEyesBox('none')
    }
  }

  displayDifferentEyesBox(displayMode) {
    for (let eyeBox of this.differentEyesBoxTargets) {
      eyeBox.style.display = displayMode;
    }
  }
}
