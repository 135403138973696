import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data] = event.detail;

    if (data.location) {
      window.location = data.location;
    }

    if (data.form) {
      $('body').
        findOrAppend('#importer_form', '<div id="importer_form" />').
        html(data.form);
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;

    if (data.form) {
      $('#importer_form').html(data.form);
    }
  }
}
