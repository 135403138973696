import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    $(this.element).append('<div class="custom-file-upload">Choose file...</div>');

    $('.custom-file-upload').each(function(e) {
      $(this).on('click', function () {
        $(this).siblings('input[type="file"]').click();

        $('input[type="file"]').change(function() {
          const fileName = this.files[0].name;

          $(this).siblings('.custom-file-upload').text(fileName);
        });
      });
    });
  }
}
