import $ from 'jquery'

const offset = 220;
const duration = 300;
const button = $('<div class="be-scroll-top"></div>');

const init = () => {
  scrollTopButton()
}

const scrollTopButton = () => {
  button.appendTo("body");

  $(window).on('scroll',function() {
    if ($(this).scrollTop() > offset) {
      button.fadeIn(duration);
    } else {
      button.fadeOut(duration);
    }
  });

  button.on('touchstart mouseup', (e) => {
    $('html, body').animate({ scrollTop: 0 }, duration);
    e.preventDefault();
  });
}

export { init }
