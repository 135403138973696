import { presence } from 'src/utils/formatters'
import { FORM_ERROR } from 'final-form'

const trainingForm = (values) => {
  const form = new FormData();

  form.append('training[name]', presence(values.name));
  form.append('training[threshold]', presence(values.threshold));

  return form;
};

const categoryForm = (values) => {
  const form = new FormData();

  form.append('category[name]', presence(values.name));

  return form;
};

const snippetForm = (values) => {
  const form = new FormData();

  form.append('snippet[name]', presence(values.name));
  form.append('snippet[category_id]', presence(values.category_id));
  form.append('snippet[video]', presence(values.video));
  form.append('snippet[content]', presence(values.content));

  return form;
};

const questionForm = (values) => {
  const form = new FormData();

  form.append('question[question]', presence(values.question));
  form.append('question[question_type]', presence(values.question_type));

  if (values['image-file']) {
    form.append('question[image]', values['image-file']);
  }

  if (values.question_type == 'optionable') {
    values.answers.forEach((answer, index) => {
      let key = `question[answers_attributes][${index}]`;

      form.append(`${key}[id]`, presence(answer.id));
      form.append(`${key}[answer]`, presence(answer.answer));
      form.append(`${key}[score]`, presence(answer.score));
      form.append(`${key}[correct]`, presence(answer.correct));

      if (answer['image-file']) {
        form.append(`${key}[image]`, answer['image-file']);
      }
    });
  }

  return form;
};

const formErrors = (err) => {
  const { data, status, statusText } = err.response;
  const { base, error, ... rest } = data;

  if ([422].includes(status)) {
    return {
      ... rest,
      [FORM_ERROR]: (base || error)
    };
  }

  return {
    [FORM_ERROR]: error
  };
};

export {
  formErrors,
  trainingForm,
  categoryForm,
  snippetForm,
  questionForm
}
