class Base {
  constructor(selector, storage) {
    this.selector = selector;
    this.storage = storage;

    this.init();
  }

  init() {
    this.buildInstances();
    this.buildCallbacks();
  }

  buildCallbacks() {
    this.chart.callbacks['onDrilldown'] = (chart, e) => {
      this.showLoading();

      this.chart.fetchDrilldownResults(chart, e).
        then(result => this.hideLoading()).
        catch(jqXHR => factory.handleLoadingErrors(jqXHR));
    }

    this.chart.callbacks['onChangeDrilldownLevel'] = (level) => {};
  }

  fetchRootResults() {
    return this.chart.fetchRootResults();
  }

  updateTarget(callback = null) {
    this.showLoading();

    if (typeof(callback) === 'function') callback();

    return this.fetchRootResults().
      then(result => this.hideLoading()).
      catch(jqXHR => factory.handleLoadingErrors(jqXHR));
  }

  applyNavigationOptions(options) {
    this.storage.updateParams(options);
  }

  showLoading() {
    this.chart.showLoading();
  }

  hideLoading() {
    this.chart.hideLoading();
  }

  handleLoadingErrors(jqXHR) {
    this.hideLoading();

    console.log(jqXHR);

    //this.chart.alert(jqXHR.statusText);

    console.log(this.selector.dataset, jqXHR.responseJSON);
  }

  get params() {
    return this.selector.dataset;
  }

  get grid_selector() {
    return this.selector.querySelector('[data-grid]');
  }
}

export default Base
