import $ from 'jquery'
import 'selectize'
import 'src/plugins/selectize/noResults'

class Participants {
  constructor(form) {
    this.form = form;

    this.init();
  }

  init() {
    this.initializeSelectize();
    this.initializeSwitchers();
  }

  initializeSwitchers() {
    this.switchSelectParticipantsBox();
    this.switchAllParticipantsBox();

    this.form.on('change', '#rule_namespace', (e) => {
      this.resetParticipants();
      this.switchSelectParticipantsBox();
      this.switchAllParticipantsBox()
    });
  }

  initializeSelectize() {
    const self = this;

    this.selectize = this.participantsBox.selectize({
      plugins: ['no_results'],
      valueField: 'id',
      searchField: 'name',
      loadThrottle: 300,
      dataAttr: 'data-hydrate',
      render: {
        item: function(item, escape) {
          return self.formatResults(item, escape);
        },
        option: function(item, escape) {
          return self.formatResults(item, escape);
        }
      },
      onChange: function() {
        if (self.individualNamespaceSelected()) {
          this.clearOptions();
          this.clearCache();
        }
      },
      onDelete: function() {
        if (self.individualNamespaceSelected()) {
          self.resetParticipants();
        }
      },
      onType: function(query) {
        this.clearOptions();
        this.refreshOptions();
      },
      load: function(query, callback) {
        if (!query && self.individualNamespaceSelected()) return callback();

        this.switchNoResults('hide');

        //if (!query.length) return callback();

        $.ajax({
          url: self.url,
          type: 'GET',
          data: {
            query: query,
            type: self.namespace
          },
          error: () => {
            callback();
          },
          success: (res) => {
            self.selectizeInstance.switchNoResults(
              (res.suggestions.length == 0 ? 'show' : 'hide')
            );

            callback(res.suggestions);
          }
        });
      }
    });
  }

  resetParticipants() {
    this.selectizeInstance.clear();
    this.selectizeInstance.clearOptions();
    this.selectizeInstance.clearCache();
  }

  switchSelectParticipantsBox() {
    const action = (this.namespace == 'medical_group') ? 'hide' : 'show';

    this.selectParticipantsContext[action]();
  }

  switchAllParticipantsBox() {
    const action = (this.namespace != 'medical_group') ? 'hide' : 'show';

    this.allParticipantsContext[action]();
  }

  individualNamespaceSelected() {
    return this.namespace == 'patient';
  }

  formatResults(item, escape) {
    switch (this.namespace) {
      case 'patient_group':
        return this.formatPatientGroups(item, escape);
      case 'patient':
        return this.formatUsers(item, escape);
      default:
        return null;
    }
  }

  formatPatientGroups(item, escape) {
    return `
      <div class="selectize-participants-widget">
        <div class="meta patient-groups">
          <div class="name">
            ${escape(item.name)}
            ${item.archived ? '<span class="badge badge-secondary">Archived</span>' : ''}
          </div>
        </div>
      </div>
    `;
  }

  formatUsers(item, escape) {
    return `
      <div class="selectize-participants-widget">
        <div class="avatar"
          data-controller="helpers--avatar"
          data-name="${item.name}"
          data-image-url="${item.avatar.thumb.url}"
          data-role="member">
        </div>

        <div class="meta users">
          <div class="content">
            <div class="name">
              ${escape(item.name)}
            </div>
            <div class="email">
              ${escape(item.email)}
            </div>
          </div>
          <div class="archived-badge">
            ${item.archived ? '<span class="badge badge-secondary">Archived</span>' : ''}
          </div>
        </div>
      </div>
    `;
  }

  get url() {
    return this.participantsBox.data('url');
  }

  get namespace() {
    return this.form.find('#rule_namespace input:checked').val();
  }

  get participantsBox() {
    return this.form.find('.selectize-rule-participants');
  }

  get selectParticipantsContext() {
    return this.form.find('.select-participants-context');
  }

  get allParticipantsContext() {
    return this.form.find('.all-participants-context');
  }

  get selectizeInstance() {
    return this.selectize[0].selectize;
  }
}

export default Participants
