import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    $('#actions-modal .modal').modal('hide');
    $(`#roster-${data.key}`).replaceWith(data.content);
    $('.flash-alerts').html(data.flash);
  }
}
