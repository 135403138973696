const ZERO_AXIS_POINT = {
  type: 'scatter',
  color: 'black',
  enableMouseTracking: false,
  showInLegend: false,
  animation: {
    duration: 0
  },
  marker: {
    symbol: 'diamond'
  },
  data: [
    [0, 0]
  ]
};

const ALTERNATIVE_COLORS = {
  light: '#ccc',
  medium: '#aaa',
  heavy: '#555',
  dark: '#000',
  crooked: '#f39686',
  double: '#99c588',
  uncertain: '#fbe695'
};

const CENTRAL_BOUNDARY_POLYGON = {
  type: 'scatter',
  color: 'limegreen',
  enableMouseTracking: false,
  showInLegend: false,
  lineWidth: 1,
  zIndex: 3,
  animation: {
    duration: 0
  },
  marker: {
    enabled: false
  },
  data: [
    [-5, -5], [-5, 5], [5, 5], [5, -5], [-5, -5]
  ]
};

const ZONES = {
  dark: {
    type: 'polygon',
    //color: '#000'
    color: 'rgba(0, 0, 0, 0.7)',
    zIndex: 7,
  },
  heavy: {
    type: 'polygon',
    //color: '#555'
    color: 'rgba(85, 85, 85, 0.7)',
    zIndex: 6
  },
  medium: {
    type: 'polygon',
    //color: '#aaa'
    color: 'rgba(170, 170, 170, 0.7)',
    zIndex: 5
  },
  light: {
    type: 'polygon',
    //color: '#ccc'
    color: 'rgba(204, 204, 204, 0.7)',
    zIndex: 4
  },
  uncertain: {
    type: 'polygon',
    color: {
      pattern: {
        path: {
          d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
          strokeWidth: 1,
          fill: 'rgba(255, 255, 153, 0.3)',
          stroke: 'rgb(249, 222, 114)'
        },
        width: 10,
        height: 10
      }
    },
    zIndex: 3
  },
  double: {
    type: 'polygon',
    color: {
      pattern: {
        path: {
          d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
          strokeWidth: 1,
          fill: 'rgba(32, 114, 0, 0.3)',
          stroke: 'rgb(32, 114, 0)'
        },
        width: 10,
        height: 10
      }
    },
    zIndex: 2
  },
  crooked: {
    type: 'polygon',
    color: {
      pattern: {
        path: {
          d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
          strokeWidth: 1,
          fill: 'rgba(255, 54, 19, 0.3)',
          stroke: 'rgb(255, 54, 19)'
        },
        width: 10,
        height: 10
      }
    },
    zIndex: 1
  }
};

const PDF_ZONES = $.extend(true, {}, ZONES, {
  crooked: {
    color: {
      pattern: {
        path: {
          strokeWidth: 0.14
        },
        width: 5,
        height: 5
      }
    },
    zIndex: 1
  },
  double: {
    color: {
      pattern: {
        path: {
          strokeWidth: 0.14
        },
        width: 5,
        height: 5
      }
    },
    zIndex: 2
  },
  uncertain: {
    color: {
      pattern: {
        path: {
          strokeWidth: 0.14,
          fill: 'rgba(255, 243, 0, 0.3)',
          stroke: 'rgb(255, 164, 23)'
        },
        width: 5,
        height: 5
      }
    },
    zIndex: 3
  }
});

const DEFAULT_OPTIONS = {
  title: {
    text: null,
    style: 'display: none;'
  },
  credits: {
    enabled: false
  },

  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 1
        }
      }
    }
  },
  xAxis: {
    labels: {
      rotation: -45,
      style: {
        fontSize: '9px'
      }
    },
    title: {
      text: null,
      style: 'display: none;'
    },
    tickPositioner(min, max) {
      const positions = [];

      let i = min;
      while (i <= max) {
        positions.push(i);
        i++;
      }

      return positions;
    },
    gridLineWidth: 1,
    plotLines: [
      {
        value: 0,
        width: 1,
        color: 'black',
        zIndex: 1
      }
    ]
  },
  yAxis: {
    labels: {
      style: {
        fontSize: '9px'
      }
    },
    title: {
      text: null,
      style: 'display: none;'
    },
    tickPositioner(min, max) {
      const positions = [];

      let i = min;
      while (i <= max) {
        positions.push(i);
        i++;
      }

      return positions;
    },
    gridLineWidth: 1,
    plotLines: [
      {
        value: 0,
        width: 1,
        color: 'black',
        zIndex: 1
      }
    ]
  }
};

const CENTRAL_CHART_OPTIONS = {
  yAxis: {
    min: -5,
    max: 5
  },
  xAxis: {
    min: -5,
    max: 5
  },
  series: [ZERO_AXIS_POINT]
};

const FULL_CHART_OPTIONS = {
  yAxis: {
    min: -10,
    max: 10
  },
  xAxis: {
    min: -10,
    max: 10
  },
  series: [ZERO_AXIS_POINT, CENTRAL_BOUNDARY_POLYGON]
};

const PDF_OPTIONS = {
  plotOptions: {
    series: {
      enableMouseTracking: false,
      shadow: false,
      animation: false,
      states: {
        hover: false
      }
    }
  }
};

const COMPARE_OPTIONS = {
  yAxis: {
    labels: {
      style: {
        fontSize: '8px'
      }
    }
  },
  xAxis: {
    tickPosition: 'inside',
    tickWidth: 0,
    lineWidth: 0,
    labels: {
      style: {
        fontSize: '8px'
      }
    }
  },
  legend: {
    useHTML: true,
    enabled: true,
    symbolHeight: .001,
    symbolWidth: .001,
    symbolRadius: .001,
    align: 'center',
    verticalAlign: 'bottom',
    itemWidth: 20,
    margin: 0,
    labelFormatter: function () {
      return `<div
                class="highcharts-legend-item-cicle"
                style="background-color: ${ALTERNATIVE_COLORS[this.userOptions.zone]};">
              </div>`;
    }
  },
};

const CYCLE_PDF_OPTIONS = {
  legend: {
    enabled: false
  },
  yAxis: {
    labels: {
      enabled: false
    }
  },
  xAxis: {
    tickPosition: 'inside',
    tickWidth: 0,
    lineWidth: 0,
    labels: {
      enabled: false
    }
  }
};

const COMPARE_PDF_OPTIONS = {
  chart: {
    margin: 1
  },
  legend: {
    enabled: false
  },
  yAxis: {
    labels: {
      enabled: false
    }
  },
  xAxis: {
    tickPosition: 'inside',
    tickWidth: 0,
    lineWidth: 0,
    labels: {
      enabled: false
    }
  }
};

const LEGEND_HEIGHT = 26;
const EMPTY_LEGEND_CORRECTION = 16;

export {
  ZONES,
  PDF_ZONES,
  DEFAULT_OPTIONS,
  CENTRAL_CHART_OPTIONS,
  FULL_CHART_OPTIONS,
  PDF_OPTIONS,
  COMPARE_OPTIONS,
  CYCLE_PDF_OPTIONS,
  COMPARE_PDF_OPTIONS,
  LEGEND_HEIGHT,
  EMPTY_LEGEND_CORRECTION
}
