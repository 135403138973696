import NudgeIntervalView from 'src/plugins/nudge_settings/nudgeIntervalView';

class ActiveCustomNudge {
  constructor(options) {
    this.elements = options.elements;
    this.defaultValues = options.defaultValues;

    this.init();
  }

  init() {
    this.timeElement = this.elements.timeElement;
    this.intervalElement = this.elements.intervalElement;

    this.intervalView = new NudgeIntervalView(this.timeElement, this.intervalElement);

    this.setListeners();
    this.switchSettings();
  }

  setListeners() {
    if (this.nudgeSwitcher){
      this.switcherInput.addEventListener('change', () => {
        this.setDefaultValues();
        this.switchSettings();
      });
    }

    this.timeElement.addEventListener('change', () => this.intervalView.rerender());
  }

  enableNudgeSettings() {
    this.switcherInput.removeAttribute('disabled');
    this.switcherLabel.style.cursor = 'pointer';
  }

  disableNudgeSettings() {
    this.switcherInput.checked = false;
    this.switcherInput.disabled = true;
    this.switcherLabel.style.cursor = 'not-allowed';
    this.switchSettings();
  }

  switchSettings() {
    if (this.nudgeSwitcher) {
      this.nudgeSettingsElement.hidden = !this.switcherInput.checked;
    }

    this.intervalView.disable();
    this.intervalView.rerender();
  }

  setDefaultValues() {
    this.timeElement.value = this.defaultValues.time || '';
    this.intervalElement.value = this.defaultValues.interval || '';
  }

  get nudgeSettingsElement() {
    return this.timeElement.closest('.form-row');
  }

  get nudgeSwitcher() {
    return this.elements.nudgeSwitcher;
  }

  get switcherInput() {
    return this.nudgeSwitcher.querySelector('#active_custom_nudge');
  }

  get switcherLabel() {
    return this.nudgeSwitcher.querySelector('[for="active_custom_nudge"]');
  }
}

export default ActiveCustomNudge
