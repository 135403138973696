const LOGMAR_SNELLEN_PRETTY = {
  '-1.2': { logmar: '-0.12', snellen: '20/15' },
  '0': { logmar: '0.00', snellen: '20/20' },
  '1': { logmar: '+0.10', snellen: '20/25' },
  '2': { logmar: '+0.20', snellen: '20/32' },
  '3': { logmar: '+0.30', snellen: '20/40' },
  '4': { logmar: '+0.40', snellen: '20/50' },
  '5': { logmar: '+0.50', snellen: '20/63' },
  '6': { logmar: '+0.60', snellen: '20/80' },
  '7': { logmar: '+0.70', snellen: '20/100' },
  '8': { logmar: '+0.80', snellen: '20/125' },
  '9': { logmar: '+0.90', snellen: '20/160' },
  '10': { logmar: '+1.00', snellen: '20/200' },
  '11': { logmar: '+1.10', snellen: '20/250' },
  '12': { logmar: '+1.20', snellen: '20/320' },
  '13': { logmar: '+1.30', snellen: '20/400' }
};

const VISION = {
  chart: {
    type: 'column',
    //marginLeft: 55,
    marginTop: 20,
    height: 380
  },
  yAxis: [
    {
      min: -1.2,
      max: 14,
      tickPositions: [-1.2, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      reversed: true,
      title: {
        text: null,
        style: 'display: none;'
      },
      labels: {
        formatter: function() {
          if (this.value >= 14) return null;

          return LOGMAR_SNELLEN_PRETTY[this.value][this.axis.series[0].userOptions.unit];
        }
      }
    }
  ],
  plotOptions: {
    column: {
      borderWidth: 0
    },
    series: {
      threshold: -1.2,
      cursor: 'pointer',
      minPointLength: 3,
      events: {
        legendItemClick: function() {
          return false;
        }
      },
      point: {
        events: {}
      },
      states: {
        hover: {
          enabled: false
        }
      }
    }
  }
};

export { VISION, LOGMAR_SNELLEN_PRETTY };
