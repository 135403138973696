import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import Answer from './Answer'

const Answers = ({ isDragDisabled, snippetId, questionId, answers }) => {
  return (
    <Droppable
      droppableId={`answer-${snippetId}-${questionId}`}
      type={`answer-${snippetId}-${questionId}`}
    >
      {
        (dropProvided, dropSnapshot) => (
          <div
            className="answers"
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps}
          >
            {
              answers.map((answerId, index) => (
                <Draggable
                  draggableId={`answer-${snippetId}-${questionId}-${answerId}`}
                  index={index}
                  key={answerId}
                >
                  {
                    (dragProvided, dragSnapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                      >
                        <Answer
                          answerId={answerId}
                          index={index}
                          dragHandleProps={dragProvided.dragHandleProps}
                          isDragDisabled={isDragDisabled}
                        />
                      </div>
                    )
                  }
                </Draggable>
              ))
            }
            {dropProvided.placeholder}
          </div>
        )
      }
    </Droppable>
  );
}

export default Answers
