import { createAction } from 'redux-actions'
import axios from 'src/react/utils/Timesheets/axios'
import { toast } from 'react-toastify'

import { formatPath } from 'src/utils/formatters'
import { normalizeTree } from './schema'
import { timeRecordForm, formErrors, verificationLock, recordNotFound } from './forms'

const loadPatients = (query) => {
  const { autocomplete_path } = window.timesheets;

  try {
    return axios.get(autocomplete_path, { params: { query, type: 'patient' }}).
      then(results => results.data.suggestions);
  } catch(err) {
    console.log(err);
  }
};

const changeActionableAction = createAction('TIMESHEETS/CHANGE_ACTIONABLE');

const changeActionable = (user) => (
  async dispatch => {
    toast.info(`You are acting as ${user.name} now`);

    dispatch(changeActionableAction(user));
  }
);

const changeVerificationLockAction = createAction('TIMESHEETS/CHANGE_VERIFICATION_LOCK');

const loadActionables = (query) => {
  const { load_actionables_path } = window.timesheets;

  try {
    return axios.get(load_actionables_path).
      then(results => results.data);
  } catch(err) {
    console.log(err);
  }
};

const showFormModalAction = createAction('TIMESHEETS/SHOW_FORM_MODAL');
const showDeleteModalAction = createAction('TIMESHEETS/SHOW_DELETE_MODAL');
const hideModalAction = createAction('TIMESHEETS/HIDE_MODAL');
const clearModalAction = createAction('TIMESHEETS/CLEAR_MODAL');

const editCellAction = createAction('TIMESHEETS/EDIT_CELL');
const dropEditCellAction = createAction('TIMESHEETS/DROP_EDIT_CELL');
const changeCellAction = createAction('TIMESHEETS/CHANGE_CELL');
const setInvalidCellAction = createAction('TIMESHEETS/SET_INVALID_CELL');
const setValidCellAction = createAction('TIMESHEETS/SET_VALID_CELL');

const startTimesheetTreeRequest = createAction('TIMESHEETS/START_REQUEST_TREE');
const getTimesheetTreeRequest = createAction('TIMESHEETS/REQUEST_TREE');
const changeTimesheetRangeAction = createAction('TIMESHEETS/CHANGE_RANGE');

const getTimesheetTree = (from, to, { actionableId: actionable_id }) => (
  async dispatch => {
    const { load_tree_path } = window.timesheets;

    dispatch(startTimesheetTreeRequest());

    try {
      const { data } = await axios.get(load_tree_path, { params: { from, to, actionable_id }});

      dispatch(getTimesheetTreeRequest(normalizeTree(data)));
    } catch(err) {
      console.log(err);
    }
  }
);

const activateDayAction = createAction('TIMESHEETS/ACTIVATE_DAY');
const switchActivityAction = createAction('TIMESHEETS/SWITCH_ACTIVITY');
const switchActivitiesAction = createAction('TIMESHEETS/SWITCH_ACTIVITIES');
const deleteActivityAction = createAction('TIMESHEETS/DELETE_ACTIVITY');

const createRootTimeRecordAction = createAction('TIMESHEETS/CREATE_ROOT_TIME_RECORD');

const createRootTimeRecord = (values, { actionableId: actionable_id }) => (
  async dispatch => {
    const { create_time_record_path } = window.timesheets;

    try {
      const { data: { time_record: timeRecord } } = await axios({
        method: 'POST',
        url: create_time_record_path,
        params: {
          actionable_id
        },
        data: timeRecordForm(values)
      });

      toast.success(`Time record was successfully created`);

      dispatch(createRootTimeRecordAction({ timeRecord }));
    } catch(err) {
      return formErrors(err);
    }
  }
);

const prepareTreeTimeRecordAction = createAction('TIMESHEETS/PREPARE_TREE_TIME_RECORD');
const freezeTreeTimeRecordAction = createAction('TIMESHEETS/FREEZE_TREE_TIME_RECORD');
const createTreeTimeRecordAction = createAction('TIMESHEETS/CREATE_TREE_TIME_RECORD');
const updateTreeTimeRecordAction = createAction('TIMESHEETS/UPDATE_TREE_TIME_RECORD');
const deleteTreeTimeRecordAction = createAction('TIMESHEETS/DELETE_TREE_TIME_RECORD');

const createTreeTimeRecord = ({ activityId, timeRecord }, { actionableId: actionable_id }) => (
  async dispatch => {
    const { create_time_record_path } = window.timesheets;

    const {
      day,
      patient_id,
      id: draftTimeRecordId,
      form: {
        changedCells,
        invalidCells
      }
    } = timeRecord;

    if (invalidCells.length) return;

    dispatch(freezeTreeTimeRecordAction(draftTimeRecordId));

    const changedAttributes = changedCells.reduce((memo, context) => {
      memo[context] = timeRecord[context];

      return memo;
    }, {});

    try {
      const { data: { time_record: { id: timeRecordId }} } = await axios({
        method: 'POST',
        url: create_time_record_path,
        params: {
          actionable_id
        },
        data: {
          time_record: {
            day,
            patient_id,
            ...changedAttributes
          }
        }
      });

      toast.success(`Time record was successfully created`);

      dispatch(createTreeTimeRecordAction({ activityId, draftTimeRecordId, timeRecordId }));
    } catch(err) {
      const [ isVerificationLock, verificationLockFrame ] = verificationLock(err);

      if (isVerificationLock) {
        toast.error(`Verification period was already closed for change`);

        dispatch(changeVerificationLockAction(verificationLockFrame));
      }
    }
  }
);

const updateTreeTimeRecord = ({ activityId, timeRecord }, { actionableId: actionable_id }) => (
  async dispatch => {
    const { update_time_record_path } = window.timesheets;

    const { id: timeRecordId, form: { changedCells } } = timeRecord;

    dispatch(freezeTreeTimeRecordAction(timeRecordId));

    const changedAttributes = changedCells.reduce((memo, context) => {
      memo[context] = timeRecord[context];

      return memo;
    }, {});

    try {
      const { data } = await axios({
        method: 'PUT',
        url: formatPath(update_time_record_path, { id: timeRecordId }),
        params: {
          actionable_id
        },
        data: {
          time_record: changedAttributes
        }
      });

      toast.success(`Time record was successfully changed`);

      dispatch(updateTreeTimeRecordAction({ timeRecordId }));
    } catch(err) {
      const isNotFound = recordNotFound(err);
      const [ isVerificationLock, verificationLockFrame ] = verificationLock(err);

      if (isNotFound) {
        toast.error(`Time record was already deleted`);

        dispatch(deleteTreeTimeRecordAction({ activityId, timeRecordId }));
      }

      if (isVerificationLock) {
        toast.error(`Verification period was already closed for change`);

        dispatch(changeVerificationLockAction(verificationLockFrame));
      }
    }
  }
);

const deleteActivity = ({ entity: activity, options }, { actionableId: actionable_id }) => (
  async dispatch => {
    const { day, patient_id } = activity;
    const { delete_activity_path } = window.timesheets;

    try {
      const { data } = await axios({
        method: 'DELETE',
        url: delete_activity_path,
        params: {
          actionable_id
        },
        data: {
          day,
          patient_id
        }
      });

      toast.success(`Time records were successfully deleted`);

      dispatch(deleteActivityAction(activity));
    } catch(err) {
      const [ isVerificationLock, verificationLockFrame ] = verificationLock(err);

      if (isVerificationLock) {
        toast.error(`Verification period was already closed for change`);

        dispatch(changeVerificationLockAction(verificationLockFrame));
      }
    }
  }
);

const deleteTimeRecord = ({ entity: { id: timeRecordId, draft = false }, options: { activityId } }, { actionableId: actionable_id }) => (
  async dispatch => {
    const { delete_time_record_path } = window.timesheets;

    if (draft) {
      dispatch(deleteTreeTimeRecordAction({ activityId, timeRecordId }));
      return;
    }

    try {
      const { data } = await axios({
        method: 'DELETE',
        url: formatPath(delete_time_record_path, { id: timeRecordId }),
        params: {
          actionable_id
        }
      });

      toast.success(`Time record was successfully deleted`);

      dispatch(deleteTreeTimeRecordAction({ activityId, timeRecordId }));
    } catch(err) {
      const isNotFound = recordNotFound(err);
      const [ isVerificationLock, verificationLockFrame ] = verificationLock(err);

      if (isNotFound) {
        toast.error(`Time record was already deleted`);

        dispatch(deleteTreeTimeRecordAction({ activityId, timeRecordId }));
      }

      if (isVerificationLock) {
        toast.error(`Verification period was already closed for change`);

        dispatch(changeVerificationLockAction(verificationLockFrame));
      }
    }
  }
);

export {
  loadPatients,
  loadActionables,
  showFormModalAction,
  showDeleteModalAction,
  hideModalAction,
  clearModalAction,
  changeActionable,
  changeActionableAction,
  changeVerificationLockAction,

  editCellAction,
  dropEditCellAction,
  changeCellAction,
  setInvalidCellAction,
  setValidCellAction,

  createRootTimeRecordAction,
  prepareTreeTimeRecordAction,
  createTreeTimeRecordAction,
  updateTreeTimeRecordAction,
  freezeTreeTimeRecordAction,
  deleteTreeTimeRecordAction,

  getTimesheetTreeRequest,
  changeTimesheetRangeAction,
  startTimesheetTreeRequest,
  getTimesheetTree,
  activateDayAction,
  switchActivityAction,
  switchActivitiesAction,
  deleteActivityAction,
  deleteActivity,
  deleteTimeRecord,
  createRootTimeRecord,
  createTreeTimeRecord,
  updateTreeTimeRecord
};
