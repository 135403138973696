import React from 'react'

import TextAreaCell from './Cells/TextAreaCell'
import TextCell from './Cells/TextCell'
import SelectCell from './Cells/SelectCell'

const CellsFactory = ({ context, ... props }) => {
  switch (context) {
    case 'category':
      const categoriesForSelect = Object.entries(window.timesheets.categories).map(([value, label]) => ({ value, label }));

      return (
        <SelectCell
          {...props}
          options={categoriesForSelect}
        />
      );
    case 'details':
      return (
        <TextAreaCell
          {...props}
          placeholder="Add details ..."
        />
      );
    case 'amount':
      return <TextCell {...props} />;
    default:
      return null;
  }
}

export default CellsFactory
