import moment from 'moment'
import groupBy from 'src/utils/arrays/groupBy'
import arrayToObject from 'src/utils/arrays/arrayToObject'

import daysRange from 'src/react/utils/Timesheets/daysRange'

const generateActivityId = (day, patientId) => [day, patientId].join('-');

const generateDaysActivities = (from, to, timeRecords) => {
  let days = {},
      activities = {};

  const timeRecordsByDays = groupBy(timeRecords, 'day');

  daysRange(from, to).forEach((date) => {
    const day = date.format('YYYY-MM-DD');
    const timeRecordsByPatients = groupBy(timeRecordsByDays[day] || [], 'patient_id');

    if (!days[day]) days[day] = { day };

    // prepare temporary daily activities.
    // WARNING: iterate over object keys them to strings, so we need to cast keys back to integers.
    let dayActivities = Object.entries(timeRecordsByPatients).reduce((memo, [patientId, patientTimeRecords]) => {
      const activityId = generateActivityId(day, patientId);

      memo.push({
        id: activityId,
        day: day,
        patient_id: Number(patientId),
        _created_at: patientTimeRecords[0].created_at
      });

      return memo;
    }, []);

    // reorder activities per day based on timestamp of first time record in day
    dayActivities = dayActivities.sort((a, b) => moment(b._created_at) - moment(a._created_at));

    // prepare regular activities objects
    dayActivities.forEach((activity, index) => {
      const { id, day, patient_id } = activity;

      activities[id] = {
        id,
        day,
        patient_id,
        active: index < 5,
        time_records: timeRecordsByPatients[patient_id].map(o => o.id),
      }
    });

    // setup activities ids per day
    days[day].activities = dayActivities.map(o => o.id);
  });

  return {
    days,
    activities
  }
};

const normalizeTree = ({
  from,
  to,
  time_records,
  patients
}) => {
  const { days, activities } = generateDaysActivities(from, to, time_records);

  return {
    from,
    to,
    days,
    activities,
    patients: arrayToObject(patients, 'id'),
    time_records: arrayToObject(time_records, 'id')
  };
};

export {
  normalizeTree
}
