import swal from 'sweetalert'

class TransmitButton {
  constructor(selector, storage) {
    this.selector = selector;
    this.storage = storage;

    this.init();
  }

  init() {
    this.buildTransmitButton();
    this.attachListeners();
  }

  update() {}

  confirmation() {
    return swal({
      title: 'Send document to Athena',
      text: `Are you sure you want to send summary report pdf of ${this.patientName} to Athena? This action can not be undone.`,
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true
        },
        confirm: {
          text: 'Send'
        }
      }
    });
  };

  confirmationRequest() {
    return $.ajax({
      url: window.metrics.transmit_path,
      data: this.transmitOptions,
      type: 'GET',
      dataType: 'json'
    });
  }

  handleConfirm() {
    this.confirmation().then((isConfirmed) => {
      if (isConfirmed) {
        const request = this.confirmationRequest();

        request.done((response) => {
          this.flashAlerts.innerHTML = response.flash;
        });

        request.fail((jqXHR) => {
          if (jqXHR.status == 401) window.location = '/';

          this.flashAlerts.innerHTML = jqXHR.responseJSON.flash;
        });

        request.always((response) => window.scrollTo(0, 0));
      }
    });
  }

  get transmitOptions() {
    const { start_date, end_date, step, ... rest } = this.storage.params;

    let options = {
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD')
    }

    return { ... options, ... rest };
  }

  buildTransmitButton() {
    this.selector.innerHTML = `<a class="btn btn-block btn-secondary ml-2" target="_blank" data-transmit-button>
      Send To Athena
    </a>`;
  }

  attachListeners() {
    this.transmitButton.addEventListener('click', (e) => this.handleConfirm());
  }

  get transmitButton() {
    return this.selector.querySelector('[data-transmit-button]');
  }

  get flashAlerts() {
    return document.querySelector('.flash-alerts');
  }

  get patientName() {
    return window.metrics.patient_name;
  }
}

export default TransmitButton
