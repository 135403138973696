import { Controller } from 'stimulus'
import $ from 'jquery'
import moment from 'moment'

export default class extends Controller {
  connect() {
    this.periodField = this.element;

    this.toggleSubmitBtn();
    this.initPeriodSelectors(this.periodField.value);
    this.setListeners();
  }

  setListeners() {
    $(this.fromField).on('change', () => this.toggleSubmitBtn());
    $(this.toField).on('change', () => this.toggleSubmitBtn());
    this.periodField.addEventListener('change', (e) => this.initPeriodSelectors(e.target.value));
  }

  initPeriodSelectors(rangeType) {
    const periods = JSON.parse(this.data.get('periods'));
    const period = periods[rangeType];

    if (!this.isCustomRange(rangeType)) {
      this.fromField.parentElement.classList.add('hidden');
      this.toField.parentElement.classList.add('hidden');
    } else {
      this.fromField.parentElement.classList.remove('hidden');
      this.toField.parentElement.classList.remove('hidden');
    }

    this.setInterval(period[0], period[1]);
  }

  toggleSubmitBtn() {
    if (this.isSubmitAllowed) {
      this.submitBtn.removeAttribute('disabled');
      this.errorEl.classList.add('hidden');
    } else {
      this.submitBtn.setAttribute('disabled', true);
      this.errorEl.classList.remove('hidden');
    }
  }

  get isSubmitAllowed() {
    return moment(this.toField.value).isAfter(this.fromField.value) || moment(this.toField.value).isSame(this.fromField.value)
  }

  setInterval(from, to) {
    this.selectFromDate(from);
    this.selectToDate(to);
  }

  isCustomRange(type) {
    return type === 'custom';
  }

  get fromField() {
    return document.getElementById('q_from');
  }

  get toField() {
    return document.getElementById('q_to');
  }

  selectFromDate(date) {
    const fromPickerController = this.pickerController(document.getElementById('picker_from'));

    if (fromPickerController) {
      fromPickerController.selectDate(date);
    }
  }

  selectToDate(date) {
    const toPickerController = this.pickerController(document.getElementById('picker_to'));

    if (toPickerController) {
      toPickerController.selectDate(date);
    }
  }

  pickerController(domElement) {
    return this.application.getControllerForElementAndIdentifier(domElement, 'helpers--picker');
  }

  get periods() {
    return document.getElementById('q_range').data.get('periods');
  }

  get errorEl() {
    return document.querySelector('.errors');
  }

  get submitBtn() {
    return document.getElementById(this.submitBtnSelector);
  }

  get submitBtnSelector() {
    const { submitId } = this.element.dataset;
    return submitId;
  }
}
