import { Controller } from 'stimulus'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import configureStore from 'src/react/store/configureStore'
import timesheets from 'src/react/store/Timesheets'

import Page from 'src/react/containers/Timesheets/Page'

export default class extends Controller {
  connect() {
    ReactDOM.render(
      <Provider store={this.store}>
        <Page />
      </Provider>,
      this.element
    )
  }

  get store() {
    return configureStore({ timesheets }, this.initialState);
  }

  get initialState() {
    const { from, to, currentDay, currentUser, verificationLock } = window.timesheets;

    return {
      timesheets: {
        from,
        to,
        currentDay,
        currentUser,
        verificationLock,
        actionableUser: currentUser,
        isLoading: true,
        formAction: null,
        formEntry: null,
        formEntryId: null,
        formModalShow: false,
        formOptions: {},
        timesheets: {},
        entities: {},
        cells: {},
        currentWeek: {
          from,
          to,
          currentDay
        }
      }
    };
  }
}
