import Scores from 'src/components/summary/widgets/cardiology/scores'
import BloodPressure from 'src/components/summary/widgets/cardiology/blood_pressure'
import BloodOxygen from 'src/components/summary/widgets/cardiology/blood_oxygen'
import Temperature from 'src/components/summary/widgets/cardiology/temperature'
import Weight from 'src/components/summary/widgets/cardiology/weight'
import Statistic from 'src/components/summary/widgets/cardiology/statistic'

class Factory {
  constructor(selector, storage) {
    if (this.factory = Factory.registry[selector.dataset.key])
      return new this.factory(selector, storage);

    console.log('Factory is not registered')
  }

  static get registry() {
    return {
      'cardiology:scores': Scores,
      'cardiology:blood_pressure': BloodPressure,
      'cardiology:blood_oxygen': BloodOxygen,
      'cardiology:temperature': Temperature,
      'cardiology:weight': Weight,
      'cardiology:statistic': Statistic
    }
  }
}

export default Factory
