import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'src/react/store/Timesheets/selectors'

const TotalAmount = (props) => {
  const totalAmount = useSelector(state => selectors.timeRecordsAmount(state));

  return (
    <div className="ml-1">
      (total: {totalAmount}m)
    </div>
  );
}

export default TotalAmount
