import React from 'react'

const CopyMessage = ({ entry, entity }) => {
  if (entry == 'training') {
    return (
      <>
        You are going to copy training <strong>{entity.name}</strong> with all its content.
      </>
    );
  }

  if (entry == 'category') {
    return (
      <>
        You are going to copy category <strong>{entity.name}</strong> with all its content into current training.
      </>
    );
  }

  if (entry == 'snippet') {
    return (
      <>
        You are going to copy snippet <strong>{entity.name}</strong> with all its content into current training.
      </>
    );
  }

  return null;
};

export default CopyMessage
