const copyToClipboard = (str, callback, options = {}) => {
    let container;

    const el = document.createElement('textarea');
    el.value = str;

    if (options.container) {
      container = document.querySelector(options.container);
    } else {
      container = document.body;
    }

    container.appendChild(el);
    el.select();
    el.focus();
    document.execCommand('copy');
    container.removeChild(el);

    callback();
};

export default copyToClipboard
