import Simple from 'src/components/summary/widgets/simple'

class Scores extends Simple {
  setResults(response) {
    this.selector.innerHTML = response.content;

    this.attachListeners();
  }

  attachListeners() {
    $(this.selector).find('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
  }

  get options() {
    return {
      category: 'ophthalmology',
      widget: 'scores'
    };
  }
}

export default Scores
