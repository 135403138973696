import Simple from 'src/components/summary/widgets/simple'

class Scores extends Simple {
  setResults(response) {
    this.selector.innerHTML = response.content;
  }

  get options() {
    return {
      category: 'cardiology',
      widget: 'scores'
    };
  }
}

export default Scores
