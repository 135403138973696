import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'src/react/store/Compares/selectors'

const Vision = ({ tests }) => {
  const { 'ophthalmology:vision:l': left, 'ophthalmology:vision:r': right } = tests;

  const showAll = useSelector(state => selectors.showAll(state));
  const leftVisible = useSelector(state => selectors.leftVisible(state));
  const rightVisible = useSelector(state => selectors.rightVisible(state));

  if (!showAll) return null;

  return (
    <div className="result-item">
      <div className="title">
        <span>Vision</span>
      </div>
      <div className="content">
        <div className="row">
          {
            leftVisible && (
              <div className="col">
                <div className="d-flex justify-content-between">
                  <span>Snellen:</span>
                  <span>
                    {left ? left.snellen_pretty : 'N/A'}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>LogMAR:</span>
                  <span>
                    {left ? left.logmar_pretty : 'N/A'}
                  </span>
                </div>
              </div>
            )
          }
          {
            rightVisible && (
              <div className="col">
                <div className="d-flex justify-content-between">
                  <span>Snellen:</span>
                  <span>
                    {right ? right.snellen_pretty : 'N/A'}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>LogMAR:</span>
                  <span>
                    {right ? right.logmar_pretty : 'N/A'}
                  </span>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Vision;
