import React from 'react'

const Ability = ({ children, context }) => {
  const transmitAvailableContext = () => {
    return window.compares.transmit_available ? children : null;
  }

  switch (context) {
    case 'transmit_available':
      return transmitAvailableContext();
    default:
      return null;
  }
}

export default Ability
