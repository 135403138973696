import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    if (data.select_modal) {
      $('body').
      findOrAppend('#select-modal', '<div id="select-modal" />').
      html(data.select_modal).
      find('.modal').
      modal('show');
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;

    if (data.archive_modal) {
      $('#select-modal .modal-content').
      replaceWith(data.select_modal);
    }
  }
}
