import { Controller } from 'stimulus'
import $ from 'jquery'
import 'air-datepicker'
import 'air-datepicker/dist/js/i18n/datepicker.en'

export default class extends Controller {
  connect() {
    const self = $(this.element);
    const options = self.data();

    if (options.startDate) {
      options.startDate = new Date(options.startDate);
    }

    if (options.selectedDate) {
      options.selectedDate = new Date(options.selectedDate);
    }

    if (options.maxDate) {
      options.maxDate = new Date(options.maxDate);
    }

    if (options.minDate) {
      options.minDate = new Date(options.minDate);
    }

    if (options.isoTarget) {
      this.setOnSelectCallback(options)
    }

    this.datepicker = self.datepicker(options).data('datepicker');
    this.datepicker.selectDate(options.selectedDate);
  }

  setOnSelectCallback(options) {
    const isoTarget = $(options.isoTarget);

    options.onSelect = (dateText, date) => {
      if (!!date) {
        isoTarget.val(this.toISOLocal(date));
      } else {
        isoTarget.val('');
      }

      isoTarget.trigger('change');
    }
  }

  selectDate(date) {
    this.datepicker.selectDate(new Date(date));
  }

  toISOLocal(d) {
    const z = n => ('0' + n).slice(-2);
    const zz = n => ('00' + n).slice(-3);
    const sign = d.getTimezoneOffset() < 0 ? '+' : '-';
    const off = Math.abs(d.getTimezoneOffset());

    return d.getFullYear() + '-'
      + z(d.getMonth()+1) + '-' +
      z(d.getDate()) + 'T' +
      z(d.getHours()) + ':'  +
      z(d.getMinutes()) + ':' +
      z(d.getSeconds()) + '.' +
      zz(d.getMilliseconds()) +
      sign + z(off / 60 | 0) + ':' + z(off % 60);
  }
}
