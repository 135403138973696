class Navigate {
  constructor(selector, storage) {
    this.selector = selector;
    this.storage = storage;

    this.init();
  }

  init() {
    this.buildNavigate();
    this.buildCallbacks();
    this.buildNavigationTooltips();
    this.attachListeners();
  }

  update() {
    this.buildNavigationTooltips();
  }

  buildNavigate() {
    this.selector.innerHTML = `
      <div class="form-inline top-navigation-report">
        <button class="btn btn-outline-primary" type="button" data-toggle="tooltip" data-placement="top" title="Jump to first HR" data-move-first-entry>
          <span class="mdi mdi-chevron-double-left"></span>
        </button>
        <button class="btn btn-outline-primary" type="button" data-toggle="tooltip" data-placement="top" data-move-backward>
          <span class="mdi mdi-chevron-left"></span>
        </button>
        <button class="btn btn-outline-primary" type="button" data-toggle="tooltip" data-placement="top" data-move-forward>
          <span class="mdi mdi-chevron-right"></span>
        </button>
        <button class="btn btn-outline-primary" type="button" data-toggle="tooltip" data-placement="top" title="Jump to last HR" data-move-last-entry>
          <span class="mdi mdi-chevron-double-right"></span>
        </button>
      </div>`;
  }

  buildCallbacks() {
    this.moveForward.addEventListener('click', (e) => this.storage.moveForward());
    this.moveBackward.addEventListener('click', (e) => this.storage.moveBackward());
    this.moveFirstEntry.addEventListener('click', (e) => this.storage.moveFirstEntry());
    this.moveLastEntry.addEventListener('click', (e) => this.storage.moveLastEntry());
  }

  buildNavigationTooltips() {
    const [ forwardTitle, backwardTitle ] = this.tooltipsTitles();

    this.moveForward.dataset.originalTitle = forwardTitle;
    this.moveBackward.dataset.originalTitle = backwardTitle;
  }

  tooltipsTitles() {
    const stepTitle = {
      1: '1 day',
      3: '3 days',
      7: '7 days',
      14: '14 days',
      30: '1 month'
    }[this.storage.params.step];

    return [
      `Next period (+${stepTitle})`,
      `Previous period (-${stepTitle})`,
    ];
  }

  attachListeners() {
    $(this.selector).
      find('[data-toggle="tooltip"]').
      tooltip({ trigger: 'hover' });
  }

  get moveForward() {
    return this.selector.querySelector('[data-move-forward]');
  }

  get moveBackward() {
    return this.selector.querySelector('[data-move-backward]');
  }

  get moveFirstEntry() {
    return this.selector.querySelector('[data-move-first-entry]');
  }

  get moveLastEntry() {
    return this.selector.querySelector('[data-move-last-entry]');
  }
}

export default Navigate
