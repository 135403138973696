import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    this.setListeners();
    this.selectDefaultSnippet();
  }

  setListeners() {
    $('.snippet-item').on('click', (e) => this.selectSnippet(e.target));
    $(this.snippetMenuElements).on('click', (e) => this.toggleSnippetMenu(e.target));
  }

  selectSnippet(element) {
    const snippetId = element.getAttribute('value');

    this.hideSnippetElements();
    this.displaySnippetContent(this.snippetContent(snippetId));
  }

  hideSnippetElements() {
    this.snippetContentElements.forEach(element => element.classList.add('d-none'));
  }

  selectDefaultSnippet() {
    const snippetMenuItem = this.snippetMenuElements[0];
    const snippetMenuItemId = snippetMenuItem.getAttribute('value');
    const snippetContent = this.snippetContent(snippetMenuItemId);

    this.toggleCategory(snippetMenuItem);
    this.toggleSnippetMenu(snippetMenuItem);
    this.displaySnippetContent(snippetContent);
  }

  toggleCategory(element) {
    $(element).closest('.collapse').collapse();
  }

  toggleSnippetMenu(element) {
    $('.list-group .list-group-item').removeClass('active');
    element.classList.add('active');
  }

  displaySnippetContent(element) {
    element.classList.remove('d-none');
  }

  snippetContent(snippetId) {
    return this.element.querySelector('#snippet_' + snippetId);
  }

  get snippetMenuElements() {
    return this.element.querySelectorAll('.snippet-item');
  }

  get snippetContentElements() {
    return this.element.querySelectorAll('.wizard');
  }
}
