import axios from 'src/utils/axios'

axios.interceptors.response.use(response => {
   return response;
}, error => {
  if (error.response.status === 401) {
    window.location = '/?unauthorized';
  }

  return Promise.reject(error);
});

export default axios
