import { Controller } from 'stimulus'
import $ from 'jquery'

const freezeFormClassName = 'freeze-overlay-blur';

export default class extends Controller {
  static targets = ['loading', 'throttled', 'form'];

  onBefore() {
    $(this.loadingTarget).show();

    $(this.formTarget).
      addClass(freezeFormClassName);
  }

  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    $(this.formTarget).
      removeClass(freezeFormClassName).
      replaceWith(data.form);

    $(this.loadingTarget).hide();
  }

  onError(event) {
    let [data, status, xhr] = event.detail;

    $(this.loadingTarget).hide();
    $(this.throttledTarget).show();

    setTimeout(() => {
      $(this.formTarget).
        removeClass(freezeFormClassName).
        replaceWith(data.form);

      $(this.throttledTarget).hide();
    }, 4000)
  }
}
