import $ from 'jquery'
import Cookies from 'js-cookie'

const wrapperElement = '.be-wrapper';
const collapseClassName = 'be-collapsible-sidebar-collapsed';

const init = () => {
  toggleSidebar()
}

const toggleSidebar = () => {
  $(document).on('click', '.be-toggle-left-sidebar', () => {
    if ($(wrapperElement).hasClass(collapseClassName)) {
      $(wrapperElement).removeClass(collapseClassName);

      Cookies.remove('sidebar_collapsed');
    } else {
      $(wrapperElement).addClass(collapseClassName);

      Cookies.set('sidebar_collapsed', 'yes', { expires: 365 });
    }
  });
}

export { init }
