import React, { useEffect, useRef, useCallback } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import classnames from 'classnames'

import InvalidFeedback from 'src/react/components/Timesheets/InvalidFeedback'

const TextAreaCell = ({ cell: { value, errors, autoFocus = true }, changeCell, placeholder }) => {
  const inputRef = useRef(null);
  const inputStyles = classnames('form-control', { 'is-invalid': errors });

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
    }
  }, []);

  const handleInputChange = useCallback(
    (event) => {
      changeCell({ value: event.target.value });
    },
    [changeCell]
  );

  return (
    <>
      <TextareaAutosize
        ref={inputRef}
        minRows={1}
        maxRows={6}
        defaultValue={value}
        onChange={handleInputChange}
        className={inputStyles}
        placeholder={placeholder}
      />
      <InvalidFeedback errors={errors} />
    </>
  );
}

export default TextAreaCell
