import React, { useRef, useEffect } from 'react'
import Amsler from 'src/charts/cycle/amsler/compare'
import Preloader from './Preloader'

const Grid = (props) => {
  const { type, payload } = props;

  const node = React.useRef(null);

  useEffect(() => {
    new Amsler(node.current, { type, payload });
  }, []);

  return (
    <div
      className="grid"
      ref={node}
    >
      <Preloader />
    </div>
  );
}

const NoGrid = (props) => {
  return (
    <div className="grid">
      N/A
    </div>
  );
}

export { Grid, NoGrid };
