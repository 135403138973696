import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    $('body').
      findOrAppend('#edit-modal', '<div id="edit-modal" />').
      html(data.content).
      find('.modal').
      modal('show');
  }

  onError(event) {
    let [data, status, xhr] = event.detail;

    if (xhr.status == 401) {
      document.location.reload();
    }
  }
}
