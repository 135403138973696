import Base from 'src/components/summary/grids/base'
import { DAILY_PLOT_BANDS } from 'src/components/summary/constants/defaults'
import regression from 'regression'

export default class extends Base {
  switchTrendline(state) {
    this.storage.params.trendline = state;

    this.redrawTrendline();
  }

  redrawTrendline() {
    if (!this.storage.params.trendline) {
      this.removeTrendline(true);
      return;
    }

    const trendline = this.instance.get('trendline');

    if (!trendline) this.drawTrendline(true);
  }

  removeTrendline(redraw = false) {
    const trendline = this.instance.get('trendline');

    if (trendline) trendline.remove(redraw);
  }

  drawTrendline(redraw = false) {
    if (!this.storage.params.trendline) return;

    const points = this.trendlinePoints();

    if (points.length == 0) {
      //this.noTrendlineDataMessage();
      return;
    }

    const data = {
      id: 'trendline',
      type: 'line',
      color: 'red',
      enableMouseTracking: false,
      showInLegend: false,
      marker: {
        enabled: false
      },
      data: points
    }

    this.instance.addSeries(data, redraw);
  }

  trendlinePoints(e) {
    const row = this.currentDataspace.series[0].data.map(v => v.y);

    if (row.filter(x => (x != null)).length <= 1) {
      return [];
    }

    return regression.linear(
      row.map((object, index) => [index, object])
    ).points;
  }

/*
  noTrendlineDataMessage () {
    this.alert('There should be at least 2 results to show trendline');
  }
*/
  dailyPlotBands(response) {
    return response.bands.map((object, index) => {
      return $.extend({}, DAILY_PLOT_BANDS[object.zone], object, { id: 'band-' + Math.random().toString(36).substr(2, 9) })
    });
  }
}
