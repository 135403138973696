import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import * as selectors from 'src/react/store/Timesheets/selectors'

import Activity from './Activity'
import Empty from 'src/react/components/Timesheets/Empty'

const Timesheet = (props) => {
  const currentDay = useSelector(state => selectors.currentDay(state));
  const { minDate, maxDate } = useSelector(state => selectors.verificationLock(state));
  const { activities } = useSelector(state => selectors.getDayById(state, currentDay));

  const isLockedDay = moment(currentDay) < moment(minDate);

  return (
    <>
      {
        activities.length > 0 ?
          activities.map((activityId) => (
            <Activity
              activityId={activityId}
              key={activityId}
              isLockedDay={isLockedDay}
            />
          )) :
          <Empty />
      }
    </>
  );
}

export default Timesheet
