import Base from 'src/charts/cycle/amsler/base'
import {
  DEFAULT_OPTIONS,
  COMPARE_OPTIONS,
  LEGEND_HEIGHT,
  EMPTY_LEGEND_CORRECTION
} from 'src/charts/cycle/constants/amsler'

class Compare extends Base {
  load(chart) {
    super.load(chart);

    this.applyLegendEvents(chart);
  }

  defaultOptions() {
    return $.extend(true, {}, DEFAULT_OPTIONS, COMPARE_OPTIONS);
  }

  chartSize() {
    return {
      chart: {
        height: 230,
        width: 230
      }
    };
  }

  fixHeight(chart) {
    const newHeight = chart.chartHeight + LEGEND_HEIGHT;

    chart.setSize(undefined, newHeight, false);

    if (!chart.legend.display) {
      chart.update({
        chart: {
          spacingBottom: LEGEND_HEIGHT + EMPTY_LEGEND_CORRECTION
        }}, false);
    }

    chart.redraw();
  }

  applyLegendEvents(chart) {
    const { legend, tooltip, series } = chart;

    legend.allItems.forEach((object) => {
      const { legendItem } = object;

      legendItem.on('mouseover', (e) => {
        const { visible } = series[object.index];

        if (visible) {
          const { data } = series[object.index];

          tooltip.refresh(data[0]);
        } else {
          tooltip.hide(true);
        }
      });

      legendItem.on('mouseout', (e) => tooltip.hide(true));
    });
  }
}

export default Compare
