import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import swal from 'sweetalert'

import * as selectors from 'src/react/store/Compares/selectors'

import { formatPath } from 'src/utils/formatters'
import { deleteFromList, changeBookmark } from 'src/react/store/Compares/actions'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import Vision from 'src/react/components/Compares/Vision'
import Contrast from 'src/react/components/Compares/Contrast'
import Color from 'src/react/components/Compares/Color'
import Amsler from 'src/react/components/Compares/Amsler'

const Card = ({
  cycle: {
    id,
    end_time,
    tests,
    events_count,
    short_key,
    bookmarked,
    bookmarkBusy = false
  }
}) => {
  const dispatch = useDispatch();

  const leftVisible = useSelector(state => selectors.leftVisible(state));
  const rightVisible = useSelector(state => selectors.rightVisible(state));

  const cycleURL = formatPath(window.compares.cycle_path, { id });

  const confirm = () => {
    return swal({
      title: 'Remove bookmark',
      text: `Do you want to remove ${short_key} from your bookmarks?`,
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true
        },
        confirm: {
          text: 'Yes'
        }
      }
    });
  };

  const removeCard = (id) => dispatch(deleteFromList(id));

  const bookmarkCard = (id) => {
    if (bookmarked) {
      confirm().then((isConfirmed) => {
        if (isConfirmed) dispatch(changeBookmark(id));
      });

      return;
    }

    dispatch(changeBookmark(id))
  }

  return (
    <div className="card card-mixins">
      <div className="card-header">
        <div className="controls">
          <div className="overview">
            <a
              className="time"
              href={cycleURL}
              target="_blank"
            >
              {end_time}
            </a>

            {
              (events_count > 0) && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`events-tooltip-${id}`}>
                      HR has {events_count} event(s)
                    </Tooltip>
                  }
                >
                  <div
                    className="icon mdi mdi-alert-box text-warning ml-1"
                    style={{ cursor: 'pointer' }}
                  >
                  </div>
                </OverlayTrigger>
              )
            }
          </div>

          <div className="actions">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`bookmark-tooltip-${id}`}>
                  Bookmark HR
                </Tooltip>
              }
            >
              <a
                className={
                  classnames('btn', 'p-0', { readonly: bookmarkBusy, 'not-bookmarked': !bookmarked })
                }
                onClick={
                  () => bookmarkCard(id)
                }
              >
                <div
                  className={
                    classnames('icon', 'mdi', {
                      'mdi-bookmark': bookmarked,
                      'mdi-icon-bookmark': bookmarked,
                      'mdi-bookmark-outline': !bookmarked
                    })
                  }
                >
                </div>
              </a>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`remove-tooltip-${id}`}>
                  Remove HR from Tests Comparison
                </Tooltip>
              }
            >
              <a className="btn p-0">
                <div
                  className="icon mdi mdi-close"
                  onClick={
                    () => removeCard(id)
                  }
                >
                </div>
              </a>
            </OverlayTrigger>
          </div>
        </div>

        <div className="row eyes">
          {
            leftVisible && (
              <div className="col eye left">
                <span className='icon-eye-left'></span>
                Left Eye (OS)
              </div>
            )
          }
          {
            rightVisible && (
              <div className="col eye right">
                <span className='icon-eye-right'></span>
                Right Eye (OD)
              </div>
            )
          }
        </div>
      </div>

      <div className="results">
        <Vision tests={tests} />
        {window.compares.contrast_active && (
          <Contrast tests={tests} />
        )}
        <Color tests={tests} />
        <Amsler tests={tests} />
      </div>
    </div>
  );
}

export default Card
