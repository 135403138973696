import React from 'react'

const CellTitle = ({ value, context }) => {
  switch (context) {
    case 'category':
      return window.timesheets.categories[value];
    case 'details':
      return value;
    case 'amount':
      return `${value}m`;
    default:
      return null;
  }
}

export default CellTitle
