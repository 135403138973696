import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { copyTraining, copyCategory, copySnippet } from 'src/react/store/TrainingWizard/actions'

import { Button, Modal } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { SubmitError, SubmitButton } from 'src/react/components/FormFields/index'

import CopyMessage from './Partials/CopyMessage'

const currentAction = (entry) => {
  const factory = {
    training: copyTraining,
    category: copyCategory,
    snippet: copySnippet
  };

  return factory[entry];
};

const currentEntity = (entry, entryId = null) => {
  if (entry == 'training') {
    return useSelector(state => selectors.training(state));
  }

  if (entry == 'category') {
    return useSelector(state => selectors.getСategoryById(state, entryId));
  }

  if (entry == 'snippet') {
    return useSelector(state => selectors.getSnippetById(state, entryId));
  }
};

const CopyModal = (props) => {
  const { modal, formEntry: entry, formEntryId: entryId } = props;

  const copyAction = currentAction(entry);
  const entity = currentEntity(entry, entryId);

  const dispatch = useDispatch();
  const onSubmit = async (values) => await dispatch(copyAction(entity));

  return (
    <Form
      onSubmit={onSubmit}
      render={({ submitError, handleSubmit, form, submitting, pristine, values }) => (
        <Modal
          {...modal}
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                Copy { entry }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0">
              {
                submitError && (
                  <SubmitError
                    submitError={ submitError }
                  />
                )
              }
              <div className="bd-callout bd-callout-info mt-0">
                <strong> Note: </strong>
                <span>
                  <CopyMessage
                    entry={entry}
                    entity={entity}
                  />
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton
                type="submit"
                disabled={submitting}
                className="btn-primary"
              >
                Confirm
              </SubmitButton>
              <Button
                onClick={modal.onHide}
                className="btn-secondary"
              >
                Cancel
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    />
  );
}

export default CopyModal
