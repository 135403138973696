import { Controller } from 'stimulus'
import RangeDatesConverter from 'src/utils/rangeDatesConverter'

const datesConverter = new RangeDatesConverter();

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', (e) => this.setRange(e.target));
  }

  setRange(target) {
    const range = this.element.options[this.element.selectedIndex].value;

    this.setFrom(datesConverter.dateFrom(range));
    this.setTo(datesConverter.dateTo(range));
    this.applyFilters();
  }

  setFrom(date_from) {
    this.fromSelector.value = date_from;
  }

  setTo(date_to) {
    this.toSelector.value = date_to;
  }

  applyFilters() {
    const form = this.filterFormSelector;
    $(form).submit();
  }

  get fromSelector() {
    return document.getElementById('q_from');
  }

  get toSelector() {
    return document.getElementById('q_to');
  }

  get filterFormSelector() {
    return document.getElementsByClassName('form-filters');
  }
}
