import { Controller } from 'stimulus'

export const MIN_FILTER_ROWS = 2;

export default class extends Controller {
  connect() {
    if (this.isLongFilter) {
      this.addExpandFilterBtn();
      this.hideFilterRows();
      this.toggleFilter();
      this.setListeners();
    }
  }

  toggleFilter() {
    this.toggleLabel();

    if (this.isAllDisplayed) {
      this.showFilterRows();
    } else {
      this.hideFilterRows();
    }
  }

  hideFilterRows() {
    this.extraFilterRows.forEach(element => element.hidden = true);
  }

  setListeners() {
    this.expandFilterCheckbox.addEventListener('change', () => this.toggleFilter());
  }

  showFilterRows() {
    this.filterRows.forEach(element => element.hidden = false);
  }

  toggleLabel() {
    this.expandFilterLabel.innerText = this.isAllDisplayed ? 'Show less' : 'Show more';
  }

  get extraFilterRows() {
    let totalRows = this.filterRows.length;
    return this.filterRows.slice(MIN_FILTER_ROWS, totalRows - 1);
  }

  get isLongFilter() {
    return this.filterRows.length > MIN_FILTER_ROWS;
  }

  get filterRows() {
    return this.allFilterRows.slice(0, this.allFilterRows.length - 1);
  }

  get filterSubmitRow() {
    return this.allFilterRows.splice(-1, 1)[0];
  }

  get allFilterRows() {
    return [... this.element.querySelectorAll('form > .form-row')];
  }

  get expandElement() {
    return this.element.querySelector('.expand-filter');
  }

  get expandFilterLabel() {
    return this.expandElement.querySelector('[expand-filter-label]');
  }

  get expandFilterCheckbox() {
    return this.expandElement.querySelector('[expand-filter-checkbox]');
  }

  get isAllDisplayed() {
    return this.expandFilterCheckbox.checked;
  }

  get isExpandedFilter() {
    const url = new URL(window.location.href);

    return url.searchParams.has('expand_filter_checkbox');
  }

  addExpandFilterBtn() {
    const rowsElement = this.filterSubmitRow.parentElement;
    const expandElement = this.createExpandElement();

    rowsElement.insertBefore(expandElement, this.filterSubmitRow);
  }

  createExpandElement() {
    const element = document.createElement('div');
    const checkbox = this.createExpandCheckbox();
    const label = this.createExpandLabel();

    element.className = 'form-row expand-filter';
    element.appendChild(checkbox);
    element.appendChild(label);

    return element;
  }

  createExpandCheckbox() {
    const el = document.createElement('input');

    el.setAttribute('expand-filter-checkbox', true);
    el.id = 'expand-filter-checkbox';
    el.name = 'expand_filter_checkbox';
    el.type = 'checkbox';
    el.checked = this.isExpandedFilter;

    return el;
  }

  createExpandLabel() {
    const el = document.createElement('label');

    el.htmlFor = 'expand-filter-checkbox';
    el.setAttribute('expand-filter-label', true);

    return el;
  }
}
