import React from 'react'
import { Field } from 'react-final-form'

import ImageFileField from './ImageFileField'

const ImageAttachmentField = ({ name }) => (
  <Field name={name} subscription={{ value: true, error: true, submitError: true }}>
    {({ input: { value }, meta: { error, submitError } }) => (
        <Field
          name={`${name}-file`}
          component={ImageFileField}
          image={ value }
          error={ error || submitError }
        />
      )
    }
  </Field>
)

export default ImageAttachmentField
