import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    $('body').
      findOrAppend('#comment-modal', '<div id="comment-modal" />').
      html(data.content).
      find('.modal').
      modal('show');
  }
}
