import React, { memo } from 'react'
import $ from 'jquery'

import combineOptions from 'src/react/utils/Compares/combineOptions'

const PdfLink = ({
  blacklist,
  showAll,
  leftVisible,
  rightVisible,
  overflow,
  overflowLimit
}) => {
  let options = combineOptions(blacklist);

  options.show_all = showAll;
  options.left_visible = leftVisible;
  options.right_visible = rightVisible;

  const url = window.compares.compare_pdf_path + '?' + $.param(options);

  const node = React.createRef(null);
  const timerID = React.createRef(null);

  const overflowTooltip = () => {
    if (!overflow) return;

    if (timerID.current) {
      clearTimeout(timerID.current);
    }

    $(node.current).
      tooltip({ title: `PDF report will be limited to ${overflowLimit} records`, trigger: 'manual' }).
      tooltip('show');

    timerID.current = setTimeout((() => $(node.current).tooltip('dispose')), 2000);
  };

  const dismissOverflowTooltip = () => {
    $(node.current).tooltip('dispose');
  };

  return (
    <a
      target="_blank"
      ref={node}
      onMouseEnter={
        () => overflowTooltip()
      }
      onMouseLeave={
        () => dismissOverflowTooltip()
      }
      className="btn btn-white text-nowrap"
      href={url}
    >
      PDF Report
    </a>
  );
}

export default memo(PdfLink)
