import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/Timesheets/selectors'
import { hideModalAction, clearModalAction } from 'src/react/store/Timesheets/actions'

import TimeRecordModal from './Modals/TimeRecordModal'
import DeleteModal from './Modals/DeleteModal'

const ModalFactory = (props) => {
  const dispatch = useDispatch();

  const formAction = useSelector(state => selectors.formAction(state));
  const formEntry = useSelector(state => selectors.formEntry(state));
  const formEntryId = useSelector(state => selectors.formEntryId(state));
  const formOptions = useSelector(state => selectors.formOptions(state));
  const formModalShow = useSelector(state => selectors.formModalShow(state));

  const options = {
    formAction,
    formEntry,
    formEntryId,
    formOptions,
    modal: {
      show: formModalShow,
      onHide: () => dispatch(hideModalAction()),
      onExited: () => dispatch(clearModalAction())
    }
  };

  if (formAction == 'delete') {
    return <DeleteModal {...options} />;
  }

  if (formAction == 'form') {
    switch (formEntry) {
      case 'time_record':
        return <TimeRecordModal {...options} />;
      default:
        return null;
    }
  }

  return null;
}

export default ModalFactory
