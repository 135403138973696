class Settings {
  constructor(selector, storage) {
    this.selector = selector;
    this.storage = storage;

    this.init();
  }

  init() {
    this.buildNavigate();
    this.buildCallbacks();
    this.applyRootSettings();
  }

  update() {}

  buildNavigate() {
    const switcherId = this.buildId('trendline');

    this.selector.innerHTML = `
      <div class="dropdown settings">
        <button
          class="btn btn-secondary dropdown-toggle"
          data-toggle="dropdown"
          id="charts-settings"
          type="button"
          aria-haspopup=""
        >
          <span class="mdi mdi-tune-variant mr-1"></span>
          Charts Settings
        </button>

        <div aria-labelledby="charts-settings" class="dropdown-menu dropdown-menu-right" data-dropdown-menu>
          <div class="settings-widget">
            <div class="trendline">
              <div class="caption">Show Trendline</div>
              <div class="switch-button switch-button-sm switch-button-primary switch-button-yesno">
                <input type="checkbox" name="${switcherId}" id="${switcherId}" data-trendline-switcher />
                <span>
                  <label for="${switcherId}"></label>
                </span>
              </div>
            </div>

            <div class="vision-units">
              <div class="caption">Vision Units</div>
              <div class="d-flex justify-content-start">
                <div class="btn-group btn-group-toggle btn-group-settings">
                  <button class="btn btn-sm" data-unit="snellen">Snellen</button>
                  <button class="btn btn-sm" data-unit="logmar">LogMAR</button>
                </div>
              </div>
            </div>

            <div class="steps">
              <div class="caption">Step Size</div>
              <div class="d-flex justify-content-start">
                <div class="btn-group btn-group-toggle btn-group-settings">
                  <button class="btn btn-sm" data-step="1" type="button">1 day</button>
                  <button class="btn btn-sm" data-step="3" type="button">3 days</button>
                  <button class="btn btn-sm" data-step="7" type="button">7 days</button>
                  <button class="btn btn-sm" data-step="14" type="button">14 days</button>
                  <button class="btn btn-sm" data-step="30" type="button">1 month</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>`;
  }

  buildCallbacks() {
    this.dropdownMenu.addEventListener('click', (e) => e.stopPropagation());
    this.trendlineSwitcher.addEventListener('click', (e) => this.switchTrendline(e));

    this.stepButtons.forEach(el => el.addEventListener('click', (e) => this.changeStep(e)));
    this.unitButtons.forEach(el => el.addEventListener('click', (e) => this.changeUnit(e)));
  }

  applyRootSettings() {
    const { step, vision_unit } = this.storage.params;

    this.applyActiveStepButton(step);
    this.applyActiveUnitButton(vision_unit);
  }

  switchTrendline(e) {
    this.storage.switchTrendline(e.target.checked);
  }

  changeStep(e) {
    const { step } = e.target.dataset;

    this.applyActiveStepButton(step);

    this.storage.changeStep(step);
  }

  changeUnit(e) {
    const { unit } = e.target.dataset;

    this.applyActiveUnitButton(unit);

    this.storage.changeUnit(unit);
  }

  applyActiveStepButton(step) {
    this.stepButtons.forEach((element) => {
      element.classList.toggle('active', element.dataset.step == step);
    });
  }

  applyActiveUnitButton(unit) {
    this.unitButtons.forEach((element) => {
      element.classList.toggle('active', element.dataset.unit == unit);
    });
  }

  buildId(prefix) {
    return prefix + '-' + Math.random().toString(36).substr(2, 9);
  }

  get stepButtons() {
    return this.selector.querySelectorAll('[data-step]');
  }

  get unitButtons() {
    return this.selector.querySelectorAll('[data-unit]');
  }

  get dropdownMenu() {
    return this.selector.querySelector('[data-dropdown-menu]');
  }

  get trendlineSwitcher() {
    return this.selector.querySelector('[data-trendline-switcher]');
  }
}

export default Settings
