import { createAction } from 'redux-actions';
import axios from 'src/utils/axios'
import { formatPath } from 'src/utils/formatters'

const getListReceive = createAction('COMPARES/RECEIVE');
const getListRequest = createAction('COMPARES/REQUEST');
const setRequestErrors = createAction('COMPARES/ERRORS');

const getList = (page) => (
  async dispatch => {
    const {
      ids,
      from,
      to,
      without_ids,
      only_amsler,
      load_tree_path
    } = window.compares;

    let params = { page };

    if (ids) params.ids = ids;
    if (from) params.from = from;
    if (to) params.to = to;
    if (without_ids) params.without_ids = without_ids;
    if (only_amsler) params.only_amsler = only_amsler;

    dispatch(getListReceive());

    try {
      const { data: { cycles, total, complete } } = await axios.get(load_tree_path, { params });

      dispatch(getListRequest({ cycles, total, complete }));
    } catch(err) {
      const { data: { base }, status, statusText } = err.response;

      console.log(err);

      dispatch(setRequestErrors(base));
    }
  }
);

const setPageAction = createAction('COMPARES/SET_PAGE');

const setPage = (page) => (
  async dispatch => {
    dispatch(setPageAction(page));
  }
);

const busyBookmarkAction = createAction('COMPARES/BOOKMARK_BUSY');
const changeBookmarkAction = createAction('COMPARES/BOOKMARK_CHANGE');
const failureBookmarkAction = createAction('COMPARES/BOOKMARK_FAILURE');

const changeBookmark = (id) => (
  async dispatch => {
    const { bookmark_cycle_path } = window.compares;

    dispatch(busyBookmarkAction(id));

    try {
      const { data: { bookmarked } } = await axios({
        method: 'POST',
        url: formatPath(bookmark_cycle_path, { id }),
        params: {
          variant: 'compare'
        }
      });

      dispatch(changeBookmarkAction({ id, bookmarked }));
    } catch(err) {
      console.error(err);

      dispatch(failureBookmarkAction(id));
    }
  }
);

const busyTransmitAction = createAction('COMPARES/TRANSMIT_BUSY');
const completeTransmitAction = createAction('COMPARES/TRANSMIT_COMPLETE');

const transmitDocument = (options) => (
  async dispatch => {
    const { transmit_path } = window.compares;

    dispatch(busyTransmitAction());

    try {
      const { data: { flash } } = await axios({
        method: 'GET',
        url: transmit_path,
        params: options
      });

      dispatch(completeTransmitAction(flash));
    } catch(err) {
      console.error(err);

      dispatch(completeTransmitAction(flash));
    }
  }
);

const deleteFromListAction = createAction('COMPARES/DELETE');

const deleteFromList = (id) => (
  async dispatch => {
    dispatch(deleteFromListAction(id));
  }
);

const switchVisibilityAction = createAction('COMPARES/SWITCH_VISIBILITY');
const switchShowAllAction = createAction('COMPARES/SWITCH_SHOW_ALL');
const changeScrollPositionsAction = createAction('COMPARES/CHANGE_SCROLL');

export {
  getList,
  getListReceive,
  getListRequest,
  setRequestErrors,
  switchVisibilityAction,
  switchShowAllAction,
  deleteFromList,
  deleteFromListAction,
  changeScrollPositionsAction,
  setPageAction,
  setPage,
  busyBookmarkAction,
  changeBookmarkAction,
  changeBookmark,
  failureBookmarkAction,
  busyTransmitAction,
  completeTransmitAction,
  transmitDocument
};
