import { Controller } from 'stimulus'
import RangeDatesConverter from 'src/utils/rangeDatesConverter'

const datesConverter = new RangeDatesConverter();

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', (e) => this.setRange());
  }

  setRange() {
    const groupBy = this.element.value;
    const range = this.setRangeOptions(groupBy);

    this.setFrom(datesConverter.dateFrom(range));
    this.setTo(datesConverter.dateTo(range));
    this.applyFilters();
  }

  setFrom(date_from) {
    this.fromSelector.value = date_from;
  }

  setTo(date_to) {
    this.toSelector.value = date_to;
  }

  setRangeOptions(groupBy) {
    let range, value;

    switch (groupBy) {
      case 'week':
        [range, value] = ['12 Weeks', '12_weeks'];
        break;
      case 'day':
        [range, value] = ['Last 30 Days', 'last_30_days'];
        break;
      case 'month':
        [range, value] = ['This Month', 'this_month'];
        break;
    }

    this.rangeField.options.length = 0;
    this.addRangeOption(range, value);
    this.rangeField.value = value;

    return value;
  }

  applyFilters() {
    const form = this.filterFormSelector;
    $(form).submit();
  }

  addRangeOption(optionText, optionValue) {
    let opt = this.rangeField.options;

    opt[opt.length] = new Option(optionText, optionValue);
  }

  get fromSelector() {
    return document.getElementById('q_from');
  }

  get toSelector() {
    return document.getElementById('q_to');
  }

  get rangeField() {
    return document.getElementById('q_range');
  }

  get filterFormSelector() {
    return document.getElementsByClassName('form-filters');
  }
}
