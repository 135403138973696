import Scores from 'src/components/summary/widgets/ophthalmology/scores'
import Vision from 'src/components/summary/widgets/ophthalmology/vision'
import Contrast from 'src/components/summary/widgets/ophthalmology/contrast'
import Color from 'src/components/summary/widgets/ophthalmology/color'
import Amsler from 'src/components/summary/widgets/ophthalmology/amsler'
import Statistic from 'src/components/summary/widgets/ophthalmology/statistic'

class Factory {
  constructor(selector, storage) {
    if (this.factory = Factory.registry[selector.dataset.key])
      return new this.factory(selector, storage);

    console.log('Factory is not registered')
  }

  static get registry() {
    return {
      'ophthalmology:scores': Scores,
      'ophthalmology:vision': Vision,
      'ophthalmology:contrast': Contrast,
      'ophthalmology:color': Color,
      'ophthalmology:amsler': Amsler,
      'ophthalmology:statistic': Statistic
    }
  }
}

export default Factory
