const AMSLER = {
  chart: {
    type: 'column',
    marginTop: 20,
    height: 420
  },
  yAxis: [
    {
      min: 0,
      max: 100,
      tickPositioner(min, max) {
        const positions = [];

        let i = min;
        while (i <= max) {
          if ((i % 5) === 0) { positions.push(i); }
          i++;
        }

        return positions;
      },
      reversed: true,
      title: {
        text: null,
        style: 'display: none;'
      }
    }
  ],
  tooltip: {
    shared: true
  },
  plotOptions: {
    series: {
      cursor: 'pointer',
      minPointLength: 3,
      pointPadding: 0.05,
      point: {
        events: {}
      },
      states: {
        hover: {
          enabled: false
        }
      }
    },
    column: {
      borderWidth: 0,
      grouping: false,
      shadow: false
    }
  }
};

export { AMSLER }
