import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { saveTraining } from 'src/react/store/TrainingWizard/actions'

import { Button, Modal, FormGroup, FormLabel } from 'react-bootstrap'
import { Form, Field } from 'react-final-form'
import { TextField, FieldError, SubmitError, SubmitButton } from 'src/react/components/FormFields/index'

const TrainingModal = (props) => {
  const { modal } = props;

  const dispatch = useDispatch();
  const training = useSelector(state => selectors.training(state));
  const onSubmit = async (values) => await dispatch(saveTraining(values, training));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={training}
      render={({ submitError, handleSubmit, form, submitting, pristine, values }) => (
        <Modal
          {...modal}
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                Edit Training
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0">
              {
                submitError && (
                  <SubmitError
                    submitError={ submitError }
                  />
                )
              }
              <FormGroup>
                <FormLabel>
                  Name
                </FormLabel>
                <Field
                  name="name"
                  component={TextField}
                  type="text"
                />
                <FieldError name="name" />
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  Success Threshold, % *
                </FormLabel>
                <Field
                  name="threshold"
                  component={TextField}
                  type="number"
                />
                <FieldError name="threshold" />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton
                type="submit"
                disabled={submitting}
                className="btn-primary"
              >
                Confirm
              </SubmitButton>
              <Button
                onClick={modal.onHide}
                className="btn-secondary"
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    />
  );
}

export default TrainingModal
