const COLOR = {
  chart: {
    type: 'column',
    marginTop: 20,
    height: 380
  },
  yAxis: [
    {
      min: 0,
      max: 12,
      tickInterval: 1,
      reversed: true,
      title: {
        text: null,
        style: 'display: none;'
      }
    }
  ],
  plotOptions: {
    column: {
      borderWidth: 0
    },
    series: {
      cursor: 'pointer',
      minPointLength: 3,
      events: {
        legendItemClick: function() {
          return false;
        }
      },
      point: {
        events: {}
      },
      states: {
        hover: {
          enabled: false
        }
      }
    }
  }
};

export { COLOR };
