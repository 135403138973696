import { Controller } from 'stimulus'
import swal from 'sweetalert'

export default class extends Controller {
  connect() {
    this.clicked = false;
    this.attachListeners();
  }

  confirm(event) {
    if (this.clicked) return;

    this.clicked = true;

    event.preventDefault();
    event.stopPropagation();

    this.displayModal().then((isConfirmed) => {
      if (isConfirmed) {
        this.element.click();
      } else {
        this.clicked = false;
      }
    });
  }

  displayModal() {
    const { title, message, cancel_btn_text = 'Cancel', confirm_btn_text = 'Yes' } = JSON.parse(this.element.dataset.options);

    let content = document.createElement('div');
    content.innerHTML = message;

    return swal({
      title: title,
      content: content,
      buttons: {
        cancel: {
          text: cancel_btn_text,
          visible: true,
        },
        confirm: {
          text: confirm_btn_text
        }
      }
    });
  }

  attachListeners() {
    this.initializeTooltips()
  }

  initializeTooltips() {
    $('[data-toggle="tooltip"], .tooltip').tooltip('hide');
    $('[data-toggle="tooltip"]').tooltip();
  }
}
