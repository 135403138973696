import { Controller } from 'stimulus'
import $ from 'jquery'
import 'selectize'

export default class extends Controller {
  connect() {
    let self = this;

    const selected_value = this.data.get('selected')
    const selected = selected_value && JSON.parse(selected_value);

    let selectize = $(this.element).selectize({
      maxItems: 2,
      persist: false,
      create: false,
      plugins: ['remove_button'],
      onBlur:function() {
        if(this.getValue() == '') {
          this.setValue(Object.keys(this.options));
        }
      },
      onChange:function() {
        self.switchEyeToTest(this.getValue());
      }
    });

    if (selected) {
      selectize[0].selectize.setValue(selected)
    }

    self.switchEyeToTest(selectize[0].selectize.getValue());
  }

  switchEyeToTest(selected) {
    const accessible = selected.length === 2 ? true : false;

    this.changeControlValue(this.eyeToStartSelect, selected);
    this.toggleControlAccessible(this.eyeToStartSelect, accessible, selected);

    this.changeControlValue(this.eyeToStartHiddenInput, selected);
    this.toggleControlAccessible(this.eyeToStartHiddenInput, !accessible, selected);

    this.toggleDifferentEyesSwitchAccessible(accessible);
  }

  toggleControlAccessible(control, accessible, selected) {
    if (accessible === true) {
      $(control).removeAttr('disabled');
    } else {
      $(control).attr('disabled', 'disabled');
    }
  }

  changeControlValue(control, selected) {
    if (selected.length == 1) {
      control.value = selected[0];
    }
  }

  toggleDifferentEyesSwitchAccessible(accessible) {
    if (accessible === true) {
      $(this.differentEyesSwitchBox).removeClass('disabled');
    } else {
      this.differentEyesSwitch.checked = false;
      this.triggerEvent(this.differentEyesSwitch, 'change');
      $(this.differentEyesSwitchBox).addClass('disabled');
    }
  }

  triggerEvent(element, eventName) {
    let event = document.createEvent("HTMLEvents");
    event.initEvent(eventName, false, true);
    element.dispatchEvent(event);
  }

  get eyeToStartSelect() {
    return document.getElementsByClassName('eye-to-start')[0];
  }

  get eyeToStartHiddenInput() {
    return document.getElementsByClassName('eye-to-start')[1];
  }

  get differentEyesSwitchBox() {
    return document.getElementsByClassName('different-eyes')[0];
  }

  get differentEyesSwitch() {
    return document.getElementById('differentEyes');
  }
}
