import React from 'react'

const Avatar = ({ user }) => {
  const { name, avatar } = user;

  const nameByChunks = (name) => {
    const chunks = name.replace(/\s+/g, ' ').trim().split(' ');

    return [chunks[0], chunks[chunks.length - 1]];
  };

  const avatarInitials = (name) => {
    const [ lastName, firstName ] = nameByChunks(name);

    return (lastName[0] + firstName[0]).toUpperCase();
  };

  return avatar ? (
    <img
      src={avatar.thumb.url}
      width="50"
      height="50"
      alt="avatar"
    />
  ) : (
    <div className="avatar user-avatar">
      <div className="with-text-overflow">
        <div className="avatar-middle patient-background">
          <div className="initials">{avatarInitials(name)}</div>
        </div>
      </div>
    </div>
  );
}

export default Avatar
