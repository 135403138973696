import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import PatternFill from 'highcharts/modules/pattern-fill'

HighchartsMore(Highcharts)
PatternFill(Highcharts)

import Base from 'src/charts/cycle/amsler/base'

import {
  PDF_ZONES,
  DEFAULT_OPTIONS,
  PDF_OPTIONS,
  CYCLE_PDF_OPTIONS
} from 'src/charts/cycle/constants/amsler'

const CENTRAL_CHART_SIZE = {
  chart: {
    height: 110,
    width: 110,
  }
};

const FULL_CHART_SIZE = {
  chart: {
    height: 200,
    width: 200
  }
};

class Widget extends Base {
  init() {
    Highcharts.chart(this.selector, this.chartOptions());
  }

  defaultOptions() {
    return $.extend(true, {}, DEFAULT_OPTIONS, PDF_OPTIONS, CYCLE_PDF_OPTIONS);
  }

  chartSize() {
    switch (this.type) {
      case 'central':
        return CENTRAL_CHART_SIZE;
      case 'full':
        return FULL_CHART_SIZE;
    }
  }

  modifySeriesObject(object) {
    $.extend(object, PDF_ZONES[object.zone]);
  }
}

export default Widget
