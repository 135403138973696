import React from 'react'

const Empty = (props) => {
  return (
    <div className="no-records-block">
      <div className="icon">
        <span className="icomoon-hrs"></span>
      </div>
      <div className="message">
        No health records for compare
      </div>
    </div>
  );
}

export default Empty
