import BaseController from "./base_controller";
import LeftEye from 'images/eyes/left.svg'
import RightEye from 'images/eyes/right.svg'
import Amsler from 'src/charts/cycle/amsler/widget'

export default class extends BaseController {

  resolve(response) {
    this.instance.setContent(this.renderFull(response).innerHTML);
  }

  renderFull(response) {
    const template = `
        <div class="full-hr-preview">

          ${this.renderSurvey(response)}

          <div class="row">
            <div class="col-6 left-eye">
              <div class="section-title">
                <img src="${LeftEye}">
                Left (OS)
              </div>
            </div>
            <div class="col-6 right-eye">
              <div class="section-title">
                <img src="${RightEye}">
                Right (OD)
              </div>
            </div>
          </div>

          ${this.renderVision(response)}

          ${this.renderContrast(response)}

          ${this.renderColor(response)}

          ${this.renderAmsler(response)}

        </div>`;

    let container = this.buildContainer(template);

    const { 'ophthalmology:amsler:l': left, 'ophthalmology:amsler:r': right } = response.tests;

    if (this.withResults(left)) {
      new Amsler(container.querySelector('[data-left-central]'), { type: 'central', payload: left.central });
      new Amsler(container.querySelector('[data-left-full]'), { type: 'full', payload: left.full });
    }

    if (this.withResults(right)) {
      new Amsler(container.querySelector('[data-right-central]'), { type: 'central', payload: right.central });
      new Amsler(container.querySelector('[data-right-full]'), { type: 'full', payload: right.full });
    }

    return container;
  }

  renderAmsler(response) {
    if (this.resultsNotAvailable(response, 'ophthalmology:amsler')) return '';

    const { 'ophthalmology:amsler:l': left, 'ophthalmology:amsler:r': right } = response.tests;

    const template = `
      <div class="row">
        <div class="col-6 left-eye">
          <div class="test test-amsler ${this.testClass(left)}">
            <div class="test-name">
              <div class="icon icomoon-test-amsler"></div>
              Amsler-VF
            </div>
            <div class="test-result">
              <div class="chapter">Central 5-degrees</div>
              <div class="grid central" data-left-central>
                ${this.withResults(left) ? '' : 'N/A'}
              </div>
              <div class="chapter">QUAD Central 10-Degrees</div>
              <div class="grid full" data-left-full>
                ${this.withResults(left) ? '' : 'N/A'}
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="test test-amsler ${this.testClass(right)}">
            <div class="test-name">
              <div class="icon icomoon-test-amsler"></div>
              Amsler-VF
            </div>
            <div class="test-result">
              <div class="chapter">Central 5-degrees</div>
              <div class="grid central" data-right-central>
                ${this.withResults(right) ? '' : 'N/A'}
              </div>
              <div class="chapter">QUAD Central 10-Degrees</div>
              <div class="grid full" data-right-full>
                ${this.withResults(right) ? '' : 'N/A'}
              </div>
            </div>
          </div>
        </div>
      </div>`;

    return template;
  }

  renderColor(response) {
    if (this.resultsNotAvailable(response, 'ophthalmology:color')) return '';

    const { 'ophthalmology:color:l': left, 'ophthalmology:color:r': right } = response.tests;

    const template = `
      <div class="row">
        <div class="col-6 left-eye">
          <div class="test ${this.testClass(left)}">
            <div class="test-name">
              <div class="icon icomoon-test-color"></div>
              Color
            </div>
            <div>
              ${
                this.withResults(left) ?
                `${left.recognized_plates} of ${left.shown_plates} (Missed ${left.missed_plates})` :
                'N/A'
                }
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="test ${this.testClass(right)}">
            <div class="test-name">
              <div class="icon icomoon-test-color"></div>
              Color
            </div>
            <div>
            ${
              this.withResults(right) ?
              `${right.recognized_plates} of ${right.shown_plates} (Missed ${right.missed_plates})` :
              'N/A'
              }
            </div>
          </div>
        </div>
      </div>`;

    return template;
  }

  renderVision(response) {
    if (this.resultsNotAvailable(response, 'ophthalmology:vision')) return '';

    const { 'ophthalmology:vision:l': left, 'ophthalmology:vision:r': right } = response.tests;

    const template = `
      <div class="row">
        <div class="col-6 left-eye">
          <div class="test ${this.testClass(left)}">
            <div class="test-name">
              <div class="icon icomoon-test-vision"></div>
              Vision
            </div>
            <div>${this.withResults(left) ? `${left.snellen_pretty} (${left.logmar_pretty})` : 'N/A'}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="test ${this.testClass(right)}">
            <div class="test-name">
              <div class="icon icomoon-test-vision"></div>
              Vision
            </div>
            <div>${this.withResults(right) ? `${right.snellen_pretty} (${right.logmar_pretty})` : 'N/A'}</div>
          </div>
        </div>
      </div>`;

    return template
  }

  renderContrast(response) {
    if (this.resultsNotAvailable(response, 'ophthalmology:contrast')) return '';

    const { 'ophthalmology:contrast:l': left, 'ophthalmology:contrast:r': right } = response.tests;

    const template = `
      <div class="row">
        <div class="col-6 left-eye">
          <div class="test ${this.testClass(left)}">
            <div class="test-name">
              <div class="icon icomoon-test-contrast"></div>
              Contrast
            </div>
            <div>${this.withResults(left) ? `${left.contrast_pelli_robson}` : 'N/A'}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="test ${this.testClass(right)}">
            <div class="test-name">
              <div class="icon icomoon-test-contrast"></div>
              Contrast
            </div>
            <div>${this.withResults(right) ? `${right.contrast_pelli_robson}` : 'N/A'}</div>
          </div>
        </div>
      </div>`;

    return template;
  }

  renderSurvey(response) {
    if (this.resultsNotAvailable(response, 'ophthalmology:survey')) return '';

    const { 'ophthalmology:survey': survey } = response.tests;

    const reducer = (accumulator, currentValue) => accumulator + (
      survey[currentValue] != null ? (
        `<li>
          <span class="text">${BaseController.surveyLibrary[currentValue]}</span>
          ${this.renderAnswer(survey, currentValue)}
        </li>`
      ) : ''
    );

    const template = `
    <div class="survey ${survey ? '' : 'empty-test'}">
      <div class="section-title">SURVEY</div>
      <ul class="survey-list">
          ${survey ? Object.keys(BaseController.surveyLibrary).reduce(reducer, '') : 'N/A'}
      </ul>
    </div>`;

    return template;
  }

  resultsNotAvailable(response, key) {
    return !response.available_keyrings.includes(key);
  }

  testClass(results) {
    if (!this.withResults(results)) return 'empty-test' ;
    if (results.base) return 'base-test' ;
  }
}
