class MonitoringReasonsFormatter {
  constructor(element, params = {}) {
    this.element = element;
    this.params = params;
  }

  dotInsensitiveQuery(query) {
    return query.split('.').join('');
  }

  optionViewCallback() {
    return (item, escape) => {
      let desc = item.description ? ' ' + escape(item.description) : '';
      const classes = ['billable-code-option'];

      if (item.code == 'None') {
        classes.push('strong-select-option');
      }

      return '<div class="' + classes.join(' ') + '"><span>' + escape(item.code) + desc + '</span></div>';
    };
  }

  itemViewCallback() {
    return (item, escape) => {
      return `<div type='button' data-toggle='tooltip' data-placement='top' title='${item.description}'>` + '<span>' + escape(this.injectPeriodToCode(item.code)) + '</span></div>';
    };
  }

  injectPeriodToCode(code) {
    if (code.length <= 3 || code.indexOf('.') > -1 || code == 'None') {
      return code
    }

    return [code.slice(0, 3), '.', code.slice(3)].join('');
  }

  formatResponseData(data) {
    data.forEach((el) => {
      el['code'] = this.injectPeriodToCode(el['code']);
    });

    return data;
  }

  cleanHiddenSelectOptions(options) {
    Array.from(options).forEach((el) => {
      el.value = el.value.replace('.', '');
    });
  }
}

export default MonitoringReasonsFormatter
