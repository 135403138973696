import Base from 'src/components/summary/storages/base'
import * as Actions from 'src/components/summary/actions'

class Storage extends Base {
  changeUnit(unit, key) {
    switch (key) {
      case 'weight':
        return this.updateParams(Actions.UPDATE_WEIGHT_UNIT, { weight_unit: unit });
      case 'temperature':
        return this.updateParams(Actions.UPDATE_TEMPERATURE_UNIT, { temperature_unit: unit });
    }
  }

  get defaultParams() {
    return {
      step: 1,
      weight_unit: 'lbs',
      temperature_unit: 'fahrenheit'
    };
  }
}

export default Storage
