import React from 'react'
import { FormGroup, FormLabel, Button } from 'react-bootstrap'
import { Field } from 'react-final-form'

import { TextField, FieldError, ImageAttachmentField } from 'src/react/components/FormFields/index'

import classnames from 'classnames'

const AnswerRow = ({ index, name, form: { mutators: { switchCorrectAnswer }}, fields }) => {
  const removeLineAvailable = fields.length > 2;

  return (
    <div className="form-row">
      <FormGroup className="col-auto currect-answer">
        <FormLabel>
          Correct
        </FormLabel>
        <Field name={`${name}.correct`}>
          {({ input, meta }) => (
            <Button
              className="btn bg-white"
              onClick={
                () => switchCorrectAnswer(index)
              }
            >
              <div
                className={
                  classnames('mdi', 'mdi-24px', { 'mdi-radiobox-blank text-grey': !input.value, 'mdi-radiobox-marked text-success': input.value })
                }
              >
              </div>
            </Button>
          )
        }
        </Field>
      </FormGroup>

      <FormGroup className="col-md-8">
        <div className="d-flex justify-content-between align-items-center">
          <FormLabel>
            Answer #{index + 1}
          </FormLabel>
          <ImageAttachmentField name={`${name}.image`} />
        </div>
        <Field
          name={`${name}.answer`}
          component={TextField}
          placeholder="Option text ..."
          type="text"
        />
        <FieldError name={`${name}.answer`} />
      </FormGroup>

      <FormGroup className="col-md-2">
        <FormLabel>
          Answer Score
        </FormLabel>
        <Field
          name={`${name}.score`}
          component={TextField}
          type="number"
        />
        <FieldError name={`${name}.score`} />
      </FormGroup>

      <FormGroup className="col-auto remove-answer">
        {
          removeLineAvailable && (
            <Button
              className="btn bg-white"
              onClick={
                () => fields.remove(index)
              }
            >
              <div className="mdi mdi-24px mdi-close text-danger"></div>
            </Button>
          )
        }
      </FormGroup>
    </div>
  );
}

export default AnswerRow
