import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Collapse } from 'react-bootstrap'

const FlashAlerts = ({ id, message, options: { type = 'success', timeout = 2500 } }) => {
  const [visibility, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);

    const timer = setTimeout(() => setVisible(false), timeout);

    return () => clearTimeout(timer);
  }, [id, message]);

  return (
    <Collapse
      in={visibility}
    >
      <div className="flash-alerts">
        <div className={`alert alert-icon alert-icon-border alert-dismissible alert-main alert-${type}`} role="alert">
          <div className="icon">
            <span className="mdi mdi-check"></span>
          </div>
          <div className="message">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="alert"
              type="button"
              onClick={
                () => setVisible(false)
              }
            >
              <span aria-hidden="true" className="mdi mdi-close"></span>
            </button>
            <h4 id="flash_notice" className="m-1">
              {message}
            </h4>
          </div>
        </div>
      </div>
    </Collapse>
  );
}

const renderComponent = (message, options = {}) => {
  const id = Date.now();

  ReactDOM.render(
    <FlashAlerts
      id={ id }
      message={ message }
      options={ options }
    />,
    document.querySelector('.flash-alerts')
  );
};

const showErrorMessage = (message, options = {}) => {
  renderComponent(message, { type: 'danger', ... options });
}

const showSuccessMessage = (message, options = {}) => {
  renderComponent(message, { type: 'success', ... options });
}

const resetFlashMessage = () => {
  ReactDOM.unmountComponentAtNode(document.querySelector('.flash-alerts'));
}

export {
  showErrorMessage,
  showSuccessMessage,
  resetFlashMessage
}
