//import axios from 'axios'
//import { csrfToken } from '@rails/ujs'
//
//const safeAxios = () => {
//  return axios.create({
//    headers: {
//      'X-CSRF-Token': csrfToken()
//    }
//  });
//}
//
//export default safeAxios

import axios from 'axios'
import { csrfToken } from '@rails/ujs'

axios.interceptors.request.use((config) => {
  config.headers.common['X-CSRF-Token'] = csrfToken();

  return config;
}, (err) => {
  return Promise.reject(err);
});

export default axios
