import Highcharts from 'highcharts'

import { DEFAULT_OPTIONS } from 'src/charts/dashboard/onboarding/constants'

class Chart {
  constructor(selector, params) {
    this.selector = selector;
    this.params = params;

    this.init();
  }

  init() {
    this.instance = Highcharts.chart(this.selector, this.chartOptions());
  }

  chartOptions() {
    return $.extend(true, {}, DEFAULT_OPTIONS, this.chartSize(), this.params.payload);
  }

  chartSize() {
    return {
      chart: {
        height: 190,
        width: 190
      }
    }
  }
}

export default Chart
