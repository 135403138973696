export const MIN_TIME = 1;
export const MAX_TIME = 23;

class NudgeIntervalView {
  constructor(timeElement, intervalElement) {
    this.timeElement = timeElement;
    this.intervalElement = intervalElement;

    this.init();
  }

  init() {
    this.rerender();
  }

  rerender() {
    this.displayAllOptions();
    this.hideOptionsByNudgeTime();
    this.setDefaultOption();
    this.disable();
  }

  disable() {
    if (this.nudgeTime === 0 || this.nudgeTime === 23) {
      this.intervalElement.setAttribute('readonly', true);
    } else {
      this.intervalElement.removeAttribute('readonly');
    }
  }

  displayAllOptions() {
    for (let i = MIN_TIME; i <= MAX_TIME; i++) {
      this.intervalOptions[i].style.display = 'block';
    }
  }

  hideOptionsByNudgeTime() {
    for (let i = MAX_TIME; i > this.maxAvailableInterval; i--) {
      this.intervalOptions[i].style.display = 'none';
    }
  }

  setDefaultOption() {
    if (!this.isAvailableInterval) {
      this.intervalElement.value = '';
    }
  }

  get intervalOptions() {
    return this.intervalElement.getElementsByTagName('option');
  }

  get maxAvailableInterval() {
    return MAX_TIME - this.nudgeTime;
  }

  get nudgeTime() {
    return +this.timeElement.value;
  }

  get nudgeInterval() {
    return +this.intervalElement.value;
  }

  get isAvailableInterval() {
    if (this.nudgeTime === 0) return false;
    return (this.nudgeTime + this.nudgeInterval) <= MAX_TIME;
  }
}

export default NudgeIntervalView
