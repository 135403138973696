import BaseController from './base_controller'

import Storage from 'src/components/summary/storages/cardiology'
import Factory from 'src/components/summary/factories/cardiology'

import Range from 'src/components/summary/controls/range'
import Navigate from 'src/components/summary/controls/navigate'
import Timeframe from 'src/components/summary/controls/timeframe'
import ResetButton from 'src/components/summary/controls/reset_button'
import ExportButton from 'src/components/summary/controls/export_button'

import Settings from 'src/components/summary/controls/cardiology/settings'

import * as Actions from 'src/components/summary/actions'

export default class extends BaseController {
  initializeFunctionality() {
    this.storage = new Storage(this.element.dataset);

    this.range = new Range(this.range_selector, this.storage);
    this.navigate = new Navigate(this.navigate_selector, this.storage);
    this.settings = new Settings(this.settings_selector, this.storage);
    this.timeframe = new Timeframe(this.timeframe_selector, this.storage);
    this.reset_button = new ResetButton(this.reset_selector, this.storage);
    this.export_button = new ExportButton(this.export_selector, this.storage);

    this.factories = [...this.targets].map(target => new Factory(target, this.storage));
  }

  attachCallbacks() {
    this.storage.callbacks['onChange'] = (action) => {
      switch (action) {
        case Actions.UPDATE_RANGE:
          return this.redrawTimeframesChange();
        case Actions.UPDATE_WEIGHT_UNIT:
          return this.redrawUnitsChange('cardiology:weight');
        case Actions.UPDATE_TEMPERATURE_UNIT:
          return this.redrawUnitsChange('cardiology:temperature');
        case Actions.UPDATE_STEP:
          return this.redrawStepChange();
      }
    }
  }

  redrawUnitsChange(type) {
    this.export_button.update();

    const factories = this.filterFactories(type);

    this.showLoading(factories);
    this.sequentiallyFetchResults(factories);
  }

  filterFactories(type) {
    return this.factories.filter((el, index) => el.params.key == type);
  }
}
