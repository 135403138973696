import { createAction } from 'redux-actions';
import axios from 'src/utils/axios'
import { formatPath } from 'src/utils/formatters'

import {
  normalizeTraining,
  normalizeCategory,
  normalizeSnippet,
  normalizeQuestion
} from './schema'

import {
  trainingForm,
  categoryForm,
  snippetForm,
  questionForm,
  formErrors
} from './forms'

import { showSuccessMessage } from 'src/react/utils/TrainingWizard/flash'

const getTrainingTreeRequest = createAction('TRAININGS/REQUEST_TREE');

const getTrainingTree = () => (
  async dispatch => {
    const { load_tree_path } = window.training;

    try {
      const { data } = await axios.get(load_tree_path);

      dispatch(getTrainingTreeRequest(normalizeTraining(data)));
    } catch(err) {
      console.log(err);
    }
  }
);

const activateTrainingAction = createAction('TRAININGS/ACTIVATE_TRAINING');

const activateTraining = () => (
  async dispatch => {
    const { activate_training_path } = window.training;

    try {
      const { data: { training } } = await axios.put(activate_training_path);

      dispatch(activateTrainingAction(training));

      showSuccessMessage('Training was successfully activated.');
    } catch(err) {
      return formErrors(err);
    }
  }
);

const copyTrainingAction = createAction('TRAININGS/COPY_TRAINING');

const copyTraining = () => (
  async dispatch => {
    const { copy_training_path } = window.training;

    try {
      const { data } = await axios({
        method: 'PUT',
        url: copy_training_path
      });

      dispatch(copyTrainingAction());

      showSuccessMessage('Training was successfully copied.');
    } catch(err) {
      return formErrors(err);
    }
  }
);

const updateTrainingAction = createAction('TRAININGS/UPDATE_TRAINING');

const saveTraining = (values, entity) => (
  async dispatch => {
    const { update_training_path } = window.training;

    try {
      const { data: { training } } = await axios({
        method: 'PUT',
        url: formatPath(update_training_path, { id: entity.id }),
        data: trainingForm(values)
      });

      dispatch(updateTrainingAction(training));

      showSuccessMessage('Training was successfully updated.');
    } catch(err) {
      return formErrors(err);
    }
  }
);

const showFormModalAction = createAction('TRAININGS/SHOW_FORM_MODAL');
const showCopyModalAction = createAction('TRAININGS/SHOW_COPY_MODAL');
const showDeleteModalAction = createAction('TRAININGS/SHOW_DELETE_MODAL');
const showActivateTrainingModalAction = createAction('TRAININGS/SHOW_ACTIVATE_TRAINING_MODAL');
const hideModalAction = createAction('TRAININGS/HIDE_MODAL');
const clearModalAction = createAction('TRAININGS/CLEAR_MODAL');

const activateCategoryAction = createAction('TRAININGS/ACTIVATE_CATEGORY');
const createCategoryAction = createAction('TRAININGS/CREATE_CATEGORY');
const updateCategoryAction = createAction('TRAININGS/UPDATE_CATEGORY');
const copyCategoryAction = createAction('TRAININGS/COPY_CATEGORY');
const deleteCategoryAction = createAction('TRAININGS/DELETE_CATEGORY');
const moveCategoryAction = createAction('TRAININGS/MOVE_CATEGORY');

const saveCategory = (values, entity) => (
  async dispatch => {
    const { create_category_path, update_category_path } = window.training;

    try {
      const { data: { category } } = await axios({
        method: entity ? 'PUT' : 'POST',
        url: entity ? formatPath(update_category_path, { id: entity.id }) : create_category_path,
        data: categoryForm(values)
      });

      dispatch(entity ? updateCategoryAction(category) : createCategoryAction(category));

      showSuccessMessage('Category was successfully ' + (entity ? 'updated.' : 'created.'));
    } catch(err) {
      return formErrors(err);
    }
  }
);

const copyCategory = (entity) => (
  async dispatch => {
    const { copy_category_path } = window.training;

    try {
      const { data: { category } } = await axios({
        method: 'PUT',
        url: formatPath(copy_category_path, { id: entity.id })
      });

      dispatch(copyCategoryAction(normalizeCategory(category)));

      showSuccessMessage('Category was successfully copied.');
    } catch(err) {
      return formErrors(err);
    }
  }
);

const deleteCategory = (entity) => (
  async dispatch => {
    const { delete_category_path } = window.training;

    try {
      const { data: { category } } = await axios({
        method: 'DELETE',
        url: formatPath(delete_category_path, { id: entity.id })
      });

      dispatch(deleteCategoryAction(category));

      showSuccessMessage('Category was successfully deleted.');
    } catch(err) {
      return formErrors(err);
    }
  }
);

const moveCategory = ({ categoryId: id, from, to }) => (
  async dispatch => {
    const { move_category_path } = window.training;

    try {
      dispatch(moveCategoryAction({ categoryId: id, from, to }));

      const { data: { category } } = await axios({
        method: 'PUT',
        url: formatPath(move_category_path, { id }),
        data: { position: to }
      });
    } catch(err) {
      console.log(err);
    }
  }
);

const activateSnippetAction = createAction('TRAININGS/ACTIVATE_SNIPPET');
const createSnippetAction = createAction('TRAININGS/CREATE_SNIPPET');
const updateSnippetAction = createAction('TRAININGS/UPDATE_SNIPPET');
const copySnippetAction = createAction('TRAININGS/COPY_SNIPPET');
const deleteSnippetAction = createAction('TRAININGS/DELETE_SNIPPET');
const moveSnippetAction = createAction('TRAININGS/MOVE_SNIPPET');

const saveSnippet = (values, entity) => (
  async dispatch => {
    const { create_snippet_path, update_snippet_path } = window.training;

    try {
      const { data: { snippet } } = await axios({
        method: entity ? 'PUT' : 'POST',
        url: entity ? formatPath(update_snippet_path, { id: entity.id }) : create_snippet_path,
        data: snippetForm(values)
      });

      dispatch(entity ? updateSnippetAction({ snippet, entity }) : createSnippetAction(snippet));

      showSuccessMessage('Snippet was successfully ' + (entity ? 'updated.' : 'created.'));
    } catch(err) {
      return formErrors(err);
    }
  }
);

const copySnippet = (entity) => (
  async dispatch => {
    const { copy_snippet_path } = window.training;

    try {
      const { data: { snippet } } = await axios({
        method: 'PUT',
        url: formatPath(copy_snippet_path, { id: entity.id })
      });

      dispatch(copySnippetAction(normalizeSnippet(snippet)));

      showSuccessMessage('Snippet was successfully copied.');
    } catch(err) {
      return formErrors(err);
    }
  }
);

const deleteSnippet = (entity) => (
  async dispatch => {
    const { delete_snippet_path } = window.training;

    try {
      const { data: { snippet } } = await axios({
        method: 'DELETE',
        url: formatPath(delete_snippet_path, { id: entity.id })
      });

      dispatch(deleteSnippetAction(snippet));

      showSuccessMessage('Snippet was successfully deleted.');
    } catch(err) {
      return formErrors(err);
    }
  }
);

const moveSnippet = ({ categoryId, snippetId: id, from, to }) => (
  async dispatch => {
    const { move_snippet_path } = window.training;

    try {
      dispatch(moveSnippetAction({ categoryId, snippetId: id, from, to }));

      const { data: { snippet } } = await axios({
        method: 'PUT',
        url: formatPath(move_snippet_path, { id }),
        data: { position: to }
      });
    } catch(err) {
      console.log(err);
    }
  }
);

const createQuestionAction = createAction('TRAININGS/CREATE_QUESTION');
const updateQuestionAction = createAction('TRAININGS/UPDATE_QUESTION');
const deleteQuestionAction = createAction('TRAININGS/DELETE_QUESTION');
const moveQuestionAction = createAction('TRAININGS/MOVE_QUESTION');

const saveQuestion = (values, entity, { snippetId }) => (
  async dispatch => {
    const { create_question_path, update_question_path } = window.training;

    try {
      const { data: { question } } = await axios({
        method: entity ? 'PUT' : 'POST',
        url: (
          entity ?
          formatPath(update_question_path, { snippet_id: snippetId, id: entity.id }) :
          formatPath(create_question_path, { snippet_id: snippetId })
        ),
        data: questionForm(values)
      });

      dispatch(entity ?
        updateQuestionAction(normalizeQuestion(question)) :
        createQuestionAction(normalizeQuestion(question))
      );

      showSuccessMessage('Question was successfully ' + (entity ? 'updated.' : 'created.'));
    } catch(err) {
      return formErrors(err);
    }
  }
);

const deleteQuestion = (entity) => (
  async dispatch => {
    const { delete_question_path } = window.training;
    const { id, snippet_id } = entity;

    try {
      const { data: { question } } = await axios({
        method: 'DELETE',
        url: formatPath(delete_question_path, { id, snippet_id })
      });

      dispatch(deleteQuestionAction(question));

      showSuccessMessage('Question was successfully deleted.');
    } catch(err) {
      return formErrors(err);
    }
  }
);

const moveQuestion = ({ snippetId: snippet_id, questionId: id, from, to }) => (
  async dispatch => {
    const { move_question_path } = window.training;

    try {
      dispatch(moveQuestionAction({ snippetId: snippet_id, questionId: id, from, to }));

      const { data: { question } } = await axios({
        method: 'PUT',
        url: formatPath(move_question_path, { snippet_id, id }),
        data: { position: to }
      });
    } catch(err) {
      console.log(err);
    }
  }
);

const moveAnswerAction = createAction('TRAININGS/MOVE_ANSWER');

const moveAnswer = ({ snippetId: snippet_id, questionId: question_id, answerId: id, from, to }) => (
  async dispatch => {
    const { move_answer_path } = window.training;

    try {
      dispatch(moveAnswerAction({ snippetId: snippet_id, questionId: question_id, answerId: id, from, to }));

      const { data: { answer } } = await axios({
        method: 'PUT',
        url: formatPath(move_answer_path, { snippet_id, question_id, id }),
        data: { position: to }
      });
    } catch(err) {
      console.log(err);
    }
  }
);

export {
  getTrainingTreeRequest,
  updateTrainingAction,
  activateTrainingAction,
  copyTrainingAction,
  showFormModalAction,
  showCopyModalAction,
  showDeleteModalAction,
  showActivateTrainingModalAction,
  hideModalAction,
  clearModalAction,
  createCategoryAction,
  updateCategoryAction,
  copyCategoryAction,
  deleteCategoryAction,
  moveCategoryAction,
  activateCategoryAction,
  activateSnippetAction,
  createSnippetAction,
  updateSnippetAction,
  copySnippetAction,
  deleteSnippetAction,
  moveSnippetAction,
  createQuestionAction,
  updateQuestionAction,
  deleteQuestionAction,
  moveQuestionAction,
  moveAnswerAction,
  getTrainingTree,
  activateTraining,
  copyTraining,
  saveTraining,
  saveCategory,
  saveSnippet,
  saveQuestion,
  copyCategory,
  copySnippet,
  deleteCategory,
  deleteSnippet,
  deleteQuestion,
  moveCategory,
  moveSnippet,
  moveQuestion,
  moveAnswer
};
