import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { showFormModalAction, showCopyModalAction } from 'src/react/store/TrainingWizard/actions'

import Ability from './Ability'
import AccordionTree from './AccordionTree'

const Categories = (props) => {
  const dispatch = useDispatch();
  const categories = useSelector(state => selectors.categories(state));
  const categoriesCount = useSelector(state => selectors.categoriesCount(state));

  return (
    <div className="categories">
      <div className="d-flex justify-content-between align-items-center">
        <div className="catigories-title">
          Categories
        </div>

        <Ability
          context="editable_training"
        >
          {
            categoriesCount > 0 && (
              <Button
                className="btn-sm mr-4"
                onClick={
                  () => dispatch(showFormModalAction({ entry: 'snippet' }))
                }
              >
                Add snippet
              </Button>
            )
          }
        </Ability>
      </div>

      {
        categoriesCount > 0 ? (
          <AccordionTree
            categories={categories}
          />
        ) : (
          <h5 className="text-secondary m-4">
            No categories
          </h5>
        )
      }

      <Ability
        context="editable_training"
      >
        <Button
          className="m-2"
          variant="link"
          onClick={
            () => dispatch(showFormModalAction({ entry: 'category' }))
          }
        >
          + Add category
        </Button>
      </Ability>
    </div>
  );
}

export default Categories
