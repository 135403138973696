import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.switchVisibitity();

    this.element.addEventListener('change', (e) => this.switchVisibitity());
  }

  switchVisibitity() {
    // cardiology
    this.measurementsInput.style.display = this.isCardiologyType ? 'block' : 'none';

    // ophthalmology
    this.visionSettings.style.display = this.isOphthalmologyType ? 'block' : 'none';
    this.ambientSettings.style.display = this.isOphthalmologyType ? 'block' : 'none';
    this.contrastSettings.style.display = this.isOphthalmologyType ? 'block' : 'none';
  }

  get isCardiologyType() {
    return this.element.value === 'cardiology';
  }

  get isOphthalmologyType() {
    return this.element.value === 'ophthalmology';
  }

  get visionSettings() {
    return document.querySelector('.vision-settings-context');
  }

  get ambientSettings() {
    return document.querySelector('.ambient-settings-context');
  }

  get contrastSettings() {
    return document.querySelector('.contrast-settings-context');
  }

  get measurementsInput() {
    return document.querySelector('.medical_group_measurements');
  }
}
