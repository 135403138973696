import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { DEFAULT_SNIPPET_FORM } from 'src/react/constants/TrainingWizard/index'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { saveSnippet } from 'src/react/store/TrainingWizard/actions'

import { Accordion, Button, Modal, FormGroup, FormLabel } from 'react-bootstrap'
import { Form, Field } from 'react-final-form'
import { TextField, SelectField, FieldError, SubmitError, SubmitButton } from 'src/react/components/FormFields/index'

import Trix from 'trix'
import { ReactTrixRTEInput } from 'react-trix-rte'

const onFileAccepted = (e) => {
  if (!['image/jpeg', 'image/png'].includes(e.file.type)) {
    e.preventDefault();

    swal('Only images can be attached.');
  }
};

const changeContent = ([e, value], state, { changeValue }) => changeValue(state, 'content', () => value);

const SnippetModal = (props) => {
  const { modal, formEntryId: entityId, formOptions: { defaultCategoryId = null } } = props;

  const dispatch = useDispatch();
  const categoriesForSelect = useSelector(state => selectors.categoriesForSelect(state));
  const snippet = entityId ? useSelector(state => selectors.getSnippetById(state, entityId)) : null;
  const onSubmit = async (values) => await dispatch(saveSnippet(values, snippet));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={snippet || DEFAULT_SNIPPET_FORM}
      mutators={{ changeContent }}
      render={({ submitError, handleSubmit, form, submitting, pristine, values }) => (
        <Modal
          {...modal}
          size="xl"
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                { snippet ? 'Edit snippet' : 'New snippet' }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                submitError && (
                  <SubmitError
                    submitError={ submitError }
                  />
                )
              }
              <FormGroup>
                <FormLabel>
                  Snippet name
                </FormLabel>
                <Field
                  name="name"
                  component={TextField}
                  placeholder="Snippet name"
                  type="text"
                />
                <FieldError name="name" />
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  Snippet category
                </FormLabel>
                <Field
                  name="category_id"
                  component={SelectField}
                  placeholder="Select category ..."
                  defaultValue={defaultCategoryId}
                  type="select"
                  options={
                    categoriesForSelect.map(({ id: value, name: label }) => ({ label, value }))
                  }
                />
                <FieldError name="category_id" />
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  Video URL
                </FormLabel>
                <Field
                  name="video"
                  component={TextField}
                  placeholder="Video URL"
                  type="text"
                />
                <FieldError name="video" />
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  Content
                </FormLabel>
                <Field
                  name="content_editable"
                >
                  {({ input, meta }) => (
                    <ReactTrixRTEInput
                      className="trix-content"
                      isRailsDirectUpload={true}
                      defaultValue={input.value}
                      onChange={form.mutators.changeContent}
                      onFileAccepted={onFileAccepted}
                    />
                  )
                }
                </Field>
                <FieldError name="content" />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton
                type="submit"
                disabled={submitting}
                className="btn-primary"
              >
                Submit
              </SubmitButton>
              <Button
                onClick={modal.onHide}
                className="btn-secondary"
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    />
  );
}

export default SnippetModal
