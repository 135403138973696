import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import * as selectors from 'src/react/store/Timesheets/selectors'
import { activateDayAction } from 'src/react/store/Timesheets/actions'

const Day = ({ day }) => {
  const dispatch = useDispatch();

  const dayWithFormat = day.format('YYYY-MM-DD');
  const dayPretty = day.format('D MMM');
  const dayOfWeek = day.format('ddd');

  const currentDay = useSelector(state => selectors.currentDay(state));
  const isLoading = useSelector(state => selectors.isLoading(state));

  const [totalAmount, patientsCount] =
    useSelector(state =>
      selectors.isLoading(state) ? [0, 0] : selectors.getStatisticsByDay(state, dayWithFormat)
    );

  const activeDayStyles = classnames('timesheet-day', { 'active': dayWithFormat == currentDay });

  return (
    <div
      className={activeDayStyles}
      onClick={
        () => dispatch(activateDayAction(dayWithFormat))
      }
    >
      <div className="day-block">
        <div className="day-number">
          {dayPretty}
        </div>
        <div className="day-title">
          {dayOfWeek}
        </div>
      </div>

      <div className="day-block">
        <div className="patients-checked">
          <span className="mdi mdi-account"></span>
          {
            isLoading ? (
              <span className="mdi mdi-loading mdi-spin"></span>
            ) :
            patientsCount
          }
        </div>
        <div className="time-tracked">
        {
          isLoading ? (
            <span className="mdi mdi-loading mdi-spin"></span>
          ) :
          `${totalAmount}m`
        }
        </div>
      </div>
    </div>
  );
}

export default Day
