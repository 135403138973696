import { Controller } from 'stimulus'
import $ from 'jquery'
import 'selectize'

export default class extends Controller {
  connect() {
    const statusSelectilize = $(this.element).selectize({
      create: false,
      plugins: ['remove_button']
    });

    this.disableStatusFilter();
    this.clearOptions(statusSelectilize);

    $('#q_event_type').change(e => {
      this.disableStatusFilter();
      this.clearOptions(statusSelectilize);
    });
  }

  disableStatusFilter() {
    const statusEl = $('#q_statuses').parent();
    statusEl.toggleClass( 'disabled-col', this.isInformativeFilter());
  }

  clearOptions(selectEl) {
    if(this.isInformativeFilter()) {
      selectEl[0].selectize.setValue(null);
    }
  }

  isInformativeFilter() {
    return $('#q_event_type').val() == 'informative';
  }
}
