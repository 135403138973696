import { Controller } from 'stimulus'
import Highcharts from 'highcharts'

export default class extends Controller {
  connect() {
    this.initializeHighcharts();
    this.initializeStickyNavigate();
    this.initializeFunctionality();

    this.attachCallbacks();
    this.applyRoot();
  }

  redrawStepChange() {
    this.navigate.update();
  }

  redrawTimeframesChange() {
    this.range.update();
    this.navigate.update();
    this.timeframe.update();
    this.export_button.update();

    this.updateWidgets();
  }

  showLoading(factories) {
    factories.forEach((factory) => factory.showLoading());
  }

  sequentiallyFetchResults(factories) {
    let promise = Promise.resolve();

    factories.forEach((factory) => {
      promise = promise.
        then(result => factory.fetchRootResults()).
        then(result => factory.hideLoading()).
        catch(jqXHR => factory.handleLoadingErrors(jqXHR));
    });

    //promise.then(() => console.log('Finished'));
  }

  updateWidgets() {
    this.applyRoot();
  }

  applyRoot() {
    this.showLoading(this.factories);
    this.sequentiallyFetchResults(this.factories);
  }

  initializeHighcharts() {
    // disable click on category label
    Highcharts.Tick.prototype.drillable = function() {};

    Highcharts.wrap(Highcharts.Chart.prototype, 'drillUp', function (proceed) {
      Highcharts.fireEvent(this, 'drillupstart');

      proceed.apply(this);
    });

    Highcharts.setOptions({
      lang: {
        drillUpText: 'Back'
      }
    });
  }

  initializeStickyNavigate() {
    const stickyTopOffset = 60;

    window.addEventListener('scroll', (e) => {
      const elementRects = this.sticky_navigate_selector.getBoundingClientRect();

      if (elementRects.top <= stickyTopOffset) {
        this.sticky_navigate_selector.classList.add('minimized-nav-bar', 'sticky-top');
      } else {
        this.sticky_navigate_selector.classList.remove('minimized-nav-bar', 'sticky-top');
      }
    });
  }

  get export_selector() {
    return this.element.querySelector('[data-export]');
  }

  get reset_selector() {
    return this.element.querySelector('[data-reset]');
  }

  get settings_selector() {
    return this.element.querySelector('[data-settings]');
  }

  get timeframe_selector() {
    return this.element.querySelector('[data-timeframe]');
  }

  get range_selector() {
    return this.element.querySelector('[data-range]');
  }

  get navigate_selector() {
    return this.element.querySelector('[data-navigate]');
  }

  get sticky_navigate_selector() {
    return this.element.querySelector('[data-sticky-navigate]');
  }

  get targets() {
    return document.querySelectorAll('.targets');
  }
}
