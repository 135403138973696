import { createSelector } from 'reselect'

const getState = state => state.timesheets;
const isLoading = state => getState(state).isLoading;
const formAction = state => getState(state).formAction;
const formEntry = state => getState(state).formEntry;
const formEntryId = state => getState(state).formEntryId;
const formModalShow = state => getState(state).formModalShow;
const formOptions = state => getState(state).formOptions;
const from = state => getState(state).from;
const to = state => getState(state).to;
const verificationLock = state => getState(state).verificationLock;
const currentDay = state => getState(state).currentDay;
const currentWeek = state => getState(state).currentWeek;
const currentUser = state => getState(state).currentUser;
const actionableUser = state => getState(state).actionableUser;

const daysTree = state => getState(state).days;
const activitiesTree = state => getState(state).activities;
const timeRecordsTree = state => getState(state).time_records;
const patientsTree = state => getState(state).patients;

const actionableId = createSelector(
  [currentUser, actionableUser],
  (current, actionable) => {
    return current.id != actionable.id ? actionable.id : null;
  }
);

const getDayById = (state, id) => daysTree(state)[id];
const getActivityById = (state, id) => activitiesTree(state)[id];
const getTimeRecordById = (state, id) => timeRecordsTree(state)[id];
const getPatientById = (state, id) => patientsTree(state)[id];

const timeRecordsAmount = createSelector(
  [timeRecordsTree], (tree => {
    let totalAmount = 0;

    for (let property in tree) {
      const { amount, draft } = tree[property];

      if (!draft) totalAmount += amount;
    }

    return totalAmount;
  })
);

const getStatisticsByDay = createSelector(
  [getDayById, timeRecordsTree],
  (item, tree) => {
    const { day: activeDay } = item;

    let totalAmount = 0,
        uniqPatientsCount = new Set();

    for (let property in tree) {
      const { day, patient_id, draft, amount } = tree[property];

      if (!draft && activeDay == day) {
        totalAmount += amount, uniqPatientsCount.add(patient_id);
      }
    }

    return [totalAmount, [...uniqPatientsCount].length];
  }
);

const getStatisticsByActivity = createSelector(
  [getActivityById, timeRecordsTree],
  (activity, tree) => {
    const { day: activityDay, patient_id: activityPatientId } = activity;

    let totalCount = 0,
        totalAmount = 0,
        uniqCategories = new Set();

    for (let property in tree) {
      const { day, patient_id, draft, amount, category } = tree[property];

      if (!draft && activityDay == day && activityPatientId == patient_id) {
        totalCount += 1, totalAmount += amount, uniqCategories.add(category);
      }
    }

    return [totalCount, totalAmount, [...uniqCategories]];
  }
);

export {
  getState,
  isLoading,
  formAction,
  formEntry,
  formEntryId,
  formModalShow,
  formOptions,
  from,
  to,
  verificationLock,
  currentDay,
  currentWeek,
  currentUser,
  actionableUser,
  actionableId,
  getDayById,
  getActivityById,
  getTimeRecordById,
  timeRecordsAmount,
  getPatientById,
  getStatisticsByDay,
  getStatisticsByActivity
}
