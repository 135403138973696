import React from 'react'

const SubmitError = ({
  submitError,
  ... rest
}) => {
  const errors = [].concat(submitError);
  const withEnumeration = errors.length > 1;

  return (
    <div
      className="bd-callout bd-callout-danger mt-0 text-danger"
    >
      {
        errors.map((error, index) => (
          <div
            key={ index }
          >
            { withEnumeration && `${index + 1})` } { error }
          </div>
        ))
      }
    </div>
  );
}

export default SubmitError
