import React from 'react'

const Preloader = (props) => {
  return (
    <div className="d-flex justify-content-center align-items-stretch">
      <div
        className="spinner-border text-secondary m-5"
        style={{ width: '4rem', height: '4rem' }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default Preloader
