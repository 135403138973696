import { handleActions } from 'redux-actions'
import produce from 'immer'
import { mutate as arrayMoveMutate } from 'array-move'

import {
  getTrainingTreeRequest,
  updateTrainingAction,
  activateTrainingAction,
  showFormModalAction,
  showCopyModalAction,
  showDeleteModalAction,
  showActivateTrainingModalAction,
  copyTrainingAction,
  hideModalAction,
  clearModalAction,
  createCategoryAction,
  updateCategoryAction,
  copyCategoryAction,
  deleteCategoryAction,
  moveCategoryAction,
  activateSnippetAction,
  activateCategoryAction,
  createSnippetAction,
  updateSnippetAction,
  copySnippetAction,
  deleteSnippetAction,
  moveSnippetAction,
  createQuestionAction,
  updateQuestionAction,
  deleteQuestionAction,
  moveQuestionAction,
  moveAnswerAction
} from './actions'

const initialState = {
  isLoading: true,
  formAction: null,
  formEntry: null,
  formEntryId: null,
  formModalShow: false,
  formOptions: {},
  activeSnippetId: null,
  activeCategoryId: null,
  training: {},
  entities: {}
};

const combineModalAction = (state, payload) => {
  const { id = null, entry, action, options = {} } = payload;

  return {
    ...state,
    formAction: action,
    formEntry: entry,
    formEntryId: id,
    formOptions: options,
    formModalShow: true
  };
};

const selectCategory = (categoriesIds, categories) => {
  let [activeCategoryId, activeSnippetId] = [null, null];

  if (categoriesIds.length) {
    activeCategoryId = categoriesIds[0];

    if (categories[activeCategoryId].snippets.length) {
      activeSnippetId = categories[activeCategoryId].snippets[0];
    }
  }

  return [activeCategoryId, activeSnippetId];
};

export default handleActions({
  [ getTrainingTreeRequest ]: (state, { payload }) => {
    const {
      entities: {
        categories = {},
        snippets = {},
        questions = {},
        answers = {}
      },
      result: training
    } = payload;

    const [activeCategoryId, activeSnippetId] = selectCategory(training.categories, categories);

    return ({
      ...state,
      training,
      categories,
      snippets,
      questions,
      answers,
      activeCategoryId,
      activeSnippetId,
      isLoading: false
    });
  },
  [ showFormModalAction ]: (state, { payload }) => (
    combineModalAction(state, { ... payload, action: 'form' })
  ),
  [ showCopyModalAction ]: (state, { payload }) => (
    combineModalAction(state, { ... payload, action: 'copy' })
  ),
  [ showDeleteModalAction ]: (state, { payload }) => (
    combineModalAction(state, { ... payload, action: 'delete' })
  ),
  [ showActivateTrainingModalAction ]: (state, { payload }) => (
    combineModalAction(state, { ... payload, action: 'activate_training' })
  ),
  [ hideModalAction ]: (state, { payload }) => ({
    ...state,
    formModalShow: false
  }),
  [ activateSnippetAction ]: (state, { payload }) => ({
    ...state,
    activeSnippetId: payload
  }),
  [ activateCategoryAction ]: (state, { payload }) => ({
    ...state,
    activeCategoryId: payload
  }),
  [ clearModalAction ]: (state, { payload }) => ({
    ...state,
    formAction: null,
    formEntry: null,
    formEntryId: null,
    formOptions: {}
  }),
  [ updateTrainingAction ]: (state, { payload: training }) => {
    return produce(state, draftState => {
      const updateTraining = () => {
        Object.assign(draftState.training, training);
      }

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      updateTraining();
      hideModal();
    });
  },
  [ activateTrainingAction ]: (state, { payload: training }) => {
    const { status, status_changed_at } = training;

    return produce(state, draftState => {
      const activateCategory = () => {
        draftState.training.status = status;
        draftState.training.status_changed_at = status_changed_at;
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      activateCategory();
      hideModal();
    });
  },
  [ copyTrainingAction ]: (state) => {
    return produce(state, draftState => {
      const hideModal = () => {
        draftState.formModalShow = false;
      };

      hideModal();
    });
  },
  [ createCategoryAction ]: (state, { payload: category }) => {
    const { id: categoryId } = category;

    return produce(state, draftState => {
      const createCategory = () => {
        draftState.categories[categoryId] = { ...category, snippets: [] };
        draftState.training.categories.push(categoryId);
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      createCategory();
      hideModal();
    });
  },
  [ updateCategoryAction ]: (state, { payload: category }) => {
    const { id: categoryId } = category;

    return produce(state, draftState => {
      const updateCategory = () => {
        Object.assign(draftState.categories[categoryId], category);
      }

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      updateCategory();
      hideModal();
    });
  },
  [ copyCategoryAction ]: (state, { payload }) => {
    const {
      entities: {
        snippets = {},
        questions = {},
        answers = {}
      },
      result: category
    } = payload;

    const { id: categoryId, position } = category;

    return produce(state, draftState => {
      const copyCategory = () => {
        draftState.categories[categoryId] = category;
        draftState.training.categories.splice(position, 0, categoryId);
      };

      const copySnippets = () => {
        Object.assign(draftState.snippets, snippets);
      };

      const copyQuestions = () => {
        Object.assign(draftState.questions, questions);
      };

      const copyAnswers = () => {
        Object.assign(draftState.answers, answers);
      };

      const activateCategory = () => {
        draftState.activeCategoryId = categoryId;
      };

      const activateSnippet = () => {
        if (category.snippets.length) {
          draftState.activeSnippetId = category.snippets[0];
        }
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      copyCategory();
      copySnippets();
      copyQuestions();
      copyAnswers();
      activateCategory();
      activateSnippet();
      hideModal();
    });
  },
  [ deleteCategoryAction ]: (state, { payload: { id: categoryId }}) => {
    return produce(state, draftState => {
      const { training: { categories: categoriesIds }, categories } = draftState;

      const deleteCategory = () => {
        categoriesIds.splice(categoriesIds.indexOf(categoryId), 1);
      };

      const updateActive = () => {
        const [activeCategoryId, activeSnippetId] = selectCategory(categoriesIds, categories);

        draftState.activeCategoryId = activeCategoryId;
        draftState.activeSnippetId = activeSnippetId;
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      deleteCategory();
      updateActive();
      hideModal();
    });
  },
  [ moveCategoryAction ]: (state, { payload: { categoryId, from, to }}) => {
    return produce(state, draftState => {
      arrayMoveMutate(draftState.training.categories, from, to);
    });
  },
  [ createSnippetAction ]: (state, { payload: snippet }) => {
    const { id: snippetId, category_id: categoryId } = snippet;

    return produce(state, draftState => {
      const createSnippet = () => {
        draftState.snippets[snippetId] = { ...snippet, questions: [] };
        draftState.categories[categoryId].snippets.push(snippetId);
      };

      const updateActive = () => {
        draftState.activeCategoryId = categoryId;
        draftState.activeSnippetId = snippetId;
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      createSnippet();
      updateActive();
      hideModal();
    });
  },
  [ updateSnippetAction ]: (state, { payload: { snippet, entity: { category_id: previousCategoryId } } }) => {
    const { id: snippetId, category_id: currentCategoryId } = snippet;

    return produce(state, draftState => {
      const updateSnippet = () => {
        Object.assign(draftState.snippets[snippetId], snippet);
      };

      const updateCategoriesTree = () => {
        if (currentCategoryId != previousCategoryId) {
          const currentCategorySnippets = draftState.categories[currentCategoryId].snippets;
          const previousCategorySnippets = draftState.categories[previousCategoryId].snippets;
          const previousCategorySnippetIndex = previousCategorySnippets.indexOf(snippetId);

          currentCategorySnippets.push(...previousCategorySnippets.splice(previousCategorySnippetIndex, 1));
        }
      };

      const updateActive = () => {
        draftState.activeCategoryId = currentCategoryId;
        draftState.activeSnippetId = snippetId;
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      updateSnippet();
      updateCategoriesTree();
      updateActive();
      hideModal();
    });
  },
  [ copySnippetAction ]: (state, { payload }) => {
    const {
      entities: {
        questions = {},
        answers = {}
      },
      result: snippet
    } = payload;

    const { id: snippetId, category_id: categoryId, position } = snippet;

    return produce(state, draftState => {
      const copySnippet = () => {
        draftState.snippets[snippetId] = snippet;
        draftState.categories[categoryId].snippets.splice(position, 0, snippetId);
      };

      const copyQuestions = () => {
        Object.assign(draftState.questions, questions);
      };

      const copyAnswers = () => {
        Object.assign(draftState.answers, answers);
      };

      const updateActive = () => {
        draftState.activeCategoryId = categoryId;
        draftState.activeSnippetId = snippetId;
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      copySnippet();
      copyQuestions();
      copyAnswers();
      updateActive();
      hideModal();
    });
  },
  [ deleteSnippetAction ]: (state, { payload: { id: snippetId, category_id: categoryId }}) => {
    return produce(state, draftState => {
      const  { snippets } = draftState.categories[categoryId];

      const deleteSnippet = () => {
        snippets.splice(snippets.indexOf(snippetId), 1);
      }

      const updateActive = () => {
        draftState.activeSnippetId = snippets.length ? snippets[0] : null;
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      deleteSnippet();
      updateActive();
      hideModal();
    });
  },
  [ moveSnippetAction ]: (state, { payload: { categoryId, snippetId, from, to }}) => {
    return produce(state, draftState => {
      arrayMoveMutate(draftState.categories[categoryId].snippets, from, to);
    });
  },
  [ createQuestionAction ]: (state, { payload }) => {
    const {
      entities: {
        answers = {}
      },
      result: question
    } = payload;

    const { id: questionId, snippet_id: snippetId } = question;

    return produce(state, draftState => {
      const createQuestion = () => {
        draftState.questions[questionId] = question;
        draftState.snippets[snippetId].questions.push(questionId);
      };

      const updateAnswers = () => {
        Object.assign(draftState.answers, answers);
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      createQuestion();
      updateAnswers();
      hideModal();
    });
  },
  [ updateQuestionAction ]: (state, { payload }) => {
    const {
      entities: {
        answers = {}
      },
      result: question
    } = payload;

    const { id: questionId } = question;

    return produce(state, draftState => {
      const updateQuestion = () => {
        Object.assign(draftState.questions[questionId], question);
      };

      const updateAnswers = () => {
        Object.assign(draftState.answers, answers);
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      updateQuestion();
      updateAnswers();
      hideModal();
    });
  },
  [ deleteQuestionAction ]: (state, { payload: { id: questionId, snippet_id: snippetId }}) => {
    return produce(state, draftState => {
      const { questions } = draftState.snippets[snippetId];

      const deleteQuestion = () => {
        questions.splice(questions.indexOf(questionId), 1);
      }

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      deleteQuestion();
      hideModal();
    });
  },
  [ moveQuestionAction ]: (state, { payload: { snippetId, questionId, from, to }}) => {
    return produce(state, draftState => {
      arrayMoveMutate(draftState.snippets[snippetId].questions, from, to);
    });
  },
  [ moveAnswerAction ]: (state, { payload: { snippetId, questionId, answerId, from, to }}) => {
    return produce(state, draftState => {
      arrayMoveMutate(draftState.questions[questionId].answers, from, to);
    });
  }
}, initialState);
