import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import PatternFill from 'highcharts/modules/pattern-fill'

HighchartsMore(Highcharts)
PatternFill(Highcharts)

import {
  ZONES,
  DEFAULT_OPTIONS,
  CENTRAL_CHART_OPTIONS,
  FULL_CHART_OPTIONS,
} from 'src/charts/cycle/constants/amsler'

class Base {
  constructor(selector, params) {
    this.selector = selector;
    this.params = params;

    this.init();
  }

  init() {
    let promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.instance = Highcharts.chart(this.selector, this.chartOptions(), () => resolve(true));
      }, 0)
    });

    return promise;
  }

  chartOptions() {
    const self = this;

    const options = {
      chart: {
        events: {
          load(e) {
            return self.load(this);
          }
        }
      }
    };

    return $.extend(true, {}, options, this.typeOptions(), this.defaultOptions(), this.chartSize());
  }

  defaultOptions() {
    return $.extend(true, {}, DEFAULT_OPTIONS);
  }

  typeOptions() {
    switch (this.type) {
      case 'central':
      case 'compare':
        return CENTRAL_CHART_OPTIONS;
      case 'full':
        return FULL_CHART_OPTIONS;
    }
  }

  modifySeriesObject(object) {
    $.extend(object, ZONES[object.zone]);
  }

  load(chart) {
    this.applySeries(chart);
    this.fixHeight(chart);
  }

  applySeries(chart) {
    const self = this;

    this.series.forEach((object, index) => {
      self.modifySeriesObject(object);

      chart.addSeries(object, false);
    });

    chart.redraw();
  }

  fixHeight(chart) {
    if (chart.legend.options.enabled) {
      const newHeight = chart.chartHeight + chart.legend.legendHeight - 10;

      chart.setSize(undefined, newHeight, false);

      chart.redraw();
    }
  }

  get payload() {
    return this.params.payload;
  }

  get series() {
    return this.payload.series;
  }

  get type() {
    return this.params.type;
  }

  get dimensions() {
    return this.params.dimensions;
  }
}

export default Base
