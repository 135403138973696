import React from 'react'
import { useSelector } from 'react-redux'

import * as selectors from 'src/react/store/TrainingWizard/selectors'

const Ability = ({ children, context }) => {
  const training = useSelector(state => selectors.training(state));

  const editableTrainingContext = () => {
    return training.status == 'draft' ? children : null;
  }

  const activateableTrainingContext = () => {
    return ['draft', 'inactive'].includes(training.status) ? children : null;
  }

  switch (context) {
    case 'editable_training':
      return editableTrainingContext();
    case 'activateable_training':
      return activateableTrainingContext();
    default:
      return null;
  }
}

export default Ability
