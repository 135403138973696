import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import classnames from 'classnames'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { activateSnippetAction } from 'src/react/store/TrainingWizard/actions'

const CategorySnippet = ({ dragProvided, snippetId }) => {
  const dispatch = useDispatch();
  const snippet = useSelector(state => selectors.getSnippetById(state, snippetId));
  const activeSnippetId = useSelector(state => selectors.activeSnippetId(state));

  const categorySnippetStyles = classnames('list-group-item list-group-item-action', { 'active': snippetId == activeSnippetId });

  return (
    <div
      ref={dragProvided.innerRef}
      {...dragProvided.draggableProps}
      {...dragProvided.dragHandleProps}
      className={categorySnippetStyles}
      onClick={
        () => dispatch(activateSnippetAction(snippetId))
      }
    >
        {snippet.name}
    </div>
  );
}

export default CategorySnippet
