import { Controller } from 'stimulus'
import $ from 'jquery'
import copyToClipboard from 'src/utils/copyToClipboard'

export default class extends Controller {
  copy() {
    const { copyText, container } = this.element.dataset;

    const copyCallback = () => {
      $(this.element).
        tooltip({ title: 'Copied!', trigger: 'manual' }).
        tooltip('show');

      setTimeout((() => {
        $(this.element).tooltip('dispose');
      }), 1300);
    }

    copyToClipboard(copyText, copyCallback, { container: container });
  }
}
