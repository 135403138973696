import { Controller } from 'stimulus'
import $ from 'jquery'
import 'selectize'

export default class extends Controller {

  connect() {
    const self = this;

    $(this.element).selectize({
      valueField: 'code',
      labelField: 'name',
      searchField: ['name'],
      create: false,
      minChars: 2,
      plugins: ['remove_button'],
      disabledField: 'disabled',
      render: {
        option: function(item, escape) {
          item.disabled = item.level === 1;
          return '<div class="' + 'dropdown-level-' + item.level +
            '"><span>' + escape(item.name) + '</span></div>';
        }
      },
      load: function(query, callback) {
        this.clearOptions();
        self.loadRaces(query, callback);
      },
      score: () => () => 1
    });
  }

  loadRaces(query, callback) {
    if (!query.length) return callback();

    if (query.length > 1) {
      this.fetchRaces({
        search: query,
        success: (response) => callback(response.data),
        error: () => callback()
      })
    }
  }

  fetchRaces(options) {
    const dataOptions = {};
    dataOptions.search = options.search;

    $.ajax({
      url: this.url(),
      dataType: 'json',
      data: dataOptions,
      error: options.error,
      success: options.success
    })
  }

  url() {
    return this.element.dataset.url;
  }
}
