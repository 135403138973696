import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const element = this.element;
    const options = this.data;
    const parentNode = element.parentNode;

    this.appendModal(parentNode, this.modalOptions(options))
  }

  modalOptions(options) {
    return {
      elementId: options.get('elementId'),
      title: options.get('title'),
      content: options.get('content')
    }
  }

  appendModal(baseElement, modalOptions) {
    const modalNode = document.createElement('div');
    modalNode.innerHTML = this.modalTemplate(modalOptions);

    baseElement.appendChild(modalNode)
  }

  modalTemplate(options) {
    const { elementId, title, content } = options;
    return `
      <div class="modal fade" id="${elementId}" tabindex="-1" role="dialog" aria-labelledby="${elementId}Title" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="${elementId}LongTitle">${ title }</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              ${ content }
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
       </div> 
    `;
  }
}
