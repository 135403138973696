import { Controller } from 'stimulus'
//import Factory from 'src/charts/summary_report/factory'
import moment from 'moment'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    Highcharts.Tick.prototype.drillable = function() {};

    this.factories = [...this.targets].map((target, index) => {
      let factory = new Factory(target, target.dataset);

      return factory;
    });
  }

  sequentiallyFetchResults(factories) {
    let promise = Promise.resolve();

    factories.forEach((factory) => {
      promise = promise.
      then(result => factory.fetchRootResults({ start_date: this.startDate, end_date: this.endDate })).
      then(result => factory.hideLoading()).
      catch(jqXHR => factory.handleLoadingErrors(jqXHR));
    });
  }

  showLoading(factories) {
    factories.forEach((factory) => factory.showLoading());
  }

  get targets() {
    return document.querySelectorAll('.targets');
  }
}
