import Base from 'src/components/summary/grids/base'
import { DEFAULT_OPTIONS } from 'src/components/summary/constants/defaults'
import { WEIGHT } from 'src/components/summary/constants/cardiology/weight'
import moment from 'moment'

class Weight extends Base {
  defaultParams() {
    return $.extend(true, {}, DEFAULT_OPTIONS, WEIGHT);
  }

  onDrillupStart() {
    this.level -= 1;
  }

  tooltipFormatter(context, tooltip) {
    return this.tooltipHeader(this.tooltipLabel(context.point)) +
           this.tooltipBody(context.point, this.tooltipResult(context.point));
  }

  tooltipLabel(point) {
    if (point.by_date) return moment(point.by_date).format('D MMM Y');

    return `${moment(point.start_date).format('D MMM Y')} - ${moment(point.end_date).format('D MMM Y')}`;
  }

  tooltipResult(point) {
    return point.by_date ? point.y : `${point.low} - ${point.high}`;
  }

  get options() {
    let { weight_unit: unit } = this.storage.params;

    return {
      unit,
      category: 'cardiology',
      widget: 'weight'
    };
  }
}

export default Weight
