import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    if (data.location) {
      window.location = data.location;
    }

    if (data.archive_form) {
      $('#archive-modal .modal-content').
      replaceWith(data.archive_form);
    }

    if (data.archive_modal) {
      $('body').
        findOrAppend('#archive-modal', '<div id="archive-modal" />').
        html(data.archive_modal).
        find('.modal').
        modal('show');
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;

    if (data.archive_form) {
      $('#archive-modal .modal-content').
      replaceWith(data.archive_form);
    }
  }
}

