import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { hideModalAction, clearModalAction } from 'src/react/store/TrainingWizard/actions'

import CategoryModal from './Modals/CategoryModal'
import SnippetModal from './Modals/SnippetModal'
import QuestionModal from './Modals/QuestionModal'
import TrainingModal from './Modals/TrainingModal'
import ActivateTrainingModal from './Modals/ActivateTrainingModal'
import CopyModal from './Modals/CopyModal'
import DeleteModal from './Modals/DeleteModal'

const ModalFactory = (props) => {
  const dispatch = useDispatch();

  const formAction = useSelector(state => selectors.formAction(state));
  const formEntry = useSelector(state => selectors.formEntry(state));
  const formEntryId = useSelector(state => selectors.formEntryId(state));
  const formOptions = useSelector(state => selectors.formOptions(state));
  const formModalShow = useSelector(state => selectors.formModalShow(state));

  const options = {
    formAction,
    formEntry,
    formEntryId,
    formOptions,
    modal: {
      show: formModalShow,
      onHide: () => dispatch(hideModalAction()),
      onExited: () => dispatch(clearModalAction())
    }
  };

  if (formAction == 'activate_training') {
    return <ActivateTrainingModal {...options} />;
  }

  if (formAction == 'copy') {
    return <CopyModal {...options} />;
  }

  if (formAction == 'delete') {
    return <DeleteModal {...options} />;
  }

  if (formAction == 'form') {
    switch (formEntry) {
      case 'category':
        return <CategoryModal {...options} />;
      case 'snippet':
        return <SnippetModal {...options} />;
      case 'question':
        return <QuestionModal {...options} />;
      case 'training':
        return <TrainingModal {...options} />;
      default:
        return null;
    }
  }

  return null;
}

export default ModalFactory
