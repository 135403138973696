import Base from 'src/charts/cycle/amsler/base'

const CENTRAL_CHART_SIZE = {
  chart: {
    height: 350,
    width: 350
  }
};

const FULL_CHART_SIZE = {
  chart: {
    height: 440,
    width: 440
  }
};

class Cycle extends Base {
  chartSize() {
    switch (this.type) {
      case 'central':
      case 'compare':
        return CENTRAL_CHART_SIZE;
      case 'full':
        return FULL_CHART_SIZE;
    }
  }
}

export default Cycle
