import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.stickyYOffset = this.element.offsetTop;
  }

  onScroll() {
    this.stickyDate();
  }

  stickyDate() {
    if (window.pageYOffset >= this.stickyYOffset) {
      this.element.classList.add('sticky');
    } else {
      this.element.classList.remove('sticky');
    }
  }
}
