import {
  moveCategory,
  moveSnippet,
  moveQuestion,
  moveAnswer
} from 'src/react/store/TrainingWizard/actions'

const extractParams = (string) => string.split('-');

const moveEntity = (result) => (
  async dispatch => {
    if (!result.destination) return;

    const { source: { index: from }, destination: { index: to } } = result;
    const [ entity, ... rest ] = extractParams(result.draggableId);

    if (from == to) return;

    const moveCategoryContext = () => {
      const [ categoryId ] = rest;

      dispatch(moveCategory({ categoryId, from, to }));
    };

    const moveSnippetContext = () => {
      const [ categoryId, snippetId ] = rest;

      dispatch(moveSnippet({ categoryId, snippetId, from, to }));
    };

    const moveQuestionContext = () => {
      const [ snippetId, questionId ] = rest;

      dispatch(moveQuestion({ snippetId, questionId, from, to }));
    };

    const moveAnswerContext = () => {
      const [ snippetId, questionId, answerId ] = rest;

      dispatch(moveAnswer({ snippetId, questionId, answerId, from, to }));
    };

    const factory = {
      category: moveCategoryContext,
      snippet: moveSnippetContext,
      question: moveQuestionContext,
      answer: moveAnswerContext
    };

    return factory[entity]();
  }
);


export { moveEntity }
