class ExportButton {
  constructor(selector, storage) {
    this.selector = selector;
    this.storage = storage;

    this.init();
  }

  init() {
    this.buildExport();
    this.updateExportURL();
  }

  update() {
    this.updateExportURL();
  }

  buildExport() {
    this.selector.innerHTML = `<a class="btn btn-block btn-secondary" target="_blank" data-export-button>
      Export To PDF
    </a>`;
  }

  updateExportURL() {
    this.exportButton.href = this.exportURL;
  }

  get exportURL() {
    const { start_date, end_date, step, ... rest } = this.storage.params;

    let options = {
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD')
    }

    return window.metrics.export_pdf_path + '?' + $.param({ ... options, ... rest });
  }

  get exportButton() {
    return this.selector.querySelector('[data-export-button]');
  }
}

export default ExportButton
