import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.hideUnreadButton();
    this.hideNotificationMarker();
    this.markAsRead();
  }

  hideUnreadButton() {
    if (this.unreadNumber == 0) {
      const btn = document.getElementsByClassName('make-all-read-btn')[0];

      if (btn) {
        btn.setAttribute("style", "display: none;");
      }
    }
  }

  hideNotificationMarker() {
    if (this.unreadNumber == 0) {
      const notificationMarker = document.getElementsByClassName('web-notifications')[0].getElementsByClassName('indicator')[0];

      if (notificationMarker) {
        notificationMarker.setAttribute("style", "display: none;");
      }
    }
  }

  markAsRead() {
    setTimeout(() => {
      const unreads = document.getElementsByClassName('unread');

      [...unreads].forEach((el) => {
        el.classList.remove('unread');
      });
    }, 2000);
  }


  get unreadNumber() {
    return this.element.dataset.unreadNumber || 0;
  }
}
