import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.toggleTrainingsFilter();
    this.setListeners();
  }

  setListeners() {
    $(this.selector).on('change', () => this.toggleTrainingsFilter());
  }

  toggleTrainingsFilter() {
    this.isFilterEnabled ? this.enableProgressSelector() : this.disableProgressSelector();
  }

  enableProgressSelector() {
    if (this.progressSelector.disabled) {
      this.progressSelector.value = 'assigned';
    }
    this.progressSelector.removeAttribute('disabled');
    this.progressSelector.style.cursor = 'pointer';
  }

  disableProgressSelector() {
    this.progressSelector.setAttribute('disabled', true);
    this.progressSelector.value = '';
    this.progressSelector.style.cursor = 'not-allowed';
  }

  get isFilterEnabled() {
    return !!this.selector.value;
  }

  get progressSelector() {
    return this.element.querySelector('[data-trainings-progress-selector]');
  }

  get selector() {
    return this.element.querySelector('[data-trainings-selector]');
  }

  get preloadedProgressStatus() {
    const { progressStatus } = this.element.dataset;
    return progressStatus;
  }
}
