import React from 'react'

const Errors = ({ errors }) => {
  return (
    <div className="no-records-block text-danger">
      <div className="icon">
        <span className="icon mdi mdi-close-octagon"></span>
      </div>
      <div className="message">
        Exception: {errors}
      </div>
    </div>
  );
}

export default Errors
