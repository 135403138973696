import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    $('.event-activities-context').
      replaceWith(data.content);

    $('.flash-alerts').
      html(data.flash);

    $('#comment-modal .modal').
      modal('hide');
  }

  onError(event) {
    let [data, status, xhr] = event.detail;

    $('#comment-modal .modal-content').
      replaceWith(data.form);

    $('.modal textarea[autofocus]').
      focus();
  }
}
