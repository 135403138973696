import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import * as selectors from 'src/react/store/Timesheets/selectors'
import { switchActivityAction, prepareTreeTimeRecordAction, showDeleteModalAction } from 'src/react/store/Timesheets/actions'
import { generateDraftTimeRecord } from 'src/react/store/Timesheets/forms'

import { Accordion } from 'react-bootstrap'

import ActivityPatient from 'src/react/components/Timesheets/ActivityPatient'

const SummaryLine = ({ activity: { id: activityId, patient_id: patientId, day, active }, isLockedDay }) => {
  const dispatch = useDispatch();

  const patient = useSelector(state => selectors.getPatientById(state, patientId));

  const [totalCount, totalAmount, categories] =
    useSelector(state => selectors.getStatisticsByActivity(state, activityId));

  const collapserStyles = classnames('btn btn-icon', { 'collapsed': !active });
  const activityCategories = categories.map(category => window.timesheets.categories[category]).join(', ');

  const switchActivity = () => {
    dispatch(switchActivityAction({ activityId, active: !active }));
  };

  const generateTimeRecord = () => {
    dispatch(prepareTreeTimeRecordAction({
      activityId,
      timeRecord: generateDraftTimeRecord(day, patientId)
    }));
  };

  return (
    <div className="timesheet-line">
      <Accordion.Toggle
        as="div"
        variant="link"
        eventKey={true}
        onClick={
          () => switchActivity()
        }
      >
        <div className="timesheet-line-collapser">
          <div className={collapserStyles}>
            <span className="mdi mdi-chevron-up"></span>
          </div>
        </div>
      </Accordion.Toggle>

      <div className="timesheet-line-patient">
        <ActivityPatient patient={patient} />
      </div>

      <div className="timesheet-line-activity">
        {activityCategories}
      </div>

      <div className="timesheet-line-reconds">
        {totalCount}
      </div>

      <div className="timesheet-line-time">
        {totalAmount}m
      </div>

      <div className="timesheet-line-actions">
        {
          !isLockedDay && (
            <>
              <a
                className="btn btn-icon"
                onClick={
                  () => generateTimeRecord()
                }
              >
                <span className="mdi mdi-plus-box"></span>
              </a>
              <a
                className="btn btn-icon"
                onClick={
                  () => dispatch(showDeleteModalAction({ entry: 'activity', id: activityId }))
                }
              >
                <span className="mdi mdi-delete"></span>
              </a>
            </>
          )
        }
      </div>
    </div>
  );
}

export default SummaryLine
