class Settings {
  constructor(selector, storage) {
    this.selector = selector;
    this.storage = storage;

    this.init();
  }

  init() {
    this.buildNavigate();
    this.buildCallbacks();
    this.applyRootSettings();
  }

  update() {}

  buildNavigate() {
    this.selector.innerHTML = `
      <div class="dropdown settings">
        <button
          class="btn btn-secondary dropdown-toggle"
          data-toggle="dropdown"
          id="charts-settings"
          type="button"
          aria-haspopup=""
        >
          <span class="mdi mdi-tune-variant mr-1"></span>
          Charts Settings
        </button>

        <div aria-labelledby="charts-settings" class="dropdown-menu dropdown-menu-right" data-dropdown-menu>
          <div class="settings-widget">
            <div class="temperature-units">
              <div class="caption">Temperature Units</div>
              <div class="d-flex justify-content-start">
                <div class="btn-group btn-group-toggle btn-group-settings">
                  <button class="btn btn-sm" data-unit="fahrenheit">°F</button>
                  <button class="btn btn-sm" data-unit="celsius">°C</button>
                </div>
              </div>
            </div>

            <div class="weight-units">
              <div class="caption">Weight Units</div>
              <div class="d-flex justify-content-start">
                <div class="btn-group btn-group-toggle btn-group-settings">
                  <button class="btn btn-sm" data-unit="lbs">Lbs</button>
                  <button class="btn btn-sm" data-unit="kg">Kg</button>
                </div>
              </div>
            </div>

            <div class="steps">
              <div class="caption">Step Size</div>
              <div class="d-flex justify-content-start">
                <div class="btn-group btn-group-toggle btn-group-settings">
                  <button class="btn btn-sm" data-step="1" type="button">1 day</button>
                  <button class="btn btn-sm" data-step="3" type="button">3 days</button>
                  <button class="btn btn-sm" data-step="7" type="button">7 days</button>
                  <button class="btn btn-sm" data-step="14" type="button">14 days</button>
                  <button class="btn btn-sm" data-step="30" type="button">1 month</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>`;
  }

  buildCallbacks() {
    this.dropdownMenu.addEventListener('click', (e) => e.stopPropagation());

    this.stepButtons.forEach(el => el.addEventListener('click', (e) => this.changeStep(e)));

    this.unitButtons('weight').forEach(el => el.addEventListener('click', (e) => this.changeUnit(e, 'weight')));
    this.unitButtons('temperature').forEach(el => el.addEventListener('click', (e) => this.changeUnit(e, 'temperature')));
  }

  applyRootSettings() {
    const {
      step,
      weight_unit,
      temperature_unit
    } = this.storage.params;

    this.applyActiveStepButton(step);

    this.applyActiveUnitButton(weight_unit, 'weight');
    this.applyActiveUnitButton(temperature_unit, 'temperature');
  }

  changeStep(e) {
    const { step } = e.target.dataset;

    this.applyActiveStepButton(step);

    this.storage.changeStep(step);
  }

  changeUnit(e, type) {
    const { unit } = e.target.dataset;

    this.applyActiveUnitButton(unit, type);

    this.storage.changeUnit(unit, type);
  }

  applyActiveStepButton(step) {
    this.stepButtons.forEach((element) => {
      element.classList.toggle('active', element.dataset.step == step);
    });
  }

  applyActiveUnitButton(unit, type) {
    this.unitButtons(type).forEach((element) => {
      element.classList.toggle('active', element.dataset.unit == unit);
    });
  }

  unitButtons(type) {
    return this.selector.querySelectorAll(`.${type}-units [data-unit]`);
  }

  get stepButtons() {
    return this.selector.querySelectorAll('[data-step]');
  }

  get dropdownMenu() {
    return this.selector.querySelector('[data-dropdown-menu]');
  }
}

export default Settings
