import { Controller } from 'stimulus'
//import React from 'react'
//import ReactDOM from 'react-dom'
//import { Provider } from 'react-redux'
//
//import List from 'src/react/containers/Patients/List/hooks'
//
//import configureStore from 'src/react/store/configureStore';
//
//const store = configureStore();

export default class extends Controller {
  connect() {
    //ReactDOM.render(
    //  <Provider store={store}>
    //    <List />
    //  </Provider>,
    //  this.element.appendChild(document.createElement('div')),
    //)
  }
}
