import moment from 'moment'

import * as Actions from 'src/components/summary/actions'

const MONTHLY_RANGE = 30;

class Base {
  constructor(dataset) {
    // casted to patient timezone at server
    this.first_entry = dataset.firstEntry && this.byTimezone(dataset.firstEntry);
    this.last_entry = dataset.lastEntry && this.byTimezone(dataset.lastEntry);

    this.params = { ... this.latestRange, ... this.defaultParams };

    this.callbacks = {};
  }

  moveLatest() {
    this.updateParams(Actions.UPDATE_RANGE, this.latestRange);
  }

  moveFirstEntry() {
    this.updateParams(Actions.UPDATE_RANGE, this.firstEntryRange);
  }

  moveLastEntry() {
    this.updateParams(Actions.UPDATE_RANGE, this.lastEntryRange);
  }

  moveBackward() {
    this.updateParams(Actions.UPDATE_RANGE, this.backwardRange);
  }

  moveForward() {
    const range = this.forwardRange;

    if (range) this.updateParams(Actions.UPDATE_RANGE, range);
  }

  get backwardRange() {
    const start_date = this.params.start_date.clone().subtract(this.params.step, 'days');
    const end_date = this.params.end_date.clone().subtract(this.params.step, 'days');

    return { start_date, end_date };
  }

  get forwardRange() {
    const start_date = this.params.start_date.clone().add(this.params.step, 'days');
    const end_date = this.params.end_date.clone().add(this.params.step, 'days');

    return { start_date, end_date };
  }

  get lastEntryRange() {
    const start_date = this.last_entry.clone().subtract(MONTHLY_RANGE - 1, 'days');
    const end_date = this.last_entry.clone();

    return { start_date, end_date };
  }

  get firstEntryRange() {
    const start_date = this.first_entry.clone();
    const end_date = this.first_entry.clone().add(MONTHLY_RANGE - 1, 'days');

    return { start_date, end_date };
  }

  get latestRange() {
    const start_date = this.today.subtract(MONTHLY_RANGE - 1, 'days');
    const end_date = this.today;

    return { start_date, end_date };
  }

  updateRange(range) {
    this.updateParams(Actions.UPDATE_RANGE, range);
  }

  changeStep(step) {
    this.updateParams(Actions.UPDATE_STEP, { step });
  }

  updateParams(action, options) {
    this.params = { ... this.params, ... options };

    this.callbacks.onChange(action);
  }

  byTimezone(date) {
    return moment(date).utcOffset(window.metrics.tz_offset, true);
  }

  get unitsAliases() {
    return {};
  }

  get today() {
    return moment().utc().utcOffset(window.metrics.tz_offset);
  }
}

export default Base
