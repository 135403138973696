class Timeframe {
  constructor(selector, storage) {
    this.selector = selector;
    this.storage = storage;

    this.init();
  }

  init() {
    this.buildTimeframe();
  }

  update() {
    this.buildTimeframe();
  }

  buildTimeframe() {
    this.selector.innerHTML = this.timeframe;
  }

  get timeframe() {
    return `(${this.storage.params.start_date.format('MMM-DD-Y')} - ${this.storage.params.end_date.format('MMM-DD-Y')})`;
  }
}

export default Timeframe
