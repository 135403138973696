class DiseaseFormatter {
  constructor(element, params = {}) {
    this.element = element;
    this.params = params;
  }

  dotInsensitiveQuery(query) {
    return query.split('.').join('');
  }

  optionViewCallback() {
    const isGroupingDisabled = this.element.dataset.disableGroupByNode == true;

    return (item, escape) => {
      let desc = item.description ? ' ' + escape(item.description) : '';
      const classes = ['billable-code-option'];
      if (!isGroupingDisabled && item.node) {
        classes.push('disabled-select-option');
      }

      return '<div class="' + classes.join(' ') + '">'+ this.billingSignElement(item, isGroupingDisabled) +'&nbsp;<span>' + escape(this.injectPeriodToCode(item.code)) + desc + '</span></div>';
    };
  }

  itemViewCallback() {
    const isGroupingDisabled = this.element.dataset.disableGroupByNode == true;

    return (item, escape) => {
      return `<div type='button' data-toggle='tooltip' data-placement='top' title='${item.description}'>`+ this.billingSignElement(item, isGroupingDisabled) +'&nbsp;<span>' + escape(this.injectPeriodToCode(item.code)) + '</span></div>';
    };
  }

  billingSignElement(item, isGroupingDisabled) {
    const billableIcon = '<span class="billable-sign-circle"><span class="mdi mdi-currency-usd text-success"></span></span>';
    const notBillableIcon = '<span class="billable-sign-circle"><span class="mdi mdi-currency-usd-off text-secondary"></span></span>';
    if (!isGroupingDisabled && item.node) {
      return '';
    }

    return item.billable ? billableIcon : notBillableIcon;
  }

  injectPeriodToCode(code) {
    if (code.length <= 3 || code.indexOf('.') > -1) {
      return code
    }

    return [code.slice(0, 3), '.', code.slice(3)].join('');
  }

  formatResponseData(data, selectedItems = []) {
    if (selectedItems.length > 0) {
      data = this.cleanDuplicates(data, selectedItems);
    }

    data.forEach((el) => {
      el['code'] = this.injectPeriodToCode(el['code']);
    });

    return data;
  }

  cleanHiddenSelectOptions(options) {
    Array.from(options).forEach((el) => {
      el.value = el.value.replace('.', '');
    });
  }

  cleanDuplicates(data, selectedItems) {
    return data.filter(d => !selectedItems.includes(d.code.replace('.', '')))
  }
}

export default DiseaseFormatter
