import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const groupByField = this.element;

    this.setRange(groupByField.value);
    groupByField.addEventListener('change', (e) => {
      this.setRange(e.target.value);
    })
  }

  setRange(groupBy) {
    let exception;

    switch (groupBy) {
      case 'week':
        exception = ['week'];
        if (exception.includes(this.rangeField.value)) {
          this.rangeField.value = 'fortnight';
        }
        this.disableOptions(exception)
        break;
      case 'month':
        exception = ['week', 'last_week', 'fortnight'];
        if (exception.includes(this.rangeField.value)) {
          this.rangeField.value = 'month';
        }
        this.disableOptions(exception)
        break;
      default:
        this.disableOptions([])
        break;
    }
  }

  get rangeField() {
    return document.getElementById('q_range');
  }

  disableOptions(arr) {
    let options = this.rangeField.options;
    for (var i = 0; i < options.length; i++) {
      if (arr.includes(options[i].value)) {
        options[i].disabled = true
      } else {
        options[i].disabled = false
      }
    }
  }
}
