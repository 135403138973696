import { Controller } from 'stimulus'
import { createStore } from 'redux'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    if (this.recordsAvailable()) {
      this.initializeStore();
      this.provideState();
      this.addListeners();
    };
  }

  initializeStore() {
    const visibilityReducer = ((state = false, action) => {
      if (action.type === 'SET_VISIBILITY') {
        return action.visibility;
      } else {
        return state;
      }
    });

    const recordsReducer = ((state = [], action) => {
      switch (action.type) {
        case 'ADD_RECORDS':
          return [...state, ...action.ids];
        case 'REMOVE_RECORDS':
          return state.filter((id) => !action.ids.includes(id));
        case 'CLEANUP_RECORDS':
          return [];
        default:
          return state;
      }
    });

    const reducer = ((state = {}, action) => {
      return {
        records: recordsReducer(state.records, action),
        visibility: visibilityReducer(state.visibility, action)
      }
    });

    this.store = createStore(reducer, this.readLocalStorage());
    this.store.subscribe(() => this.provideState());
  }

  provideState() {
    const { visibility, records } = this.store.getState();

    this.visibility = visibility;

    // visibility of toggle
    this.compareVisibility.checked = visibility;

    // checkboxes states
    this.checkboxItems.forEach((el) => {
      el.checked = records.includes(el.dataset.id);
    });

    // check all if all selected on a page
    this.checkboxAll.checked = (this.checkboxItemsSize == this.checkboxItemsCheckedSize);

    // switch compare widget popup
    this.popupWidget.classList.toggle('d-none', !(records.length > 0));

    // switch check all checkbox visibility
    this.checkboxAllContext.classList.toggle('d-none', !visibility);

    // switch check all checkbox visibility
    this.checkboxItemsContext.forEach((el) => {
      el.classList.toggle('d-none', !visibility);
    });

    // set numbers of records to circle
    this.selectedCount.innerHTML = records.length;

    this.updateCompareURL(records);
  }

  addListeners() {
    this.checkboxItems.forEach(el => el.addEventListener('click', (e) => {
      const { checked, dataset: { id } } = e.target;

      if (this.hasOverflow(checked)) {
        e.preventDefault();

        this.showOverflowMessage();
        return;
      }

      this.store.dispatch({
        type: (checked ? 'ADD_RECORDS' : 'REMOVE_RECORDS'),
        ids: [id]
      });

      this.updateLocalStorage();
    }));

    this.checkboxAll.addEventListener('click', (e) => {
      const { checked } = e.target;

      if (this.hasOverflow(checked, this.checkboxItemsNotChecked.length)) {
        e.preventDefault();

        this.showOverflowMessage();
        return;
      }

      this.store.dispatch({
        type: (checked ? 'ADD_RECORDS' : 'REMOVE_RECORDS'),
        ids: [...(checked ? this.checkboxItemsNotChecked : this.checkboxItemsChecked)].map(el => el.dataset.id)
      });

      this.updateLocalStorage();
    });

    this.compareVisibility.addEventListener('change', (e) => {
      this.store.dispatch({ type: 'SET_VISIBILITY', visibility: e.target.checked });

      if (!e.target.checked) {
        this.store.dispatch({ type: 'CLEANUP_RECORDS' });
        this.updateLocalStorage();
      } else {
        this.presetDefaultTests()
      }
    });

    this.resetButton.addEventListener('click', (e) => {
      this.store.dispatch({ type: 'CLEANUP_RECORDS' });

      this.updateLocalStorage();
    });
  }

  presetDefaultTests() {
    $.ajax({
      method: 'GET',
      url: window.compare_preset_url,
      dataType: 'json',
      success: (data) => {
        const ids = data.ids.map(id => id.toString());

        this.store.dispatch({ type: 'ADD_RECORDS', ids });

        this.updateLocalStorage();
      }
    })
  }

  hasOverflow(checked, newLength = 1) {
    const { records } = this.store.getState();

    newLength = checked ? (records.length + newLength) : 0;

    return (newLength > this.maxItems && checked);
  }

  updateLocalStorage() {
    localStorage.setItem(this.storageKey, JSON.stringify(this.store.getState()));
  }

  readLocalStorage() {
    return JSON.parse(localStorage.getItem(this.storageKey)) || this.defaultState;
  }

  updateCompareURL(records) {
    this.applyButton.href = window.compare_url + '?ids=' + records.join(',');
  }

  showOverflowMessage() {
    $(document).find('#compareOverflow').modal('show');
  }

  recordsAvailable() {
    return !!this.checkboxAllContext;
  }

  get checkboxItems() {
    return this.element.querySelectorAll('[data-check-item]');
  }

  get checkboxItemsChecked() {
    return this.element.querySelectorAll('[data-check-item]:checked');
  }

  get checkboxItemsNotChecked() {
    return this.element.querySelectorAll('[data-check-item]:not(:checked)');
  }

  get checkboxItemsContext() {
    return this.element.querySelectorAll('[data-check-item-context]');
  }

  get checkboxAll() {
    return this.element.querySelector('[data-check-all]');
  }

  get checkboxAllContext() {
    return this.element.querySelector('[data-check-all-context]');
  }

  get compareVisibility() {
    return this.element.querySelector('[data-visibility]');
  }

  get selectedCount() {
    return this.element.querySelector('[data-selected]');
  }

  get applyButton() {
    return this.element.querySelector('[data-apply]');
  }

  get resetButton() {
    return this.element.querySelector('[data-reset]');
  }

  get popupWidget() {
    return this.element.querySelector('[data-popup]');
  }

  get checkboxItemsSize() {
    return this.checkboxItems.length;
  }

  get checkboxItemsCheckedSize() {
    return this.checkboxItemsChecked.length;
  }

  get storageKey() {
    return this.element.dataset.compareNamespace;
  }

  get maxItems() {
    return this.element.dataset.maxItems;
  }

  get defaultState() {
    return {
      records: [],
      visibility: false
    };
  }
}
