/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

const fonts = require.context('../fonts', true)
const fontPath = (name) => fonts(name, true)

require('@rails/ujs').start()
require("trix")
require("@rails/actiontext")

import 'styles/application'

import 'bootstrap'

// Load Stimulus controllers
import 'src/stimulus/controllers'
// import Dropzone from 'dropzone/dist/min/dropzone.min'

//import 'src/execute_immediatly'
//import 'src/hello_coffee'
//import { Store } from 'src/plugins'
//
//import test from 'src/export_default'
//import { a, b } from 'src/export_few'
//
//test()
//a()
//b()
//
//Store.run()
//
//console.log('Hello World from Webpacker')
