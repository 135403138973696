import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const element = this.element;
    const options = this.getOptions(this.data);

    this.appendProgressBar(element, this.calcBarOptions(options))
  }

  getOptions(options) {
    return {
      values: this.toArray(options.get('values')),
      colors: this.toArray(options.get('colors'))
    }
  }

  calcBarOptions(options) {
    let values = options.values.map(Number);
    let total = values.reduce((sum, val) => sum + val);
    let percentages = values.map((v) => this.calcPercent(v, total));
    let colors = options.colors.map((c) => c.replace(/ /g, ''));

    return { values: percentages, colors: colors };
  }

  appendProgressBar(baseElement, options)  {
    const modalNode = document.createElement('div');
    modalNode.innerHTML = this.progressBarsTemplate(options.values, options.colors);

    baseElement.appendChild(modalNode)
  }

  progressBarsTemplate(values, colors) {
    let barsTemplates = '';
    values.forEach((value, index) => barsTemplates += this.progressBarTemplate(value, colors[index]));

    return `<div class="progress">${barsTemplates}</div>`;
  }

  progressBarTemplate(value, color) {
    return `
      <div 
        class="progress-bar" role="progressbar" style="width: ${value}%; background: ${color}" 
        aria-valuenow="${value}" aria-valuemin="0" aria-valuemax="100">
        ${value}%
      </div>`;
  }

  calcPercent(value, total) {
    if (total == 0) return 0;

    return Math.round((value / total) * 100)
  }

  toArray(strValue) {
    return strValue.slice(1, strValue.length - 1).replace(/"/g,'').split(',')
  }
}
