import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'src/react/store/Compares/selectors'

const Contrast = ({ tests }) => {
  const { 'ophthalmology:contrast:l': left, 'ophthalmology:contrast:r': right } = tests;

  const showAll = useSelector(state => selectors.showAll(state));
  const leftVisible = useSelector(state => selectors.leftVisible(state));
  const rightVisible = useSelector(state => selectors.rightVisible(state));

  if (!showAll) return null;

  return (
    <div className="result-item">
      <div className="title">
        <span>Contrast</span>
      </div>
      <div className="content">
        <div className="row">
          {
            leftVisible && (
              <div className="col">
                <div className="d-flex justify-content-between">
                  <span>
                    Log<small>10</small> (CS):
                  </span>
                  <span>
                    {left ? left.contrast_pelli_robson : 'N/A'}
                  </span>
                </div>
              </div>
            )
          }
          {
            rightVisible && (
              <div className="col">
                <div className="d-flex justify-content-between">
                  <span>
                    Log<small>10</small> (CS):
                  </span>
                  <span>
                    {right ? right.contrast_pelli_robson : 'N/A'}
                  </span>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Contrast;
