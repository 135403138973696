import React, { memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as selectors from 'src/react/store/Compares/selectors'
import swal from 'sweetalert'
import classnames from 'classnames'

import { transmitDocument } from 'src/react/store/Compares/actions'

import combineOptions from 'src/react/utils/Compares/combineOptions'

const TransmitLink = ({
  blacklist,
  showAll,
  leftVisible,
  rightVisible
}) => {
  const dispatch = useDispatch();

  const {
    patient_name: patientName
  } = window.compares;

  const transmitBusy = useSelector(state => selectors.transmitBusy(state));

  let options = combineOptions(blacklist);

  options.show_all = showAll;
  options.left_visible = leftVisible;
  options.right_visible = rightVisible;

  const confirm = () => {
    return swal({
      title: 'Send document to Athena',
      text: `Are you sure you want to send compare report pdf of ${patientName} to Athena? This action can not be undone.`,
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true
        },
        confirm: {
          text: 'Send'
        }
      }
    });
  };

  const transmit = () => {
    confirm().then((isConfirmed) => {
      if (isConfirmed) dispatch(transmitDocument(options));
    });
  }

  return (
    <a
      className={
        classnames('btn', 'btn-white', 'transmit-btn', 'text-nowrap', { readonly: transmitBusy })
      }
      onClick={
        () => transmit()
      }
    >
      Send to Athena
    </a>
  );
}

export default memo(TransmitLink)
