import React from 'react'
import { Form } from 'react-final-form'
import { FormControl } from 'react-bootstrap'

const SelectField = ({
  input,
  meta,
  options,
  placeholder = 'Select...',
  invalid = meta => meta.touched && meta.invalid,
  valid = () => false,
  ...rest
}) => (
  <FormControl
    {...input}
    {...rest}
    isInvalid={invalid(meta)}
    isValid={valid(meta)}
    as="select"
  >
    {
      placeholder && (
        <option className="is-placeholder" disabled value="">
          {placeholder}
       </option>
     )
   }
   {
      options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))
    }
  </FormControl>
);

export default SelectField
