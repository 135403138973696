import { Controller } from 'stimulus'
import ActiveCustomNudge from 'src/plugins/nudge_settings/activeCustomNudge'

export default class extends Controller {
  connect() {
    this.customSettingsView = new ActiveCustomNudge({
      elements: this.nudgeElements,
      defaultValues: this.defaultNudgeValues
    });

    this.toggleNudgeSettings();
    this.setListeners();
  }

  setListeners() {
    if (this.nudgeSwitcher){
      $(this.protocolsSelect).on('change', () => this.toggleNudgeSettings());
    }
  }

  toggleNudgeSettings() {
    if (!this.nudgeSwitcher) return;

    if (this.isProtocolsAvailable()) {
      this.customSettingsView.enableNudgeSettings();
    } else {
      this.customSettingsView.disableNudgeSettings();
    }
  }

  isProtocolsAvailable() {
    if (this.protocolsSelect.disabled) return false;

    return !!this.protocolsSelect.value
  }

  get nudgeElements () {
    return {
      nudgeSwitcher: this.nudgeSwitcher,
      timeElement: this.timeSelector,
      intervalElement: this.intervalSelector
    }
  }

  get nudgeSwitcher() {
    return this.element.querySelector('[data-nudge-switcher]');
  }

  get timeSelector() {
    return this.element.querySelector('[data-nudge-time]');
  }

  get intervalSelector() {
    return this.element.querySelector('[data-nudge-interval]');
  }

  get protocolsSelect() {
    return document.querySelector('[data-protocols-selector]');
  }

  get defaultNudgeValues() {
    const { defaultNudgeTime, defaultNudgeInterval } = this.element.dataset;
    return { time: defaultNudgeTime, interval: defaultNudgeInterval }
  }
}
