import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as selectors from 'src/react/store/Timesheets/selectors'

import Headline from './Headline'
import Timesheet from './Timesheet'
import Preloader from 'src/react/components/Timesheets/Preloader'

const Board = (props) => {
  const isLoading = useSelector(state => selectors.isLoading(state));

  return (
    <div className="card timesheet-list">
      <Headline />

      {
        isLoading ?
          <Preloader /> :
          <Timesheet />
      }
    </div>
  );
}

export default Board
