import isEmpty from 'validator/lib/isEmpty'
import isInt from 'validator/lib/isInt'

import { presence } from 'src/utils/formatters'
import { FORM_ERROR } from 'final-form'

const isBlank = (value) => isEmpty(value, { ignore_whitespace: true });
const isNotNumber = (value) => !isInt(value, { allow_leading_zeroes: false });

const isTooShort = (value, minLength) => value.length < minLength;
const isTooLong = (value, maxLength) => value.length > maxLength;

const isTooSmall = (value, minValue) => value < minValue;
const isTooBig = (value, maxValue) => value > maxValue;

const validateCellValue = (value, context) => {
  value = value.toString();

  switch (context) {
    case 'category':
      if (isBlank(value)) return 'Category can\'t be blank';

      break;
    case 'details':
      if (isBlank(value)) return 'Details can\'t be blank';
      if (isTooShort(value, 3)) return 'Details is too short (minimum is 3 characters)';
      if (isTooLong(value, 1000)) return 'Details is too long (maximum is 1000 characters)';

      break;
    case 'amount':
      if (isBlank(value)) return 'Time can\'t be blank';
      if (isNotNumber(value)) return 'Time is not integer number';
      if (isTooSmall(value, 1)) return 'Time must be greater than 0';
      if (isTooBig(value, 1440)) return 'Time must be less than or equal to 1440';

      break;
  }

  return null;
};

const castCellValue = (value, context) => {
  return context == 'amount' ? Number(value) : value;
};

const generateDraftTimeRecord = (day, patient_id) => {
  return {
    id: Date.now(),
    day,
    patient_id,
    details: '',
    category: 'patient_processing',
    amount: 5,
    draft: true,
    frozen: false,
    form: {
      cells: {
        details: {
          value: '',
          autoFocus: false
        }
      },
      invalidCells: ['details'],
      changedCells: ['category', 'amount']
    }
  }
};

const generateRootTimeRecord = (day) => {
  return {
    day,
    details: '',
    category: 'patient_processing',
    amount: 5,
    patient_id: null
  };
};

const timeRecordForm = (values) => {
  const form = new FormData();

  form.append('time_record[patient_id]', presence(values.patient_id));
  form.append('time_record[category]', presence(values.category));
  form.append('time_record[details]', presence(values.details));
  form.append('time_record[day]', presence(values.day));
  form.append('time_record[amount]', presence(values.amount));

  return form;
};

const verificationLock = (err) => {
  const { data, status, statusText } = err.response;

  if (data.verification_lock) {
    return [
      true, {
        minDate: data.verification_lock.min_date,
        maxDate: data.verification_lock.max_date
      }
    ];
  }

  return [false, null];
};

const recordNotFound = (err) => err.response.status == 404;

const formErrors = (err) => {
  const { data, status, statusText } = err.response;
  const { base, error, ... rest } = data;

  if ([422].includes(status)) {
    return {
      ... rest,
      [FORM_ERROR]: (base || error)
    };
  }

  return {
    [FORM_ERROR]: error
  };
};

export {
  validateCellValue,
  castCellValue,
  generateDraftTimeRecord,
  generateRootTimeRecord,
  timeRecordForm,
  verificationLock,
  recordNotFound,
  formErrors
}
