import React from 'react'

const STATUSES_DETAILS = {
  draft: {
    title: 'Draft',
    icon: 'mdi mdi-clock-outline text-warning'
  },
  active: {
    title: 'Active',
    icon: 'mdi mdi-check-circle-outline text-success'
  },
  inactive: {
    title: 'Inactive',
    icon: 'mdi mdi-minus-circle-outline text-muted'
  }
};

const Status = ({ status }) => {
  const details = STATUSES_DETAILS[status];

  return (
    <div>
      <div className={ details.icon } />
      { details.title }
    </div>
  );
};

export default Status
