import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'src/react/store/Compares/selectors'

import useDidUpdateEffect from 'src/react/hooks/useDidUpdateEffect'

import Controls from './Controls'
import ScrollableCards from './ScrollableCards'

const Page = (props) => {
  const flash = useSelector(state => selectors.flash(state));

  useDidUpdateEffect(() => {
    document.querySelector('.flash-alerts').innerHTML = flash;

    window.scrollTo(0, 0);
  }, [flash]);

  return (
    <div className="compares">
      <div className="container-fluid">
        <Controls />
        <ScrollableCards />
      </div>
    </div>
  );
}

export default Page;
