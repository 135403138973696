import Base from 'src/components/summary/storages/base'
import * as Actions from 'src/components/summary/actions'

class Storage extends Base {
  changeUnit(vision_unit) {
    this.updateParams(Actions.UPDATE_VISION_UNIT, { vision_unit });
  }

  switchTrendline(trendline) {
    this.updateParams(Actions.UPDATE_TRENDLINE, { trendline });
  }

  get defaultParams() {
    return {
      step: 1,
      trendline: false,
      vision_unit: 'snellen'
    }
  }
}

export default Storage
