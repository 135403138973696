import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;


    $('#edit-modal .modal').modal('hide');

    $('.flash-alerts').html(data.flash);

    $('#root-context').html(data.content);
  }

  onError(event) {
    let [data, status, xhr] = event.detail;

    if (xhr.status == 401) {
      document.location.reload();
    } else {
      $('#edit-modal .modal-content').replaceWith(data.form);
      $('.modal textarea[autofocus]').focus();
    }
  }
}
