import $ from 'jquery'
import 'selectize'
import Arguments from 'src/plugins/rules/arguments'
import Participants from 'src/plugins/rules/participants'

export default class formBindings {
  constructor(selector) {
    this.form = $(selector);

    this.init();
  }

  init() {
    new Arguments(this.form);
    new Participants(this.form);

    this.initScrollToAlert();
    this.initRecipientsSelect();
    this.initTooltips();

    this.switchAssigneeBox();
    this.memberSwitchPushBoxes();
    this.patientSwitchEmailBoxes();
    this.patientSwitchPushBoxes();

    this.bindListeners();
  }

  initScrollToAlert() {
    if (!!this.alertBox.length) {
      return $('html, body').animate({ scrollTop: this.alertBox.offset().top - 75 }, 500);
    }
  }

  initRecipientsSelect() {
    this.selectizeBox.selectize({
      create: false,
      plugins: ['remove_button']
    });
  }

  initTooltips() {
    $(document).find('[data-toggle="popover"]').popover({
      container: 'body'
    })
  }

  switchAssigneeBox() {
    const action = this.eventType === 'informative' ? 'hide' : 'show';

    this.assigneeBox[action]();
    this.toggleControlAccessible(this.remindersBox, action);
    this.setupTemplateParams();
  }

  setupTemplateParams() {
    this.templatesLinks.forEach((elem) => {
      elem.setAttribute('data-params', 'event_type=' + this.eventType);
    });
  }

  toggleControlAccessible(control, accessible) {
    if (accessible == true || accessible === 'show') {
      control.removeClass('disabled');
    } else {
      control.addClass('disabled');
    }
  }

  memberSwitchPushBoxes() {
    this.toggleControlAccessible(this.pushMemberBox, this.notifyByPushMember)
  }

  patientSwitchEmailBoxes() {
    this.toggleControlAccessible(this.emailPatientBox, this.notifyByEmailPatient)
  }

  patientSwitchPushBoxes() {
    this.toggleControlAccessible(this.pushPatientBox, this.notifyByPushPatient)
  }

  bindListeners() {
    this.form.on('change', '#rule_event_type', (e) => this.switchAssigneeBox());
    this.form.on('change', '#rule_notify_by_push', (e) => this.memberSwitchPushBoxes());
    this.form.on('change', '#rule_notify_patient_by_email', (e) => this.patientSwitchEmailBoxes());
    this.form.on('change', '#rule_notify_patient_by_push', (e) => this.patientSwitchPushBoxes());
  }

  get eventType() {
    return this.form.find('#rule_event_type input:checked').val();
  }

  get notifyByPushMember() {
    return this.memberPushBox.is(':checked');
  }

  get notifyByEmailPatient() {
    return this.patientEmailBox.is(':checked');
  }

  get notifyByPushPatient() {
    return this.patientPushBox.is(':checked');
  }

  get alertBox() {
    return this.form.find('.form-alert');
  }

  get selectizeBox() {
    return this.form.find('.selectize-rule-recipients');
  }

  get recipientsBox() {
    return this.form.find('.rule_recipients_ids');
  }

  get assigneeBox() {
    return this.form.find('#rule_assignee');
  }

  get memberPushBox() {
    return this.form.find('#rule_notify_by_push');
  }

  get patientEmailBox() {
    return this.form.find('#rule_notify_patient_by_email');
  }

  get patientPushBox() {
    return this.form.find('#rule_notify_patient_by_push');
  }

  get pushMemberBox() {
    return this.form.find('.rule_push_member');
  }

  get emailPatientBox() {
    return this.form.find('.rule_email_patient');
  }

  get pushPatientBox() {
    return this.form.find('.rule_push_patient');
  }

  get remindersBox() {
    return this.form.find('.rule-reminders');
  }

  get templatesLinks() {
    return document.querySelectorAll('.edit-template');
  }
}
