import { Controller } from 'stimulus'
import $ from 'jquery'
import 'selectize'

export default class extends Controller {
  connect() {
    $(this.element).selectize({
      create: false,
      allowEmptyOption: true
    });
  }
}
