import React from 'react'
import { Form } from 'react-final-form'
import { FormCheck } from 'react-bootstrap'

const RadioField = ({
  input,
  meta,
  invalid = meta => meta.touched && meta.invalid,
  valid = () => false,
  ...rest
}) => (
    <FormCheck
      {...input}
      {...rest}
      isInvalid={invalid(meta)}
      isValid={valid(meta)}
    />
);

export default RadioField
