import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as selectors from 'src/react/store/Compares/selectors'

import Ability from './Ability'

import ShareLink from 'src/react/components/Compares/ShareLink'
import PdfLink from 'src/react/components/Compares/PdfLink'
import TransmitLink from 'src/react/components/Compares/TransmitLink'

import {
  switchVisibilityAction,
  switchShowAllAction
} from 'src/react/store/Compares/actions'

const Controls = (props) => {
  const dispatch = useDispatch();

  const {
    patient_name: patientName,
    overflow_limit: overflowLimit
  } = window.compares;

  const isLoading = useSelector(state => selectors.isLoading(state));
  const showAll = useSelector(state => selectors.showAll(state));
  const blacklist = useSelector(state => selectors.blacklist(state));
  const leftVisible = useSelector(state => selectors.leftVisible(state));
  const rightVisible = useSelector(state => selectors.rightVisible(state));

  const currentCount = useSelector(state => selectors.currentCount(state));
  const availableCount = useSelector(state => selectors.availableCount(state));

  const overflow = availableCount > overflowLimit;

  const switchVisibilityCheckbox = (e) => {
    const switchers = {
      'left': 'leftVisible',
      'right': 'rightVisible'
    }

    dispatch(switchVisibilityAction({
      [switchers[e.target.name]]: e.target.checked
    }));
  };

  const switchShowAllCheckbox = (e) => {
    dispatch(switchShowAllAction(e.target.checked));
  };

  return (
    <div className="row mb-3 d-flex align-items-center">
      <div className="col-md-12 col-lg-4 col-xl-4">
        <div className="page-head pt-0">
          <div className="page-head-title">
            {patientName} Tests Comparison
          </div>
          <nav>
            <ol className="breadcrumb page-head-nav">
              <li className="breadcrumb-item active">
                {
                  isLoading ? (
                    <span>
                      <div className="mdi mdi-loading mdi-spin"></div>
                      Loading health records for comparing ...
                    </span>
                  ) : (
                    (availableCount > 0) &&
                      <span> Comparing {currentCount} of {availableCount} health records </span>
                  )
                }
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {
        (availableCount > 0) && (
          <div className="col-md-12 col-lg-8 col-xl-8">
            <div className="d-flex justify-content-lg-end justify-content-md-start align-items-center flex-wrap">
              <div className="d-flex py-2 justify-content-end">
                <div className="mr-2 d-flex align-items-center">
                  <div className="switch-button switch-button-yesno switch-button-sm mr-1">
                    <input
                      type="checkbox"
                      id="all"
                      name="all"
                      checked={showAll}
                      onChange={switchShowAllCheckbox}
                      disabled={isLoading}
                    />
                    <span>
                      <label htmlFor="all"></label>
                    </span>
                  </div>
                  <span className="text-nowrap">
                    All Tests
                  </span>
                </div>
                <div className="mr-2 d-flex align-items-center">
                  <div className="switch-button switch-button-yesno switch-button-sm mr-1">
                    <input
                      type="checkbox"
                      id="left"
                      name="left"
                      checked={leftVisible}
                      disabled={isLoading || !rightVisible}
                      onChange={switchVisibilityCheckbox}
                    />
                    <span>
                      <label htmlFor="left"></label>
                    </span>
                  </div>
                  <span className="text-nowrap">
                    Left Eye
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <div className="switch-button switch-button-yesno switch-button-sm mr-1">
                    <input
                      type="checkbox"
                      id="right"
                      name="right"
                      checked={rightVisible}
                      disabled={isLoading || !leftVisible}
                      onChange={switchVisibilityCheckbox}
                    />
                    <span>
                      <label htmlFor="right"></label>
                    </span>
                  </div>
                  <span className="text-nowrap">
                    Right Eye
                  </span>
                </div>
              </div>

              <div className="ml-4 d-flex">
                <ShareLink
                  { ... { blacklist } }
                />

                <PdfLink
                  { ... { blacklist, showAll, leftVisible, rightVisible, overflow, overflowLimit } }
                />

                <Ability
                  context="transmit_available"
                >
                  <TransmitLink
                    { ... { blacklist, showAll, leftVisible, rightVisible } }
                  />
                </Ability>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default Controls
