import React from 'react'
import { Form } from 'react-final-form'
import { FormControl } from 'react-bootstrap'

const TextField = ({
  input,
  meta,
  invalid = meta => meta.touched && meta.invalid,
  valid = () => false,
  ...rest
}) => (
  <FormControl
    {...input}
    {...rest}
    isInvalid={invalid(meta)}
    isValid={valid(meta)}
  />
);

export default TextField
