import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Accordion, Button, Dropdown } from 'react-bootstrap'

import Ability from './Ability'
import CategorySnippets from './CategorySnippets'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { showFormModalAction, showCopyModalAction, showDeleteModalAction } from 'src/react/store/TrainingWizard/actions'

const AccordionItem = ({ isDragDisabled, categoryId, active, changeActive }) => {
  const dispatch = useDispatch();
  const category = useSelector(state => selectors.getСategoryById(state, categoryId));

  return (
    <div className={active == categoryId ? 'accordion-item accordion-active' : 'accordion-item'}>
      <div className="category-block">
        <Accordion.Toggle
          as="div"
          className="accordion-toggle"
          variant="link"
          eventKey={categoryId}
          onClick={
            () => changeActive(categoryId)
          }
        >
          {category.name}
        </Accordion.Toggle>

        <div className="controls">
          <Ability
            context="editable_training"
          >
            <div className="actions mr-2">
              <Button
                className="btn-icon"
                variant="link"
                onClick={
                  () => dispatch(showFormModalAction({ entry: 'snippet', options: { defaultCategoryId: categoryId } }))
                }
              >
                <div className="mdi mdi-18px mdi-plus-circle"></div>
              </Button>

              <Dropdown>
                <Dropdown.Toggle className="btn-icon" variant="link">
                  <div className="mdi mdi-18px mdi-dots-horizontal-circle-outline"></div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={
                      () => dispatch(showFormModalAction({ entry: 'category', id: categoryId }))
                    }
                  >
                    Rename
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={
                      () => dispatch(showCopyModalAction({ entry: 'category', id: categoryId }))
                    }
                  >
                    Copy
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="text-danger"
                    onClick={
                      () => dispatch(showDeleteModalAction({ entry: 'category', id: categoryId }))
                    }
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Ability>
          <div className="indicators">
            <div className="badge badge-pill badge-primary-light">{category.snippets.length}</div>
            <div className="chevron"></div>
          </div>
        </div>
      </div>

      <Accordion.Collapse eventKey={category.id}>
        {
          category.snippets.length > 0 ?
            <CategorySnippets
              snippets={category.snippets}
              categoryId={categoryId}
              isDragDisabled={isDragDisabled}
            /> :
            <div className="accordion-empty">
              No snippets added yet
            </div>
        }

      </Accordion.Collapse>
    </div>
  );
}

export default AccordionItem
