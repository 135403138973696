import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'
import { membershipsFormBindings } from 'src/plugins/memberships'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    $('body').
      findOrAppend('#edit-modal', '<div id="edit-modal" />').
      html(data.content).
      find('.modal').
      modal('show').
      membershipsFormBindings({ type: 'members' });
  }
}
