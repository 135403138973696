import Base from 'src/components/summary/grids/base'
import { DEFAULT_OPTIONS } from 'src/components/summary/constants/defaults'
import { BLOOD_OXYGEN } from 'src/components/summary/constants/cardiology/blood_oxygen'
import moment from 'moment'

class BloodOxygen extends Base {
  defaultParams() {
    return $.extend(true, {}, DEFAULT_OPTIONS, BLOOD_OXYGEN);
  }

  onActivateDaily(chart, e, response) {
    chart.yAxis[1].update({ visible: true }, false);

    chart.yAxis[0].update({
      max: 200,
      labels: {
        formatter: function() {
          return this.value > 100 ? null : this.value;
        }
      },
      plotLines: [
        {
          color: '#0491fd',
          width: 2,
          dashStyle: 'ShortDash',
          value: 100,
          label: {
            style: {
              fontSize: 9,
              color: '#0491fd'
            },
            text: 'SAT max'
          }
        }
      ]
    }, false);
  }

  onDeactivateDaily(chart) {
    chart.yAxis[1].update({ visible: false }, false);

    chart.yAxis[0].update({
      max: 100,
      labels: {
        formatter: function() {
          return this.value;
        }
      },
      plotLines: []
    }, false);
  }

  tooltipFormatter(context, tooltip) {
    let s = this.tooltipHeader(this.tooltipLabel(context.points[0].point));

    context.points.forEach((point) => {
      s += this.tooltipBody(point, this.tooltipResult(point.point));
    });

    return s;
  }

  tooltipLabel(point) {
    if (this.currentDataspace.mode == 'daily') return point.time;
    if (point.by_date) return moment(point.by_date).format('D MMM Y');

    return `${moment(point.start_date).format('D MMM')} - ${moment(point.end_date).format('D MMM Y')}`;
  }

  tooltipResult(point) {
    return (this.currentDataspace.mode == 'range') ? `${point.low} - ${point.high}` : point.y;
  }

  get options() {
    return {
      category: 'cardiology',
      widget: 'blood_oxygen'
    }
  }
}

export default BloodOxygen
