import { handleActions } from 'redux-actions'
import produce from 'immer'

import {
  showFormModalAction,
  showDeleteModalAction,
  hideModalAction,
  clearModalAction,
  changeActionableAction,
  changeVerificationLockAction,
  editCellAction,
  dropEditCellAction,
  changeCellAction,
  setInvalidCellAction,
  setValidCellAction,
  createRootTimeRecordAction,
  freezeTreeTimeRecordAction,
  prepareTreeTimeRecordAction,
  createTreeTimeRecordAction,
  updateTreeTimeRecordAction,
  deleteTreeTimeRecordAction,
  startTimesheetTreeRequest,
  getTimesheetTreeRequest,
  changeTimesheetRangeAction,
  activateDayAction,
  switchActivityAction,
  switchActivitiesAction,
  deleteActivityAction
} from './actions'

const initialState = {};

const generateActivityId = (day, patientId) => [day, patientId].join('-');

const combineModalAction = (state, payload) => {
  const { id = null, entry, action, options = {} } = payload;

  return {
    ...state,
    formAction: action,
    formEntry: entry,
    formEntryId: id,
    formOptions: options,
    formModalShow: true
  };
};

export default handleActions({
  [ showFormModalAction ]: (state, { payload }) => (
    combineModalAction(state, { ... payload, action: 'form' })
  ),
  [ showDeleteModalAction ]: (state, { payload }) => (
    combineModalAction(state, { ... payload, action: 'delete' })
  ),
  [ hideModalAction ]: (state, { payload }) => ({
    ...state,
    formModalShow: false
  }),
  [ clearModalAction ]: (state, { payload }) => ({
    ...state,
    formAction: null,
    formEntry: null,
    formEntryId: null,
    formOptions: {}
  }),
  [ changeActionableAction ]: (state, { payload: actionableUser }) => ({
    ...state,
    actionableUser
  }),
  [ changeVerificationLockAction ]: (state, { payload: verificationLock }) => ({
    ...state,
    verificationLock,
    formModalShow: false
  }),
  [ editCellAction ]: (state, { payload }) => {
    const { timeRecordId, context, value } = payload;

    return produce(state, draftState => {
      const editCell = () => {
        const { form } = draftState.time_records[timeRecordId];

        if (form) {
          form.cells[context] = { value };
        } else {
          draftState.time_records[timeRecordId].form = {
            cells: { [context]: { value }},
            invalidCells: [],
            changedCells: []
          };
        }
      };

      editCell();
    });
  },
  [ dropEditCellAction ]: (state, { payload }) => {
    const { timeRecordId, context } = payload;

    return produce(state, draftState => {
      const dropEditCell = () => {
        delete draftState.time_records[timeRecordId].form.cells[context];
      }

      dropEditCell();
    });
  },
  [ changeCellAction ]: (state, { payload }) => {
    const { timeRecordId, context, value } = payload;

    return produce(state, draftState => {
      const changeCell = () => {
        Object.assign(draftState.time_records[timeRecordId].form.cells[context], { value });
      };

      changeCell();
    });
  },
  [ setInvalidCellAction ]: (state, { payload }) => {
    const { timeRecordId, context, errors } = payload;

    return produce(state, draftState => {
      const processForm = () => {
        const { form } = draftState.time_records[timeRecordId];

        Object.assign(form.cells[context], { errors });

        form.invalidCells = [... new Set([...form.invalidCells, context])];
      };

      processForm();
    });
  },
  [ setValidCellAction ]: (state, { payload }) => {
    const { timeRecordId, context, value } = payload;

    return produce(state, draftState => {
      const { form } = draftState.time_records[timeRecordId];

      const replaceValue = () => {
        Object.assign(draftState.time_records[timeRecordId], { [context]: value });
      }

      const removeFormCell = () => {
        delete form.cells[context];
      }

      const updateFormChanges = () => {
        form.changedCells = [... new Set([...form.changedCells, context])];
      }

      const updateFormInvalid = () => {
        const indexOfContextError = form.invalidCells.indexOf(context);

        if (indexOfContextError > -1) {
          form.invalidCells.splice(indexOfContextError, 1);
        }
      }

      replaceValue();
      removeFormCell();
      updateFormChanges();
      updateFormInvalid();
    });
  },
  [ startTimesheetTreeRequest ]: (state, { payload }) => {
    return ({
      ...state,
      isLoading: true
    });
  },
  [ getTimesheetTreeRequest ]: (state, { payload }) => {
    const {
      from,
      to,
      days,
      activities,
      time_records,
      patients,
    } = payload;

    return ({
      ...state,
      from,
      to,
      days,
      activities,
      time_records,
      patients,
      isLoading: false
    });
  },
  [ changeTimesheetRangeAction ]: (state, { payload: { from, to } }) => {
    const { currentWeek: { from: _from, to: _to, currentDay: _currentDay } } = state;

    return ({
      ...state,
      from,
      to,
      currentDay: (from == _from && to == _to) ? _currentDay : from,
      isLoading: true
    });
  },
  [ activateDayAction ]: (state, { payload: currentDay }) => ({
    ...state,
    currentDay
  }),
  [ switchActivityAction ]: (state, { payload: { activityId, active } }) => {
    return produce(state, draftState => {
      const activateActivity = () => {
        draftState.activities[activityId].active = active;
      };

      activateActivity();
    });
  },
  [ switchActivitiesAction ]: (state, { payload: day }) => {
    return produce(state, draftState => {
      const switchActivities = () => {
        const { activities } = draftState.days[day];

        activities.forEach((activityId) => {
          draftState.activities[activityId].active = true;
        });
      };

      switchActivities();
    });
  },
  [ deleteActivityAction ]: (state, { payload: activity }) => {
    const { id: activityId, day, time_records } = activity;

    return produce(state, draftState => {
      const  { activities } = draftState.days[day];

      const deleteActivityFromDay = () => {
        activities.splice(activities.indexOf(activityId), 1);
      };

      const deleteTimeRecords = () => {
        time_records.forEach((id) => delete draftState.time_records[id]);
      };

      const deleteActivity = () => {
        delete draftState.activities[activityId];
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      deleteActivityFromDay();
      deleteTimeRecords();
      deleteActivity();
      hideModal();
    });
  },
  [ createRootTimeRecordAction ]: (state, { payload: { timeRecord } }) => {
    const { category, day, details, amount, patient } = timeRecord;

    const { id: timeRecordId } = timeRecord;
    const { id: patientId } = patient;

    const activityId = generateActivityId(day, patientId);

    return produce(state, draftState => {
      const upsertPatient = () => {
        draftState.patients[patientId] = patient;
      };

      const upsertActivity = () => {
        const activity = draftState.activities[activityId];

        if (!activity) {
          if (!draftState.days[day]) return;

          draftState.activities[activityId] = {
            id: activityId,
            day: day,
            patient_id: patientId,
            active: true,
            time_records: [timeRecordId]
          }

          draftState.days[day].activities.unshift(activityId);
        } else {
          activity.time_records.push(timeRecordId);
        }
      };

      const createTimeRecord = () => {
        draftState.time_records[timeRecordId] = {
          id: timeRecordId,
          patient_id: patientId,
          day,
          details,
          category,
          amount
        }
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      upsertPatient();
      upsertActivity();
      createTimeRecord();
      hideModal();
    });
  },
  [ prepareTreeTimeRecordAction ]: (state, { payload: { activityId, timeRecord } }) => {
    const { id: timeRecordId } = timeRecord;

    return produce(state, draftState => {
      const activateActivity = () => {
        draftState.activities[activityId].active = true;
      };

      const createTimeRecord = () => {
        draftState.time_records[timeRecordId] = timeRecord;
        draftState.activities[activityId].time_records.push(timeRecordId);
      };

      activateActivity();
      createTimeRecord();
    });
  },
  [ freezeTreeTimeRecordAction ]: (state, { payload: timeRecordId }) => {
    return produce(state, draftState => {
      const freezeTimeRecord = () => {
        draftState.time_records[timeRecordId].frozen = true;
      };

      freezeTimeRecord();
    });
  },
  [ createTreeTimeRecordAction ]: (state, { payload: { activityId, timeRecordId, draftTimeRecordId } }) => {
    return produce(state, draftState => {
      const { time_records } = draftState.activities[activityId];
      const timeRecord = draftState.time_records[draftTimeRecordId];

      const resetForm = () => {
        timeRecord.changedCells = [];
      };

      const resetFrozen = () => {
        timeRecord.frozen = false;
        timeRecord.draft = false;
      };

      const replaceDraft = () => {
        timeRecord.id = timeRecordId;

        // replace time record in time records tree
        delete Object.assign(draftState.time_records, { [timeRecordId]: timeRecord })[draftTimeRecordId];

        // replace time record in activities tree
        time_records.splice(time_records.indexOf(draftTimeRecordId), 1, timeRecordId);
      };

      resetForm();
      resetFrozen();
      replaceDraft();
    });
  },
  [ updateTreeTimeRecordAction ]: (state, { payload: { timeRecordId } }) => {
    return produce(state, draftState => {
      const timeRecord = draftState.time_records[timeRecordId];

      const resetForm = () => {
        timeRecord.changedCells = [];
      };

      const resetFrozen = () => {
        timeRecord.frozen = false;
      };

      resetForm();
      resetFrozen();
    });
  },
  [ deleteTreeTimeRecordAction ]: (state, { payload }) => {
    const { activityId, timeRecordId } = payload;

    return produce(state, draftState => {
      const { day, time_records } = draftState.activities[activityId];

      const deleteTimeRecord = () => {
        delete draftState.time_records[timeRecordId];
      };

      const updateActivity = () => {
        time_records.splice(time_records.indexOf(timeRecordId), 1);
      }

      const removeActivity = () => {
        const { activities } = draftState.days[day];

        activities.splice(activities.indexOf(activityId), 1);
        delete draftState.activities[activityId];
      }

      const refreshActivity = () => {
        time_records.length > 1 ? updateActivity() : removeActivity();
      };

      const hideModal = () => {
        draftState.formModalShow = false;
      };

      deleteTimeRecord();
      refreshActivity();
      hideModal();
    });
  }
}, initialState);
