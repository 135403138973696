
const CONTRAST = {
  chart: {
    type: 'column',
    marginTop: 20,
    height: 380
  },
  yAxis: [
    {
      tickPositions: [2.10, 1.95, 1.80, 1.65, 1.50, 1.35, 1.20, 1.05, 0.90, 0.75, 0.60, 0.45, 0.30, 0.15, 0.00],
      reversed: true,
      title: {
        text: null,
        style: 'display: none;'
      },
      labels: {
        formatter: function() {
          return this.value.toFixed(2);
        }
      }
    }
  ],
  plotOptions: {
    column: {
      borderWidth: 0,
      animation: false
    },
    series: {
      cursor: 'pointer',
      minPointLength: 3,
      events: {
        legendItemClick: function() {
          return false;
        }
      },
      point: {
        events: {}
      },
      states: {
        hover: {
          enabled: false
        }
      }
    }
  }
};

export { CONTRAST };
