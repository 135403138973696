import React from 'react'
import { useSelector } from 'react-redux'

import * as selectors from 'src/react/store/TrainingWizard/selectors'

import classnames from 'classnames'

import Ability from './Ability'
import Image from 'src/react/components/TrainingWizard/Image'

const Answer = ({ isDragDisabled, dragHandleProps, answerId, index }) => {
  const answer = useSelector(state => selectors.getAnswerById(state, answerId));

  const answerPointsStyles = classnames('answer-points', { 'text-success': answer.correct });
  const dragHandleStyles = classnames('mdi', 'mdi-drag', { 'd-none': isDragDisabled });

  return (
    <div className="answer">
      <div className="d-flex justify-content-between">
        <div className="answer-title">
          <div
            className={dragHandleStyles}
            {...dragHandleProps}
          >
          </div>{' '}
          Answer {index + 1}
        </div>
        <div className={answerPointsStyles}>
          Points: {answer.score}
        </div>
      </div>
      <div className="answer-image">
        <Image image={answer.image} />
      </div>
      <div className="answer-body">
        {answer.answer}
      </div>
    </div>
  );
}

export default Answer
