import ChartTooltipFormatter from 'src/utils/chartTooltipFormatter'

const formatter = new ChartTooltipFormatter();

const DEFAULT_PARAMS = {
  chart: {
    zoomType: 'xy'
  },
  title: {
    text: 'Active Patients',
    align: 'center'
  },
  colors: ['#3590D3'],
  yAxis: [{ // Tertiary yAxis
    gridLineWidth: 1,
    allowDecimals: false,
    title: {
      text: false,
      style: {
        color: '#000'
      }
    },
    labels: {
      format: '{value}',
      style: {
        color: '#000'
      }
    },
    opposite: false,
    min: 0,
    softMax: 1
  }],
  tooltip: {
    shared: false,
    useHTML: true,
    formatter: function() {
      return formatter.patientsActivityTooltip(this)
    }
  },
  chart: {
    height: 500,
    marginTop: 60,
    marginBottom: 100
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    x: 0,
    y: 15,
    floating: true,
    backgroundColor: 'rgba(255,255,255,0.25)'
  },
  credits: {
    enabled: false
  }
};

export {
  DEFAULT_PARAMS
}
