import { Controller } from 'stimulus'

export default class extends Controller {}
/*
import Highcharts from 'highcharts'
import Factory from 'src/components/range/factory'
import regression from 'regression'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    this.initializeHighcharts();

    this.factories = [...this.targets].map((target, index) => {
      let factory = new Factory(target, target.dataset);

      factory.callbacks['onApplyRange'] = (factory) => {
        const factories = this.filterFactories(factory);

        this.applyNavigationOptions(factories, factory);
      }

      factory.callbacks['onApplyUnits'] = (factory, unit) => {
        const factories = this.filterVisionFactories(factory);

        this.applyVisionUnit(factories, unit);
      }

      return factory;
    });

    this.applyRoot();
  }

  sequentiallyFetchResults(factories) {
    let promise = Promise.resolve();

    factories.forEach((factory) => {
      promise = promise.
        then(result => factory.fetchRootResults()).
        then(result => factory.hideLoading()).
        catch(jqXHR => factory.handleLoadingErrors(jqXHR));
    });

    //promise.then(() => console.log('Finished'));
  }

  filterVisionFactories(factory) {
    const factoryPair = this.factories.filter((el, index) => {
      return el.params.target !== factory.params.target && el.params.key == 'ophthalmology:vision';
    });

    return [factory, ... factoryPair];
  }

  filterFactories(factory) {
    return this.factories.filter((el, index) => {
      return el.params.target !== factory.params.target;
    });
  }

  showLoading(factories) {
    factories.forEach((factory) => factory.showLoading());
  }

  scrollToTarget() {
    const [target, ... rest] = this.factories.filter((factory, index) => factory.params.forceEntry);

    if (target) {
      $('html, body').animate({
        scrollTop: $(`div[data-context="${target.params.key}"]`).offset().top - 50
      }, 500);
    }
  }

  applyRoot() {
    this.scrollToTarget();

    this.showLoading(this.factories);
    this.sequentiallyFetchResults(this.factories);
  }

  applyNavigationOptions(factories, factory) {
    const { start_date, end_date, step } = factory.storage.params;

    this.showLoading(factories);

    factories.forEach((factory) => {
      factory.applyNavigationOptions({ start_date, end_date, step });
    });

    this.sequentiallyFetchResults(factories);
  }

  applyVisionUnit(factories, unit) {
    this.showLoading(factories);

    factories.forEach((factory) => factory.applyUnit(unit));

    this.sequentiallyFetchResults(factories);
  }

  initializeHighcharts() {
    // disable click on category label
    Highcharts.Tick.prototype.drillable = function() {};

    Highcharts.wrap(Highcharts.Chart.prototype, 'drillUp', function (proceed) {
      Highcharts.fireEvent(this, 'drillupstart');

      proceed.apply(this);
    });

    Highcharts.setOptions({
      lang: {
        drillUpText: 'Back'
      }
    });
  }

  get targets() {
    return document.querySelectorAll('.targets');
  }
}
*/
