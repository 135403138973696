import React, { useEffect, useRef, useCallback } from 'react'
import classnames from 'classnames'

import InvalidFeedback from 'src/react/components/Timesheets/InvalidFeedback'

const TextCell = ({ cell: { value, errors }, changeCell }) => {
  const inputRef = useRef(null);
  const inputStyles = classnames('form-control', { 'is-invalid': errors });

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleInputChange = useCallback(
    (event) => {
      changeCell({ value: event.target.value });
    },
    [changeCell]
  );

  return (
    <>
      <input
        ref={inputRef}
        value={value}
        className={inputStyles}
        onChange={handleInputChange}
      />
      <InvalidFeedback errors={errors} />
    </>
  );
}

export default TextCell
