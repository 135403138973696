import Base from 'src/components/summary/widgets/base'
import Chart from 'src/components/summary/grids/cardiology/blood_oxygen'

class BloodOxygen extends Base {
  buildInstances() {
    this.chart = new Chart(this.grid_selector, this.storage, this.params);
  }

  buildCallbacks() {
    super.buildCallbacks();
  }
}

export default BloodOxygen
