const DEFAULT_OPTIONS = {
  chart: {
    height: 460,
    events: {}
  },
  legend: {
    x: 15
  },
  title: {
    text: null,
    style: 'display: none;'
  },
  subtitle: {
    text: null,
    align: 'right',
    floating: true,
    verticalAlign: 'bottom',
    style: {
      color: '#4572A7',
      fontSize: '12px',
    }
  },
  credits: {
    enabled: false
  },
  tooltip: {
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
    style: {
      padding: 0
    }
  },
  xAxis: [
    {
      plotBands: [],
      type: 'category',
      labels: {
        style: {
          fontWeight: 'bold',
          fontSize: '10px'
        }
      }
    },
    // labels
    {
      type: 'category',
      tickWidth: 0,
      tickPositioner(min, max) {
        const positions = [];

        let i = min;
        while (i <= max) {
          positions.push(i);
          i++;
        }

        return positions;
      },
      linkedTo: 0,
      opposite: true,
      labels: {
        align: 'center',
        useHTML: true,
        rotation: 0,
        y: -10
      }
    }
  ],
  series: [],
  drilldown: {
    series: [],
    activeAxisLabelStyle: {
      textDecoration: 'none'
    },
    activeDataLabelStyle: {
      textDecoration: 'none'
    },
    drillUpButton: {
      relativeTo: 'spacingBox',
      position: {
        align: 'left',
        verticalAlign: 'bottom',
        y: -22,
        x: 0
      },
      theme: {
        fill: 'white',
        stroke: 'silver',
        r: 0,
        width: 25,
        height: 4,
        style: {
          color: '#666'
        }
      }
    }
  }
};

const DAILY_PLOT_BANDS = {
  morning: {
    color: '#edf3ff',
    label: {
      useHTML: true,
      text: '<span class="mdi mdi-star"></span>',
      verticalAlign: 'bottom',
      y: -5
    }
  },
  day: {
    color: '#feffed',
    label: {
      useHTML: true,
      text: '<span class="mdi mdi-white-balance-sunny"></span>',
      verticalAlign: 'bottom',
      y: -5
    }
  },
  evening: {
    color: '#edffed',
    label: {
      useHTML: true,
      text: '<span class="mdi mdi-ceiling-light"></span>',
      verticalAlign: 'bottom',
      y: -5
    }
  }
};

export { DEFAULT_OPTIONS, DAILY_PLOT_BANDS };
