import { Controller } from 'stimulus'
import $ from 'jquery'
import 'selectize'

export default class extends Controller {
  connect() {
    this.initPrimaryDiseaseSelect();

    if(this.isAssignmentFilterDisplayed) {
      this.toggleSelectize();
      this.bindListeners();
    }
  }

  initPrimaryDiseaseSelect() {
    const self = this;

    this.protocolsSelect = $(this.element).selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      create: false,
      options: self.preloadingOptions,
      plugins: ['remove_button'],
      render: {
        item: function(item, escape) {
          return self.formatProtocolTag(item, escape);
        },
        option: function(item, escape) {
          return self.formatProtocolOption(item, escape);
        }
      }
    });
  }

  get preloadingOptions() {
    return JSON.parse(this.element.dataset.preloadingOptions);
  }

  formatProtocolTag(item, escape) {
    const cssClasses = item['archived'] ? 'archived-protocol-tag' : 'protocol-tag';
    return `
      <div class="${cssClasses}">
        ${escape(item.name)}
        ${item['archived'] ? '(archived)' : ''}
      </div>
    `;
  }

  formatProtocolOption(item, escape) {
    return `
      <div class="nudge-time-option">
        ${escape(item.name)}
        ${item['archived'] ? '(archived)' : ''}
      </div>
    `;
  }

  bindListeners() {
    $(this.assignmentFilterElement).on('change', () => this.toggleSelectize());
  }

  toggleSelectize() {
    const selectize = this.protocolsSelect[0].selectize;

    if (this.isProtocolFilterActive()) {
      selectize.enable();
    } else {
      selectize.disable();
    }
  }

  get isAssignmentFilterDisplayed() {
    return this.assignmentFilterElement.length;
  }

  isProtocolFilterActive() {
    return this.assignmentFilterStatus == 'assigned';
  }

  get assignmentFilterStatus(){
    return $(`${this.assignmentFilterSelector}:checked`).val();
  }

  get assignmentFilterElement(){
    return $(this.assignmentFilterSelector);
  }

  get assignmentFilterSelector() {
    return "input[name='q[protocol_assignments]']";
  }
}
