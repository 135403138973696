class Reset {
  constructor(selector, storage) {
    this.selector = selector;
    this.storage = storage;

    this.init();
  }

  init() {
    this.buildReset();
    this.buildCallbacks();
  }

  update() {
  }

  buildReset() {
    this.selector.innerHTML = `<a class="btn btn-secondary" href="javascript:void(0)" data-move-latest>
      Reset
    </a>`;
  }

  buildCallbacks() {
    this.moveLatest.addEventListener('click', (e) => this.storage.moveLatest());
  }

  get moveLatest() {
    return this.selector.querySelector('[data-move-latest]');
  }
}

export default Reset
