class CompareButton {
  constructor(selector, storage) {
    this.selector = selector;
    this.storage = storage;

    this.init();
  }

  init() {
    this.buildExport();
    this.updateCompareLinks();
  }

  update() {
    this.updateCompareLinks();
  }

  buildExport() {
    this.selector.innerHTML = `
      <div class="dropdown">
        <button class="btn btn-primary dropdown-toggle mr-2" type="button" id="compare" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Compare
          <div class="mdi mdi-chevron-down"></div>
       </button>
       <div class="dropdown-menu dropdown-menu-right" aria-labelledby="compare">
         <a class="dropdown-item" target="_blank" data-compare-all-button>Compare all Health Records</a>
         <a class="dropdown-item" target="_blank" data-compare-amsler-button>Compare only Amsler</a>
       </div>
     </div>`;
  }

  updateCompareLinks() {
    const [ compareAllURL, compareAmslerURL ] = this.compareLinks();

    this.compareAllButton.href = compareAllURL;
    this.compareAmslerButton.href = compareAmslerURL;
  }

  compareLinks() {
    const { start_date, end_date } = this.storage.params;

    let options = {
      from: start_date.format('YYYY-MM-DD'),
      to: end_date.format('YYYY-MM-DD')
    };

    return [
      (window.metrics.compare_path + '?' + $.param({ ... options })),
      (window.metrics.compare_path + '?' + $.param({ ... options, only_amsler: true }))
    ];
  }

  get compareAllButton() {
    return this.selector.querySelector('[data-compare-all-button]');
  }

  get compareAmslerButton() {
    return this.selector.querySelector('[data-compare-amsler-button]');
  }
}

export default CompareButton
