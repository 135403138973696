import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.initializeVariables();
    this.handleAssigneeChange();
    this.attachListeners();
  }

  initializeVariables() {
    this.currentAssigneeId = this.element.dataset.currentAssigneeId;
  }

  handleAssigneeChange(e) {
    this.stateCommentContext.style.display =
      this.currentAssigneeId == this.assigneeInputContext.value ? 'none' : 'block';
  }

  attachListeners() {
    this.assigneeInputContext.addEventListener('change', (e) => this.handleAssigneeChange(e));
  }

  get assigneeInputContext() {
    return document.querySelector('.assignee_input_context');
  }

  get stateCommentContext() {
    return document.querySelector('.state_comment_context');
  }
}
