import React from 'react'

const InvalidFeedback = ({ errors }) => (
  errors ?
    <div className="invalid-feedback">
      {errors}
    </div> :
    null
);

export default InvalidFeedback
