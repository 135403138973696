import moment from 'moment'

class RangeDatesConverter {
  constructor(element, params = {}) {
    this.element = element;
    this.params = params;
  }

  dateFrom(range) {
    let date;

    switch(range) {
      case '12_weeks':
        date = moment().endOf('isoweek').subtract(12, 'weeks').add(1, 'day');
        break;
      case '26_weeks':
        date = moment().endOf('isoweek').subtract(26, 'weeks').add(1, 'day');
        break;
      case '52_weeks':
        date = moment().endOf('isoweek').subtract(52, 'weeks').add(1, 'day');
        break;
      case 'last_30_days':
        date = moment().subtract(30, 'days');
        break;
      case 'this_month':
        date = moment().startOf('month');
        break;
      case 'last_month':
        date = moment().subtract(1, 'month').startOf('month');
        break;
      case 'this_quarter':
        date = moment().startOf('quarter');
        break;
      case 'last_quarter':
        date = moment().subtract(1, 'quarter').startOf('quarter');
        break;
      case 'last_6_months':
        date = moment().subtract(6, 'months').startOf('month');
        break;
      case 'last_12_months':
        date = moment().subtract(12, 'months').startOf('month');
        break;
      case 'this_year':
        date = moment().startOf('year');
        break;
      case 'last_year':
        date = moment().subtract(1, 'year').startOf('year');
        break;
    }

    return date.format('YYYY-MM-DD');
  }

  dateTo(range) {
    let date;

    switch(range) {
      case '12_weeks':
      case '26_weeks':
      case '52_weeks':
        date = moment().endOf('isoweek');
        break;
      case 'last_30_days':
        date = moment().subtract(1, 'day');
        break;
      case 'this_month':
        date = moment().endOf('month');
        break;
      case 'last_month':
      case 'last_6_months':
      case 'last_12_months':
        date = moment().subtract(1, 'month').endOf('month');
        break;
      case 'this_quarter':
        date = moment().endOf('quarter');
        break;
      case 'last_quarter':
        date = moment().subtract(1, 'quarter').endOf('quarter');
        break;
      case 'this_year':
        date = moment().endOf('year');
        break;
      case 'last_year':
        date = moment().subtract(1, 'year').endOf('year');
        break;
    }

    return date.format('YYYY-MM-DD');
  }
}

export default RangeDatesConverter
