import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'
import swal from 'sweetalert';

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    $('body').
      findOrAppend('#edit-modal', '<div id="edit-modal" />').
      html(data.modal).
      find('.modal').
      modal('show');
  }

  onError(event) {
    swal('Something went wrong. Please reload the page and try again.')
  }
}
