import { Controller } from 'stimulus'
import $ from 'jquery'
import Dropzone from 'dropzone/dist/min/dropzone.min'

const allowed_extentions = '.pdf, .doc, .docx, .rtf, .txt, .png, .jpg, .jpeg, .gif, .zip, .rar, .csv, .xls, .xlsx, .ppt, .pptx, .numbers, .pages, .key'

export default class extends Controller {
  connect() {
    Dropzone.autoDiscover = false;
    let dz = new Dropzone('.dz', {
      paramName: 'file',
      // customize preview
      // previewTemplate: '<div class="some-class"></div>',
      maxFilesize: 20,
      parallelUploads: 1,
      timeout: 60000,
      dictDefaultMessage: 'Drop files here or click to choose',
      acceptedFiles: allowed_extentions,
      addRemoveLinks: true,
      sending: () => { window.onbeforeunload = () => { return false }},
      queuecomplete: () => {
        this.updateTemplate();

        this.removeSuccessFiles(dz).then((files) => {
          if (files.length > 0) { $('.clean-up-queue-link').show(); }
        })
        window.onbeforeunload = true;
      }
    });
    this.cleanUpQueueLink(dz);
  }

  updateTemplate() {
    $.ajax({
      method: 'GET',
      url: this.uploadURL + '.json',
      success: (data) => {
        this.filesBlock.html(data.template);
        $('.file-counter').html(data.file_count);
      }
    })
  }

  async removeSuccessFiles(dropbox) {
    dropbox.files.forEach((file) => {
      if (file.status == 'success') {
        dropbox.removeFile(file);
      }
    });
    return dropbox.files
  }

  cleanUpQueueLink(dz) {
    this.cleanUpBlock.find('a').on('click', () => {
      dz.removeAllFiles();
      this.cleanUpBlock.hide();
    })
  }

  get filesBlock() {
    return $('.files-table');
  }

  get uploadURL() {
    return this.filesBlock.data('url');
  }

  get cleanUpBlock() {
    return $('.clean-up-queue-link');
  }
}
