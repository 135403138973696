import moment from 'moment'

const daysRange = (from, to) => {
  const range = [];

  let fromDate = moment(from);
  let toDate = moment(to);

  while (fromDate <= toDate) {
   range.push(fromDate.clone());
   fromDate.add(1, 'days');
  }

  return range;
};

export default daysRange
