import { Controller } from 'stimulus'
// import $ from 'jquery'

export default class extends Controller {
  static targets = ['value']

  connect() {
    this.load()
  }

  load() {
    const isChecked = this.element.querySelector("input[id='athena_integration_destroy']").checked
    this.hideShowElement(!isChecked)
  }

  run() {
    const isChecked = this.element.querySelector("input[id='athena_integration_destroy']").checked
    this.hideShowElement(!isChecked)
  }

  hideShowElement(toHide) {
    const el = document.getElementById('athena-integration-form');

    if (toHide) { el.style.display = 'none'; } else { el.style.display = 'block' }
  }
}
