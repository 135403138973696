export const escapeHTML = str => (str+'').replace(/[&<>"'`=\/]/g, s => ({'&': '&amp;','<': '&lt;','>': '&gt;','"': '&quot;',"'": '&#39;','/': '&#x2F;','`': '&#x60;','=': '&#x3D;'})[s]);
export const _escapeRegExChars = (query) => query.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&")

const escape = (query, value) => {
  let pattern = '(' + _escapeRegExChars(query) + ')'

  return value
    .replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/&lt;(\/?strong)&gt;/g, '<$1>')
}

export default escape
