import { Controller } from 'stimulus'
import $ from 'jquery'
import { findOrAppend } from 'src/plugins/findOrAppend'

export default class extends Controller {
  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    if (data.success) {
      $('body').find('#edit-modal').find('.modal').modal('hide');
      location.reload();
    } else {
      $('#edit-modal form').replaceWith(data.form);
    }
  }


  onError(event) {
    swal('Something went wrong. Please reload the page and try again.')
  }
}
