import $ from 'jquery'
import Selectize from 'selectize'

Selectize.define('no_results', function(options) {
  const self = this;

  options = $.extend({
    message: 'No results found.',
    html: function(data) {
      return (
        '<div class="selectize-dropdown ' + data.classNames + '">' +
          '<div class="selectize-dropdown-content">' +
            '<div class="no-results p-1">' + data.message + '</div>' +
          '</div>' +
        '</div>'
        );
      }
    }, options);

  self.switchNoResults = function(state) {
    (state == 'show') ? self.showNoResults() : self.hideNoResults();
  }

  self.showNoResults = function() {
    this.$control.addClass('dropdown-active');

    this.$empty_results_container.
      css('top', this.$control.outerHeight()).
      css('width', this.$control.outerWidth()).
      show();
    };

  self.hideNoResults = function() {
    this.$empty_results_container.hide();
  }

  self.load = (function() {
    const original = self.load;

    return function (fn) {
      const self = this;
      const $wrapper = self.$wrapper.addClass(self.settings.loadingClass);

      self.loading++;

      fn.apply(self, [function(results) {
        self.loading = Math.max(self.loading - 1, 0);

        self.clearOptions();
        self.clearCache();

        if (results && results.length) {
          self.addOption(results);
        }

        if (!self.loading) {
          self.refreshOptions(self.isFocused && !self.isInputHidden);

          $wrapper.removeClass(self.settings.loadingClass);
        }

        self.trigger('load', results);
      }]);
    }
  })();

  self.onKeyDown = (function () {
    const original = self.onKeyDown;

    return function (e) {
      original.apply(self, arguments);

      if (e.keyCode === 27) self.hideNoResults();
    }
  })();

  self.onBlur = (function () {
    const original = self.onBlur;

    return function () {
      original.apply(self, arguments);

      self.hideNoResults();
    }
  })();

  self.setup = (function() {
    const original = self.setup;

    return function() {
      original.apply(self, arguments);

      self.$empty_results_container = $(options.html(
        $.extend(
          { classNames: self.$input.attr('class') },
          options
        )
      ));

      self.$empty_results_container.insertBefore(self.$dropdown);

      self.hideNoResults();
    }
  })();
});
