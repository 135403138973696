import BaseController from './base_controller'

import Storage from 'src/components/summary/storages/ophthalmology'
import Factory from 'src/components/summary/factories/ophthalmology'

import Range from 'src/components/summary/controls/range'
import Navigate from 'src/components/summary/controls/navigate'
import Timeframe from 'src/components/summary/controls/timeframe'
import ResetButton from 'src/components/summary/controls/reset_button'
import ExportButton from 'src/components/summary/controls/export_button'
import TransmitButton from 'src/components/summary/controls/transmit_button'

import Settings from 'src/components/summary/controls/ophthalmology/settings'
import CompareButton from 'src/components/summary/controls/ophthalmology/compare_button'

import * as Actions from 'src/components/summary/actions'

export default class extends BaseController {
  initializeFunctionality() {
    this.storage = new Storage(this.element.dataset);

    this.range = new Range(this.range_selector, this.storage);
    this.navigate = new Navigate(this.navigate_selector, this.storage);
    this.settings = new Settings(this.settings_selector, this.storage);
    this.timeframe = new Timeframe(this.timeframe_selector, this.storage);
    this.reset_button = new ResetButton(this.reset_selector, this.storage);
    this.export_button = new ExportButton(this.export_selector, this.storage);
    this.compare_button = new CompareButton(this.compare_selector, this.storage);

    if (window.metrics.transmit_available) {
      this.transmit_button = new TransmitButton(this.transmit_selector, this.storage);
    }

    this.factories = [...this.targets].map(target => new Factory(target, this.storage));
  }

  attachCallbacks() {
    this.storage.callbacks['onChange'] = (action) => {
      switch (action) {
        case Actions.UPDATE_RANGE:
          return this.redrawTimeframesChange();
        case Actions.UPDATE_VISION_UNIT:
          return this.redrawVisionUnitsChange();
        case Actions.UPDATE_TRENDLINE:
          return this.redrawTrendlineChange();
        case Actions.UPDATE_STEP:
          return this.redrawStepChange();
      }
    }
  }

  redrawTimeframesChange() {
    this.range.update();
    this.navigate.update();
    this.timeframe.update();
    this.export_button.update();
    this.compare_button.update();

    this.updateWidgets();
  }

  redrawVisionUnitsChange() {
    this.export_button.update();

    this.updateVisionUnits();
  }

  redrawTrendlineChange() {
    this.export_button.update();

    this.updateTrendline();
  }

  updateVisionUnits() {
    const factories = this.filterVisionFactories();

    this.showLoading(factories);
    this.sequentiallyFetchResults(factories);
  }

  updateTrendline() {
    const factories = this.filterTrendlineFactories();

    factories.forEach((factory) => factory.chart.redrawTrendline());
  }

  filterVisionFactories() {
    return this.factories.filter((el, index) => el.params.key == 'ophthalmology:vision');
  }

  filterTrendlineFactories() {
    return this.factories.filter((el, index) => ['ophthalmology:vision', 'ophthalmology:contrast', 'ophthalmology:color'].includes(el.params.key));
  }

  get compare_selector() {
    return this.element.querySelector('[data-compare]');
  }

  get transmit_selector() {
    return this.element.querySelector('[data-transmit]');
  }
}
