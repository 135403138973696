import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'

import Ability from './Ability'
import Answers from './Answers'

import Image from 'src/react/components/TrainingWizard/Image'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { showFormModalAction, showCopyModalAction, showDeleteModalAction } from 'src/react/store/TrainingWizard/actions'

import classnames from 'classnames'

const Question = ({ isDragDisabled, dragHandleProps, questionId, index }) => {
  const dispatch = useDispatch();
  const question = useSelector(state => selectors.getQuestionById(state, questionId));

  const optionable = question.question_type == 'optionable';
  const questionType = optionable ? '(Multiple choice)' : '(Free answer)';
  const dragHandleStyles = classnames('mdi', 'mdi-drag', { 'd-none': isDragDisabled });

  return (
    <div className="question">
      <div className="panel">
        <div className="question-title">
          <div
            className={dragHandleStyles}
            {...dragHandleProps}
          >
          </div>
          Question {index + 1}
          <div className="question-type">
            {questionType}
          </div>
        </div>

        <div>
          <Ability
            context="editable_training"
          >
            <Button
              className="btn-sm"
              variant="link"
              onClick={
                () => dispatch(showFormModalAction({ entry: 'question', id: questionId }))
              }
            >
              <div className="mdi mdi-pencil"></div>
              Edit
            </Button>
            <Button
              className="btn-sm text-danger"
              variant="link"
              onClick={
                () => dispatch(showDeleteModalAction({ entry: 'question', id: questionId }))
              }
            >
              <div className="mdi mdi-delete"></div>
              Delete
            </Button>
          </Ability>
        </div>
      </div>

      <div className="question-image">
        <Image image={question.image} />
      </div>

      <div className="question-body">
        {question.question}
      </div>

      {
        optionable && (
          <Answers
            answers={question.answers}
            snippetId={question.snippet_id}
            questionId={questionId}
            isDragDisabled={isDragDisabled}
          />
        )
      }
    </div>
  );
}

export default Question
