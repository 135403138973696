import React from 'react'
import { Field } from 'react-final-form'
import FormControl from 'react-bootstrap/FormControl'

const FieldError = ({ name }) => (
  <Field
    name={name}
    subscription={{ error: true, submitError: true }}
    render={({ meta: { error, submitError } }) =>
      (error || submitError) ?
        <FormControl.Feedback type="invalid">
          {error || submitError}
        </FormControl.Feedback> :
        null
    }
  />
)

export default FieldError
