import React from 'react'

const DeleteTitle = ({ entry, entity }) => {
  if (entry == 'activity') {
    return 'Delete time records';
  }

  if (entry == 'time_record') {
    return 'Delete time record';
  }

  return null;
};

export default DeleteTitle
