import { Controller } from 'stimulus'
import $ from 'jquery'
import idleTimeout from 'idle-timeout'

const MINUTES = 1000 * 60;

export default class extends Controller {
  connect() {
    idleTimeout(
      () => {
        this.signOut()
      },
      {
        element: document,
        timeout: 30 * MINUTES,
        loop: false
      }
    );
  }

  signOut() {
    $.ajax({
      url: '/users/sign_out',
      method: 'DELETE',
      success: () => {
        location.reload();
      }
    })
  }
}
