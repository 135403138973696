import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { validateCellValue, castCellValue } from 'src/react/store/Timesheets/forms'
import * as selectors from 'src/react/store/Timesheets/selectors'

import {
  editCellAction,
  dropEditCellAction,
  changeCellAction,
  setInvalidCellAction,
  setValidCellAction
} from 'src/react/store/Timesheets/actions'

import CellsFactory from './CellsFactory'
import CellTitle from './CellTitle'

const EditableCell = ({ context, timeRecord, cell, isLockedDay }) => {
  const dispatch = useDispatch();

  const { id: timeRecordId, [context]: currentValue } = timeRecord;

  const onCellClick = () => {
    if (isLockedDay) return;

    dispatch(editCellAction({
      context,
      timeRecordId,
      value: currentValue
    }));
  }

  const onChangeCell = ({ value }) => {
    dispatch(changeCellAction({
      context,
      timeRecordId,
      value
    }));
  };

  const rollbackCell = () => {
    dispatch(dropEditCellAction({ timeRecordId, context }));
  }

  const setCell = () => {
    const errors = validateCellValue(cell.value, context);

    if (errors) {
      dispatch(setInvalidCellAction({
        context,
        timeRecordId,
        errors
      }));

      return;
    }

    dispatch(setValidCellAction({
      context,
      timeRecordId,
      value: castCellValue(cell.value, context)
    }));
  }

  const onBlur = (event) => setCell();

  const onKeyDown = (e) => {
    const { key, shiftKey, ctrlKey } = e;

    switch (key) {
      case 'Tab':
        e.preventDefault();

        break;
      case 'Escape':
        if (currentValue) rollbackCell();

        break;
      case 'Enter':
        if (shiftKey || ctrlKey) return;

        e.preventDefault();
        setCell();

        break;
    }
  };

  return (
    <div className={`timesheet-line-${context}`}>
      <div className="title">
        {context}
      </div>

      <div className="content">
        {
          cell ? (
            <div
              onBlur={(e) => onBlur(e)}
              onKeyDown={e => onKeyDown(e)}
            >
              <CellsFactory
                cell={cell}
                changeCell={onChangeCell}
                context={context}
              />
            </div>
          ) : (
            <div onClick={onCellClick}>
              <CellTitle
                context={context}
                value={currentValue}
              />
            </div>
          )
        }
      </div>
    </div>
  );
}

export default EditableCell
