import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'src/react/store/Timesheets/selectors'
import { deleteActivity, deleteTimeRecord } from 'src/react/store/Timesheets/actions'

import { Button, Modal } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { SubmitError, SubmitButton } from 'src/react/components/FormFields/index'

import DeleteTitle from './Partials/DeleteTitle'
import DeleteMessage from './Partials/DeleteMessage'

const currentAction = (entry) => {
  const factory = {
    activity: deleteActivity,
    time_record: deleteTimeRecord
  };

  return factory[entry];
};

const currentEntity = (entry, entryId) => {
  const factory = {
    activity: selectors.getActivityById,
    time_record: selectors.getTimeRecordById
  };

  return useSelector(state => factory[entry](state, entryId));
};

const DeleteModal = (props) => {
  const { modal, formEntry: entry, formEntryId: entryId, formOptions: options = {} } = props;

  const dispatch = useDispatch();

  const deleteAction = currentAction(entry);
  const entity = currentEntity(entry, entryId);
  const actionableId = useSelector(state => selectors.actionableId(state));

  const onSubmit = async (values) => await dispatch(deleteAction({ entity, options }, { actionableId }));

  return (
    <Form
      onSubmit={onSubmit}
      render={({ submitError, handleSubmit, form, submitting, pristine, values }) => (
        <Modal
          {...modal}
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                {
                  entity && (
                    <DeleteTitle
                      entry={entry}
                      entity={entity}
                    />
                  )
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0">
              {
                submitError && (
                  <SubmitError
                    submitError={ submitError }
                  />
                )
              }
              <div className="bd-callout bd-callout-danger mt-0">
                <strong> Note: </strong>
                <span>
                  {
                    entity && (
                      <DeleteMessage
                        entry={entry}
                        entity={entity}
                      />
                    )
                  }
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton
                type="submit"
                disabled={submitting}
                className="btn-primary"
              >
                Confirm
              </SubmitButton>
              <Button
                onClick={modal.onHide}
                className="btn-secondary"
              >
                Cancel
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    />
  );
}

export default DeleteModal
