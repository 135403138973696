import Simple from 'src/components/summary/widgets/simple'

class Statistic extends Simple {
  setResults(response) {
    this.selector.innerHTML = response.content;
  }

  get options() {
    return {
      category: 'cardiology',
      widget: 'statistic'
    };
  }
}

export default Statistic
