import $ from 'jquery';

$.fn.webNotifications = function() {
  const self = $(this);

  let preloadNextNotifications = function(global_block) {
    let oldHeight;
    const viewMoreBlock = $(global_block).find('.view-more-notifications');

    viewMoreBlock.find('.show-more').on('click', function(e){
      const url = $(this).data('url')
      const request = $.ajax({
        type: 'GET',
        url: url,
        data: { next: true },
        dataType: 'json'
      });

      request.done(function(response) {
        oldHeight = $('.notifications-list').find('ul')[0].scrollHeight;

        viewMoreBlock.replaceWith(response.content);
        preloadNextNotifications(global_block);
        markAsRead(global_block, response.unread_number);
        $(global_block).find('ul').animate({scrollTop: oldHeight - 50}, 1000);
      });
    });
  }

  let readAllNotifications = function(global_block) {
    const link = $(global_block).find('.notifications-block .read-all-notifications-link');

    link.on('click', function(e) {
      e.preventDefault();

      const url = $(this).attr('href');

      const request = $.ajax({
        type: 'PATCH',
        url: url,
        dataType: 'script'
      });
      request.done(function() {
        $('.unread_notifications_counter').text(0);
        $(global_block).find('.indicator').hide();
        $(global_block).find('.read-all-notifications-link').hide();
        $(global_block).find('.notifications-list').hide();
        $(global_block).find('.no-new-notifications-block.hidden').show();
      });
    });
  }

  let markAsRead = function(block, unreadNumber) {
    setTimeout(function() {
      $(block).find('.unread').removeClass('unread');
      $('.unread_notifications_counter').text(unreadNumber);
      if (unreadNumber == 0) {
        $('.web-notifications').find('.indicator').hide();
        $('.read-all-notifications-link').hide();
      }
    }, 2000);
  }

  self.on('show.bs.dropdown', function(event) {
    const content = $(event.target).find('#notificationContent');
    const link = $(event.relatedTarget);

    content.html(link.data('disable-with-content')).promise().done(() => {
      const request = $.ajax({
        type: 'GET',
        url: link.data('url'),
        dataType: 'json'
      });

      request.done((data) => {
        content.html(data.content);
        preloadNextNotifications(self);
        markAsRead(self, data.unread_number);
        readAllNotifications(self);
      });
    });
  });
};
