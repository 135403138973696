import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { components } from 'react-select'

import * as selectors from 'src/react/store/Timesheets/selectors'
import { loadPatients, createRootTimeRecord } from 'src/react/store/Timesheets/actions'
import { generateRootTimeRecord } from 'src/react/store/Timesheets/forms'

import { Button, Modal, FormGroup, FormLabel, Row, Col } from 'react-bootstrap'
import { Form, Field } from 'react-final-form'

import {
  SelectField,
  DatePickerField,
  TextField,
  TextAreaField,
  FieldError,
  AutocompleteField,
  SubmitError,
  SubmitButton
} from 'src/react/components/FormFields/index'

import User from 'src/react/components/Timesheets/User'

const Menu = (props) => {
  if (props.selectProps.inputValue.length === 0) return null;

  return (
    <components.Menu {...props} />
  );
}

const Option = (props) => {
  const { name, email, avatar } = props.data;

  return (
    <components.Option {...props}>
      <User user={{ email, name, avatar: avatar.url && avatar }} />
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => {
  const { name, email, avatar } = props.data;

  return (
    <components.SingleValue {...props}>
      <User user={{ email, name, avatar: avatar.url && avatar }} />
    </components.SingleValue>
  );
}

const TimeRecordModal = ({ modal }) => {
  const dispatch = useDispatch();

  const actionableId = useSelector(state => selectors.actionableId(state));
  const { minDate, maxDate } = useSelector(state => selectors.verificationLock(state));
  const { from, to, currentDay } = useSelector(state => selectors.currentWeek(state));

  const categoriesForSelect =
    Object.entries(window.timesheets.categories).map(([value, label]) => ({ value, label }));

  const onSubmit = async (values) => await dispatch(createRootTimeRecord(values, { actionableId }));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={
        generateRootTimeRecord(currentDay)
      }
      render={({ submitError, handleSubmit, form, submitting, pristine, values }) => (
        <Modal
          {...modal}
          className="add-time-modal"
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title as="h4">
                Add Time
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">
              {
                submitError && (
                  <SubmitError
                    submitError={ submitError }
                  />
                )
              }
              <FormGroup>
                <FormLabel>
                  Patient
                </FormLabel>
                <Field
                  name="patient_id"
                  component={AutocompleteField}
                  loadOptions={loadPatients}
                  placeholder="Enter patient ..."
                  components={{
                    Menu,
                    Option,
                    SingleValue
                  }}
                />
                <FieldError name="patient_id" />
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  Category
                </FormLabel>
                <Field
                  name="category"
                  component={SelectField}
                  type="select"
                  placeholder={false}
                  options={categoriesForSelect}
                />
                <FieldError name="category" />
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  Details{' '}
                  <abbr title="required">*</abbr>
                </FormLabel>
                <Field
                  name="details"
                  component={TextAreaField}
                  type="textarea"
                  rows="2"
                  placeholder="Add details ..."
                />
                <FieldError name="details" />
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup
                    className="date-field"
                  >
                    <FormLabel>
                      Date {' '}
                      <abbr title="required">*</abbr>
                    </FormLabel>
                    <Field
                      name="day"
                      component={DatePickerField}
                      selectedDate={currentDay}
                      minDate={minDate}
                      maxDate={maxDate}
                      type="text"
                      readOnly
                    />
                    <FieldError name="day" />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <FormLabel>
                      Time {' '}
                      <abbr title="required">*</abbr>
                    </FormLabel>
                    <Field
                      name="amount"
                      component={TextField}
                      type="text"
                    />
                    <FieldError name="amount" />
                  </FormGroup>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={modal.onHide}
                className="btn-secondary"
              >
                Cancel
              </Button>
              <SubmitButton
                type="submit"
                disabled={submitting}
                className="btn-primary"
              >
                Add time
              </SubmitButton>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    />
  );
}

export default TimeRecordModal
