import React from 'react'
import { Button } from 'react-bootstrap'

const SubmitButton = ({ disabled, children, ... props }) => {
  return (
    <Button
      disabled={ disabled }
      { ... props }
    >
      {
        disabled &&
          <span className="mdi mdi-loading mdi-spin mr-1"></span>
      }
      { children }
    </Button>
  );
}

export default SubmitButton
