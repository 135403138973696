import { Controller } from 'stimulus'
import $ from 'jquery'
import 'password-strength-meter'

export default class extends Controller {
  connect() {
    $(this.element).password({
      shortPass: 'The password is too weak',
      goodPass: 'Medium; try using special characters'
    });
  }
}
