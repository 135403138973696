const DEFAULT_OPTIONS = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    margin: 0
  },
  title: false,
  subtitle: false,
  accessibility: {
    announceNewData: {
      enabled: false
    },
    point: {
      valueSuffix: '%'
    }
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false
      }
    }
  },

  tooltip: {
    headerFormat: '',
    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
  },
  credits: {
    enabled: false
  }
};

export {
  DEFAULT_OPTIONS
}
