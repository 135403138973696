import React, { useState, useEffect } from 'react'
import { StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async'
import classnames from 'classnames'

const AutocompleteField = ({
  input: {
    onChange: changeForm
  },
  meta,
  invalid = meta => meta.touched && meta.invalid,
  valid = () => false,
  optionKey = 'id',
  components = null,
  loadOptions,
  placeholder = 'Select ...',
  ...rest
}) => {
  const [selectValue, setSelectValue] = useState(null);

  const inputStyles = classnames({ 'is-invalid': invalid(meta) });

  const controlStyles = {
    control: base => ({
      ...base,
      borderColor: invalid(meta) ? '#ea4335' : base.borderColor
    })
  };

  useEffect(() => {
    changeForm(selectValue && selectValue[optionKey]);
  }, [selectValue]);

  const handleChange = (option, { action }) => {
    setSelectValue(action == 'clear' ? null : option);
  };

  return (
    <AsyncSelect
      className={inputStyles}
      value={selectValue}
      isClearable
      blurInputOnSelect
      backspaceRemovesValue
      placeholder={placeholder}
      loadOptions={loadOptions}
      onChange={handleChange}
      components={components}
      styles={controlStyles}
    />
  );
};

export default AutocompleteField
