import { Controller } from 'stimulus'
import $ from 'jquery'
import moment from 'moment'
import 'daterangepicker'

export default class extends Controller {
  connect() {
    $(this.dateRangeInputElement).daterangepicker({
      opens: 'left',
      drops: 'up',
      ranges: this.defaultRanges,
      showToday: false,
      maxSpan: { days: 31 },
      ...this.defaultRange
    })
  }

  get dateRangeInputElement() {
    return this.element.querySelector('input');
  }

  get defaultRanges() {
    return {
      'Last 3 Days': [this.currentDate.subtract(2, 'days'), this.currentDate],
      'Last 7 Days': [this.currentDate.subtract(6, 'days'), this.currentDate],
      'Last 14 Days': [this.currentDate.subtract(13, 'days'), this.currentDate],
      'Last 30 Days': [this.currentDate.subtract(29, 'days'), this.currentDate]
    };
  }

  get currentDate() {
    return moment().clone().utcOffset(window.tz_offset).hour(0).minute(0).second(0);
  }

  get defaultRange() {
    return {
      startDate: this.currentDate.subtract(7, 'days'),
      endDate: this.currentDate
    };
  }
}
