import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Accordion } from 'react-bootstrap'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import AccordionItem from './AccordionItem'

import * as selectors from 'src/react/store/TrainingWizard/selectors'
import { activateCategoryAction } from 'src/react/store/TrainingWizard/actions'
import { resetFlashMessage } from 'src/react/utils/TrainingWizard/flash'

const AccordionTree = ({ categories }) => {
  const dispatch = useDispatch();
  const training = useSelector(state => selectors.training(state));
  const activeCategoryId = useSelector(state => selectors.activeCategoryId(state));
  const changeActive = (id) => dispatch(activateCategoryAction(id == activeCategoryId ? null : id));
  const isDragDisabled = ['active', 'inactive'].includes(training.status);

  return (
    <Accordion className="list-group list-group-flush" activeKey={activeCategoryId}>
      <Droppable
        droppableId="category"
        type="category"
      >
        {
          (dropProvided, dropSnapshot) => (
            <div
              ref={dropProvided.innerRef}
              {...dropProvided.droppableProps}
            >
              {
                categories.map((categoryId, index) => (
                  <Draggable
                    draggableId={`category-${categoryId}`}
                    index={index}
                    key={categoryId}
                    isDragDisabled={isDragDisabled}
                  >
                    {
                      (dragProvided, dragSnapshot) => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <AccordionItem
                            categoryId={categoryId}
                            active={activeCategoryId}
                            changeActive={changeActive}
                            isDragDisabled={isDragDisabled}
                          />
                        </div>
                      )
                    }
                  </Draggable>
                ))
              }
              {dropProvided.placeholder}
            </div>
          )
        }
      </Droppable>
    </Accordion>
  );
}

export default AccordionTree
