import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import CategorySnippet from './CategorySnippet'

const CategorySnippets = ({ categoryId, snippets, isDragDisabled }) => {
  return (
    <Droppable
      droppableId={`snippet-${categoryId}`}
      type={`snippet-${categoryId}`}
    >
      {
        (dropProvided, dropSnapshot) => (
          <div
            className="list-group list-group-flush list-group-inside"
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps}
          >
            {
              snippets.map((snippetId, index) => (
                <Draggable
                  isDragDisabled={isDragDisabled}
                  draggableId={`snippet-${categoryId}-${snippetId}`}
                  index={index}
                  key={snippetId}
                >
                  {
                    (dragProvided, dragSnapshot) => (
                      <CategorySnippet
                        dragProvided={dragProvided}
                        snippetId={snippetId}
                      />
                    )
                  }
                </Draggable>
              ))
            }
            {dropProvided.placeholder}
          </div>
        )
      }
    </Droppable>
  );
}

export default CategorySnippets
